import React from 'react';
import { connect } from 'react-redux';
import userActions from '../context/users/actions';
import notificationsActions from '../context/notifications/actions';

import { history } from "../routes";
import config from "../config";

class AuthCheck extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
      this.userToken();
  }

  //transactions target user.id
  getUnclaimedGifts = async () => {
    //target: this.props.auth.user.id
    this.props.onGetUnclaimed({target:this.props.auth.user.id})
  }

  userToken = async () => {
    const token = JSON.parse(localStorage.getItem(config.LOCALSTORAGE_USER_TOKEN));
    if (!this.props.auth.logged && token) {
      await this.props.onGetToken();
      if (this.props.auth.error) {
        // notify(this.t(this.props.auth.error.message));
        await this.props.onLogout();
        history.push(config.ROUTES.HOME);
      } else {
        await this.getUnclaimedGifts()
      }
    }
  }



  render() {
	  return (
  		<></>
  	)
	}


}

const mapStateToProps = state => {
  return {
    auth: state.users.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetToken: () => dispatch(userActions.getFromToken()),
    onLogout: () => dispatch(userActions.logout()),
    onGetUnclaimed: (params) => dispatch(notificationsActions.getUnclaimedGifts(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthCheck);



