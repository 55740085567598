import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../components/layout/LayoutWithSidebar";
import { history } from "../routes";
import config from "../config";
import { withTranslation } from "react-i18next";
import Button from "../components/commons/Button";
import Footer from "../components/layout/Footer";
import iconOcasion from '../assets/img/icon cards/icon-cumpleaños.png';
import Icon from "../libraries/icons";
import { capitalize } from "../libraries/utils";

class Congratulations extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      copied: false,
    };
  }

  copyToClipboard() {
    navigator.clipboard.writeText(`${config.FRONTEND_URL}/list/${this.props.location.state.id}`).then(() => {
        this.setState({copied: true});
        setTimeout(()=> {this.setState({copied: false})}, 5000)
    });
  }

  render() {
    const { state } = this.props.location;
    /* 
      Links
      Facebook:
      https://www.facebook.com/sharer.php?u=[post-url]
      Twitter:
      https://twitter.com/share?url=[post-url]&text=[post-title]&via=[via]&hashtags=[hashtags]
      Whatsapp:
      https://wa.me/?text=[post-title] [post-url]
    */
    // ---------REDES SOCIALES-----------
    // let postUrl = encodeURI('https://demo.regalalebitcoins.com/');
    // let postTitle = encodeURI('Quiero compartir con ustedes el regalo que realice: ')

    // let facebookLink = ('href',`https://www.facebook.com/sharer.php?u=${postUrl} ${postTitle}`)
    // let twLink = ('href',`https://twitter.com/share?url=${postUrl}&text=${postTitle}&via=[via]&hashtags=[hashtags]`)
    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: '' }}
      >
        <section className="flex flex-row justify-around items-start gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
          <div className="card white md:w-[500px] w-full">
            <div className="bg-success h-56 flex items-center justify-center">
              <div className="relative">
                <img src={iconOcasion} width="100px" />
                <div className="bg-success w-14 h-14 rounded-full absolute -right-7 -bottom-7 flex items-center justify-center">
                  <Icon className="w-12 h-12 text-white" name="check_circle_solid" />
                </div>
              </div>
            </div>
            <div className="flex-1 p-5">
                {state.from === 'gift' && 
                <>
                <div className="mt-10 mb-16 text-center text-2xl">
                  <b className="text-secondary">{capitalize(state.recipient.first_name)}</b> recibió tu regalo correctamente
                </div>
                <div className="text-center">
                      <div className="mb-10 mx-auto w-fit font-bold p-5 bg-white rounded-xl">
                        <p className="text-3xl">{state.total_BTC} BTC</p>
                        <p>{state.total_ARS.toFixed(0)} ARS | {(state.total_USD + state.fee).toFixed(2)} USD</p>
                        <p>1btc = {state.price_BTC} USD</p>
                      </div>
                    <div className="my-4">
                      <p className="font-light mb-2 text-gray-400">Remitente</p>
                      <p className="text-lg" >{state.sender.email}</p>
                    </div>
                    <div className="my-4">
                      <p className="font-light mb-2 text-gray-400">Destinatario</p>
                      <p className="text-lg" >{state.recipient.email}</p>
                    </div>
                    {/* -----REDES SOCIALES-------- */}
                    {/* <div className="my-4">
                      <p className="mb-2 text-lg">Compartí en tus redes Favoritas</p>
                    </div>
                    <div className="flex flex-column-2 my-4">
                      <a className="w-1/2" href={facebookLink}>Facebook</a>
                      <a className="w-1/2" href={twLink}>X</a>
                    </div> */}
                    <div className="flex justify-between text-basen w-full px-10 pt-4 border-t-2 border-t-gray-400">
                      <div className="">{this.t('Fecha')}</div>
                      <div>{state.date.slice(0,10)}</div>
                    </div>
                </div>
                </>}
                {state.from === 'list' &&
                <div className="flex flex-col h-full">
                  <h2 className="h2 text-center text-2xl"> {/* Desde Armar lista */}
                    <b className="text-secondary">{capitalize(this.props.auth.user?.first_name)}</b>, creaste correctamente tu lista de <b className="text-secondary">{capitalize(this.t(state.event_type)) || 'ocasion'}</b>
                  
                  </h2>
                  <div className="text-center text-xl h-full flex flex-col justify-center mt-10">
                    <p className="font-bold">Comparti tu lista con tus amigos</p>
                    <p className="text-sm">{`${config.FRONTEND_URL}/list/${state.id}`}</p>
                    <Button className="btn btn-link" onClick={this.copyToClipboard.bind(this)} title={this.t(capitalize(this.state.copied ? this.t('copied') : this.t('copiar link')))} />
                  </div>
                </div>}
            </div>
            <div className="flex justify-evenly items-center px-10 pb-10">
              <Button
                title={capitalize(this.t('inicio'))}
                className='btn-secondary'
                onClick={()=>history.push(config.ROUTES.HOME)}
              />
              {state.from === 'list' &&
                <Button onClick={()=>{history.push(config.ROUTES.USER.MY_LIST.replace(':id', state.id))}} 
                  title={this.t("Continuar")} 
                  className="btn-outline"
                /> 
              }
              {state.from === 'gift' &&
                <Button onClick={()=>{history.push(config.ROUTES.USER.MY_GIFT.replace(':id', state.payment_id))}} 
                  title={this.t("Continuar")} 
                  className="btn-outline"
                /> 
              }
              
            </div>
          </div>
        </section>
        <Footer className="hidden md:block" />

      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    auth: state.users.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Congratulations));