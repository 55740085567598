import config from './routes';

const customs = {
  COMPANY: {
    SHORT_NAME: 'REGALALE BITCOINS',
    LONG_NAME: 'REGALALE BITCOINS'
  },
  SUPPORTED_LANGUAGES: ['en', 'es'],
  DEFAULT_LANGUAGE: 'es',
  MONEY_SYMBOL: '$',
  MONEY_CODE: 'u$s',
  MONEY_POSITION: 'prefix', //'sufix'
  MONEY_SPACE: true,
  CURRENCIES: ['USD', 'ARS'],
  CURRENCY: 'ARS',
  LOCALE: 'es-ES',
  USER_TYPE: {
    REGULAR: 'regular',
    SUPERADMIN: 'superadmin',
  },
  USER_ACCOUNTS_INTEGRATED: true,
  USER_ACCOUNTS: {
    TYPES: {
      OWNER: 'owner',
      GUEST: 'guest'
    },
    STATUS: {
      ACTIVE: 'active',
      INACTIVE: 'inactive',
      REVOKED: 'revoked'
    }
  },
  OWNER: {
    SYSTEM: 'system'
  },
  TYPES: {
    SETTINGS: {
      CORE: 'core',
      CODES: {
        ARS_TO_USD: 'ars_to_usd',
        BTC_PRICE: 'btc_price',
        BINANCE_WITHDRAW: 'binance_withdraw',
        BUYER_FEE: 'buyer_fee',
      },
    },
    TRANSACTIONS: {
      MERCADOPAGO: 'mercadopago',
      BINANCE: 'binance'
    }
  },
  TRANSACTIONS_TYPES: [
    { label: 'Mercadopago', value: 'mercadopago' },
    { label: 'Binance', value: 'binance' },
  ],
  ROLES: {
    ADMIN: 'admin',
  },
  TRANSACTIONS: {
    MERCADOPAGO: {
      TYPE: 'mercadopago',
    }
  },
  OPTIONS: {
    ROLE_TYPES: ['security', 'group'],
    PERMISSIONS: {
      OBJECT_TYPE: ['object example 1', 'object example 2', 'object example 3'],
      ACTION_TYPE: ['action example 1', 'action example 2', 'action example 3']
    },
    USER_TYPES: ['regular', 'advanced'],
  },
  SIDEBAR: {
    MENU: {
      MAIN: [
        { label: '¿Por qué Bitcoin?', route: config.ROUTES.HOME, anchor: 'why' },
        { label: 'Cómo funciona', route: config.ROUTES.HOME, anchor: 'how_works' },
        { label: 'Ayuda', route: config.ROUTES.HOME, anchor: 'help' },
      ],
      SECONDARY: [
        { label: 'Profile', route: config.ROUTES.USER.INFO, image: 'avatar'},
        { label: 'Regalos', route: config.ROUTES.USER.MY_GIFTS, image: 'gift'},
        { label: 'Mis listas', route: config.ROUTES.USER.MY_LISTS, image: 'list'}
      ],
      ADMIN: [ 
      ],
      EXTRAS: [
        // { label: 'Information' },
        // { icon: 'help', label: 'How works?', route: config.ROUTES.HOW_WORKS },
        // { icon: 'phone', label: 'Contact us', route: config.ROUTES.CONTACT_US },
        // { icon: '', label: 'Terms and conditions', route: config.ROUTES.TERMS }
      ]
    }
  },
  SLIDER: {
    SHOW: false,
    CONTENT: []
  },
};
export default customs;
