import React from 'react';
import Sidebar from './Sidebar';
import NavActionBar from '../commons/NavActionBar';

export default function LayoutWithSidebar({
  main,
  header,
  container,
  actions,
  children,
}) {
  return (
    <main
      className={
        'flex flex-col h-screen bg-gray-100 text-black ' +
        (main?.className ? main.className : '')
      }
    >

      <Sidebar header={header ? header : false} />
      <div
        className={
          'flex-1 overflow-y-auto ' +
          (container?.className ? container.className : '')
        }
      >
        {/* <NavActionBar
          actions={actions}
          breadcrumbs={header?.breadcrumbs}
          className={main?.className}
        /> */}
        {children}
      </div>
    </main>
  );
}
