import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import { capitalize, fromISO, optionSelectGenerator } from "../../libraries/utils";
import Button from "../../components/commons/Button";
import Footer from "../../components/layout/Footer";

import aniversario from '../../assets/img/cards/aniversario.png';
import bautismo from '../../assets/img/cards/bautismo.png';
import casamiento from '../../assets/img/cards/casamiento.png';
import comunion from '../../assets/img/cards/comunion.png';
import confirmacion from '../../assets/img/cards/confirmacion.png';
import cumpleaños from '../../assets/img/cards/cumpleaños.png';
import snValentin from '../../assets/img/cards/dia de.png';
import graduacion from '../../assets/img/cards/graduacion.png';
import mudanza from '../../assets/img/cards/mudanza.png';
import nacimiento from '../../assets/img/cards/nacimiento.png';
import fiesta from '../../assets/img/cards/fiesta.png';
import navidad from '../../assets/img/cards/navidad.png';
import reyes from '../../assets/img/cards/reyes.png';
import fathers from '../../assets/img/cards/padre.png';
import mothers from '../../assets/img/cards/madre.png';
import btc from '../../assets/img/cards/btc.png';


import { withTranslation } from "react-i18next";
import Icon from "../../libraries/icons";

class ChooseCard extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      cards: [
        {logo: cumpleaños,   title: 'cumpleaños',   code: "birthday"},
        {logo: casamiento,   title: 'casamiento',   code: "wedding"},
        {logo: aniversario,  title: 'aniversario',  code: "anniversary"},
        {logo: bautismo,     title: 'bautismo',     code: "baptism"},
        {logo: confirmacion, title: 'confirmación', code: "confirmation"},
        {logo: snValentin,   title: 'san valentín', code: "valentines"},
        {logo: graduacion,   title: 'graduación',   code: "graduation"},
        {logo: mudanza,      title: 'mudanza',      code: "move"},
        {logo: nacimiento,   title: 'nacimiento',   code: "birth"},
        {logo: comunion,     title: 'comunión',     code: "communion"},
        {logo: fiesta,       title: 'fiesta',       code: "party"},
        {logo: navidad,      title: 'navidad',      code: "christmas"},
        {logo: reyes,        title: 'reyes',        code: "threekings"},
        {logo: fathers,        title: 'día del padre',        code: "fathers_day"},
        {logo: mothers,        title: 'día de la madre',        code: "mothers_day"},
        {logo: btc,        title: 'regalo',        code: "bitcoin"},
      ],
      card: 0,
    };
  }
  
  componentDidMount() {
    //precargar las imagenes así no se ve mal en la primera pasada en prod
    this.state.cards.forEach((card) => {
       const img = new Image()
       img.src = card.logo
    });
  }

  onSubmit() {
    const {card, cards} = this.state;
    history.push({pathname: config.ROUTES.LIST_NEW, state: { ...this.props.location.state, event_type: cards[card].code }})
  }
  nextCard() {
    this.setState(prevState=>{
      if (prevState.card === (this.state.cards.length - 1)) {
        return { card: 0 }
      }
      return { card: prevState.card + 1 }
    })
  }
  prevCard() {
    this.setState(prevState=>{
      if (prevState.card === 0) {
        return { card: this.state.cards.length - 1}
      }
      return { card: prevState.card - 1 }
    })
  }

  render() {
    const {card, cards} = this.state;

    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: '' }}
      >
        <section className="flex flex-row justify-around gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
          <div className="card white p-5 md:w-[500px] w-full">
            <h2 className="h2 text-primary mb-4 mx-auto">{capitalize(this.t("elegí la ocasión"))}</h2>
            <div className="flex flex-col justify-start items-center relative rounded-lg p-10">
              <img src={cards[card].logo} alt='' className="w-full max-w-[400px]"/>
              <div className="mt-4 flex justify-center gap-4 items-center w-full select-none">
                <div className="cursor-pointer absolute top-56 -left-2 bg-primary p-2 rounded-full text-white" onClick={this.prevCard.bind(this)} ><Icon name='cheveron_left' size={30} /></div>
                <p className="text-xl font-bold text-primary">{capitalize(cards[card].title)}</p>
                <div className="cursor-pointer absolute top-56 -right-2 bg-primary p-2 rounded-full text-white" onClick={this.nextCard.bind(this)} ><Icon name='cheveron_right' size={30}/></div>
              </div>
              <p className="mt-5 text-gray-400 text-lg">{(card + 1)}/{cards.length}</p>
            </div>
            <Button
              title={capitalize(this.t('siguiente'))}
              onClick={this.onSubmit.bind(this)}
              className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white w-full text-center p-2"
            />
          </div>
        </section>
        <Footer className="hidden md:block" />
      </LayoutWithSidebar>
    );
  }
}

export default connect()(withTranslation()(ChooseCard));