import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import TextInput from "../../components/forms/TextInput";
import DatepickerInput from "../../components/forms/DatepickerInput";
import { capitalize, optionSelectGenerator, formatOnlyNumberInput } from "../../libraries/utils";
import { Field, Form } from "react-final-form";
import { withTranslation } from "react-i18next";
import Button from "../../components/commons/Button";
import Footer from "../../components/layout/Footer";
import usersActions from '../../context/users/actions';
import { notify } from "../../libraries/notifications";
import Loader from "../../components/commons/Loader";
import ModalBuy from "../../components/customs/ModalBuy";
import Modal from '../../components/customs/Modal';
import { validateEmail, validateIsfilled, composeValidators, getQueryParams } from "../../libraries/utils";



class Gift extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.ref = React.createRef();
    this.state = {
      fromList: this.props.location && this.props.location.state && this.props.location.state.from === 'list' || false,
      showModal: false,
      submitting: false,
      dummy: false,
      monto: 0,
      modal: false, 
      values: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.hash === "3434c11de9fc8bb1c0e88e09eed5d011") {
      this.setState({ dummy: true })
    }
  }

  async onSubmit(values) {
    this.setState({ submitting: true})
    const { state } = this.props.location;
    
    if (this.state.fromList) {
      values.wallet = state.wallet;
    }

    if (this.state.dummy) {
      values.event_type = "birthday";
    } else {
      values.event_type = state.event_type;
    }

    if (values.remitEmail === values.destEmail) {
      this.setState({ submitting: false})
      return { emailError: 'Los emails deben ser diferentes' }
    }
    if (!this.props.user) {
      await this.props.getUsers({ email: values.remitEmail});
      const {users} = this.props;
      this.setState({ submitting: false})
      if (users.error) {
        notify(users.error.message)
        return
      }
      const registered = users.items.filter(e => e.email === values.remitEmail);
      if (registered.length > 0) {
        this.setState({ showModal: true })
        return;
      }   
    }
    this.setState({values: values})
    console.log('VALUES',values)
    this.toggleModal();
  }

  toggleModal = () => {
    this.setState(prev=>{
      return { modal: !prev.modal }
    })
  }
  hideModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { dummy, modal, item, submitting, values, showModal } = this.state;
    
    const required = value =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const email = value =>
      validateEmail(value) ? undefined : this.t('Not a valid email');
    const validateDate = (value) => {
      const hoy = new Date();
      hoy.setHours(0,0,0,0);
      const fecha = new Date(value);
      if (fecha >= hoy || !fecha) {
        return undefined;
      } else {
        return this.t("Fecha inválida");
      }
    }
    const today = new Date();
    console.log('FECHA',today)

    const { user } = this.props;
    let initialValues = {}
    initialValues.date = today
    if (user) {
      initialValues = { ...initialValues ,remitNombre: user.first_name, remitApellido: user.last_name, remitEmail: user.email }
    }
    if (this.props.location.state) {
      initialValues = { ...initialValues ,...this.props.location.state };
    }
    if (this.state.fromList) {
      const { json_data } = this.props.location.state;
      initialValues = { ...initialValues, 
        destNombre: json_data.owner.first_name,
        destApellido: json_data.owner.last_name,
        destEmail: json_data.owner.email
      }
    
    }

    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: '' }}
      >
        <section className="flex flex-row justify-around gap-5 md:py-20 md:mt-10 container mx-auto min-h-full">
          {showModal && <Modal values={this.ref.current} onClose={this.hideModal.bind(this)}/>}
          <div className="card white p-5 md:w-[500px] w-full min-h-full overflow-visible">
          <h2 className="h2 text-primary mb-4 mx-auto">Enviá</h2>
          <Form
            onSubmit={this.onSubmit.bind(this)}
            initialValues={initialValues}
            validate={values => {
              const errors = {}
              if (values.remitEmail && values.destEmail && values.remitEmail === values.destEmail) {
                errors.emailError = 'El email del destinatario debe ser diferente al de el remitente.';
              }
              if (values.date) {
                errors.date = validateDate(values.date);
              }
              return errors
            }}
          >
            {({ handleSubmit, form, submitting, pristine, values, invalid }) => {
              this.ref.current = values;
              return (
                <form onSubmit={handleSubmit} className='p-2'>                  
                  {!user && (
                    <div>
                      <div className="text-lg text-secondary font-bold mb-4">{capitalize(this.t("Remitente"))}</div>
                      <div className="grid grid-cols-2 gap-3">
                        <Field component={TextInput} validate={required} name='remitNombre' label={capitalize(this.t("nombre"))}/>
                        <Field component={TextInput} validate={required} name='remitApellido' label={capitalize(this.t("apellido"))}/>
                      </div>
                      <Field component={TextInput} validate={composeValidators(required,email)} name='remitEmail' label={capitalize(this.t("e-mail"))}/>
                    </div>
                  )}
                  {
                    !this.state.fromList &&
                    <div>
                      <div className="text-lg text-secondary font-bold mb-4">{capitalize(this.t("Destinatario"))}</div>
                      <div className="grid grid-cols-2 gap-3">
                        <Field component={TextInput} validate={required} name='destNombre' label={capitalize(this.t("nombre"))}/>
                        <Field component={TextInput} validate={required} name='destApellido' label={capitalize(this.t("apellido"))}/>
                      </div>
                      <Field component={TextInput} validate={composeValidators(required,email)} name='destEmail' label={capitalize(this.t("e-mail"))} />
                      
                    </div>
                  }
                  <div>
                  <div className="text-lg text-secondary font-bold mb-4">{capitalize(this.t("Fecha de envío de regalo"))}</div>
                  <Field 
                    component={DatepickerInput} name='date'
                    placeholder={this.t("DD / MM / AAAA")}
                    validate={validateDate}
                    autocomplete="off"
                  />
                  </div>
                  <Field name="emailError">
                    {({ input, meta }) => (
                        <label className="label">
                        {meta.error && (
                          <span className="label-text-alt invalid-feedback mb-2 -mt-2">{meta.error}</span>
                        )}
                        </label>
                    )}
                  </Field>
                  <div className="flex gap-5 mt-5">
                    <Button
                      onClick={()=>history.push({pathname: config.ROUTES.GIFT_CURRENCY, state: { ...values}})}
                      className="btn-outline btn-secondary"
                      title={this.t('Volver')}
                    />
                    <Button
                      disabled={invalid || submitting}
                      type='submit'
                      className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white flex-1 text-center p-2"
                      title={this.state.submitting && modal ? <Loader spinnerClassName='h-5 w-5' containerClassName='p-0' /> : capitalize(this.t('siguiente'))}
                    />
                  </div>
                </form>
              );
            }}
          </Form>
          </div>
          {modal && values && <ModalBuy values={values} toggleModal={this.toggleModal.bind(this)} /> }
          </section>
        <Footer className="hidden md:block" />

      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.auth.user,
    users: state.users.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(usersActions.getAll(params))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Gift));