import React from 'react';

function SelectInput({ input, meta, label, options, empty, ...rest }) {
  return (
    <div
      className={
        'form-control w-full max-w-xs ' + rest.className ? rest.className : ''
      }
    >
      {label && (
        <label
          htmlFor={input.name}
          className={
            'label ' + (rest.labelClassName ? rest.labelClassName : '')
          }
        >
          <span className="label-text">{label}</span>
        </label>
      )}
      <select
        {...input}
        className={
          'select w-full ' +
          (meta?.error && meta?.touched ? ' input-error invalid-shadow' : '') +
          (rest.narrow ? ' px-1' : '')
        }
        disabled={rest.readOnly || rest.disabled}
      >
        {empty && (
          <option key={input.name + '0'} value="">
            {empty}
          </option>
        )}
        {options &&
          options.map((option) => (
            <option
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              selected={option.selected}
            >
              {option.label}
            </option>
          ))}
      </select>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">
            {meta?.error && meta?.touched && meta?.error}
          </span>
        </label>
      )}
    </div>
  );
}

export default SelectInput;
