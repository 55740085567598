const types = {
  CLEAR_CURRENT: 'NOTIFICATIONS_CLEAR_CURRENT',
  CLEAR_LIST: 'NOTIFICATIONS_CLEAR_LIST',
  GET_REQUEST: 'NOTIFICATIONS_GET_REQUEST',
  GET_SUCCESS: 'NOTIFICATIONS_GET_SUCCESS',
  GET_FAILURE: 'NOTIFICATIONS_GET_FAILURE',

  SAVE_REQUEST: 'NOTIFICATIONS_SAVER_REQUEST',
  SAVE_SUCCESS: 'NOTIFICATIONS_SAVE_SUCCESS',
  SAVE_FAILURE: 'NOTIFICATIONS_SAVE_FAILURE',

  GETALL_REQUEST: 'NOTIFICATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'NOTIFICATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'NOTIFICATIONS_GETALL_FAILURE',

  DELETE_REQUEST: 'NOTIFICATIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'NOTIFICATIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'NOTIFICATIONS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'NOTIFICATIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'NOTIFICATIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'NOTIFICATIONS_UPDATE_FAILURE',  

  GETUNCLAIMED_REQUEST: 'NOTIFICATIONS_GETUNCLAIMED_REQUEST',
  GETUNCLAIMED_SUCCESS: 'NOTIFICATIONS_GETUNCLAIMED_SUCCESS',
  GETUNCLAIMED_FAILURE: 'NOTIFICATIONS_GETUNCLAIMED_FAILURE',

};

export default types;