import React from 'react';

const CheckboxInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className="form-control">
      <label
        className={
          'cursor-pointer label ' + (rest.labelClassName && rest.labelClassName)
        }
      >
        <span className="label-text">{label}</span>
        <div>
          <input
            {...input}
            type="checkbox"
            className={'checkbox ' + (rest.checkboxClassName && rest.checkboxClassName)}
            disabled={rest.readOnly || rest.disabled}
            id={input.name}
          />
          <span className="checkbox-mark"></span>
        </div>
      </label>
      {!rest.noError && (
          <p className="invalid-feedback">
            {meta?.error && meta?.touched && meta?.error}
        </p>
      )}
    </div>
  );
};
export default CheckboxInput;
