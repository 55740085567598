import types from './types';
import payments from './services';


const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  }
}

const getAll = (params) => {

  function request() { return { type: types.GETALL_REQUEST } }
  function success(payload) { return { type: types.GETALL_SUCCESS, payload } }
  function failure(error) { return { type: types.GETALL_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::PAYMENTS::getAll', params);
    dispatch(request());
    const response = await payments.getAll(params, getState().users.auth.token)
    console.log('ACTIONS::PAYMENTS::getAll::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };  

}

const get = (id) => {
  console.log('ACTIONS::PAYMENTS::get', id);

  const request = (id) => { return { type: types.GET_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GET_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await payments.get(id, getState().users.auth.token)
    console.log('ACTIONS::PAYMENTS::get::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };

}

const getCoin = (data) => {
  console.log('ACTIONS::COIN::get', data);

  const request = (data) => { return { type: types.GET_REQUEST, data } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GET_FAILURE, error } }

  return async (dispatch, getState) => {
    // dispatch(request(data));
    const token = getState().users.auth.token || getState().oauth.token;
    let response = await payments.getCoin(data, token)
    console.log('ACTIONS::PAYMENTS::getCoin::RESPONSE FROM SERVICE', response);
    if (response.success) {
      return response.data;
    } else {
      return response;
    }
  };

}

const claim = (id) => {
  console.log('ACTIONS::CLAIM::claim', id);

  const request = (id) => { return { type: types.GET_REQUEST, id } };
  const success = (payload) => { return { type: types.GET_SUCCESS, payload } }
  const failure = (error) => { return { type: types.GET_FAILURE, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await payments.claim(id, getState().users.auth.token)
    console.log('ACTIONS::CLAIM::claim::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }

  };

}

const saveOrUpdate = (payment) => {

  function request(payload) { return { type: types.SAVE_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_FAILURE, error } }
  
  return async (dispatch, getState) => {
    console.log('ACTIONS::PAYMENTS::saveOrUpdate', payment);
    dispatch(request(payment));

    let response;
    const token = getState().users.auth.token || getState().oauth.token;
    if (payment.id) {  // editing a existing record
      response = await payments.update(payment, token)
    } else {
      response = await payments.save(payment, token)
    }
    console.log('ACTIONS::PAYMENTS::saveOrUpdate::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}

const saveCapture = (payment) => {

  function request(payload) { return { type: types.SAVE_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::PAYMENT::saveCapture', payment);
    dispatch(request(payment));

    let response;
    
    response = await payments.saveCapture(payment, getState().oauth.token)

    console.log('ACTIONS::PAYMENT::saveCapture::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}

const saveBitcoin = (payment) => {

  function request(payload) { return { type: types.SAVE_REQUEST, payload } }
  function success(payload) { return { type: types.SAVE_SUCCESS, payload } }
  function failure(error) { return { type: types.SAVE_FAILURE, error } }

  return async (dispatch, getState) => {
    console.log('ACTIONS::PAYMENT::saveBitcoin', payment);
    dispatch(request(payment));

    let response;
    
    response = await payments.saveBitcoin(payment, getState().oauth.token)

    console.log('ACTIONS::PAYMENTS::saveBitcoin::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(response.data));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}

const del = (id) => {
  console.log('ACTIONS::PAYMENTS::del', id);
 
  function request(id) { return { type: types.DELETE_REQUEST, id } }
  function success(id) { return { type: types.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: types.DELETE_FAILURE, id, error } }

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await payments.del(id, getState().users.auth.token)
    console.log('ACTIONS::PAYMENTS::del::RESPONSE FROM SERVICE', response);
    if (response.success) {
      dispatch(success(id));
    } else {
      // if (response.error && response.error.code === 'ERROR_401') {
      //   console.log('APP TOKEN NOT VALID');
      //   dispatch(oauth.removeToken());
      //   dispatch(oauth.accessToken());
      // } else {
        dispatch(failure(response.error));
      // }
    }
  };

}

const actions = {
  clearCurrent,
  get,
  getAll,
  saveOrUpdate,
  saveCapture,
  saveBitcoin,
  del,
  claim,
  getCoin,
};

export default actions;