import React from 'react';
import Icon from '../../libraries//icons';
import { selectIconSize } from '../../libraries//utils';

export default function Button({ ...rest }) {
  return (
    <button
      type={rest.type || "button"}
      className={'btn ' + (rest.className ? rest.className : 'btn-primary')}
      onClick={() => rest.onClick ? rest.onClick(rest.value) : null}
      disabled={rest.disabled}
    >
      {rest.invert ? rest.title : ''}
      {rest.icon && (
        <Icon
          className={selectIconSize(rest.className) + (rest.title ? (rest.invert ? ' ml-2' : ' mr-2') : '')}
          name={rest.icon}
          size={rest.iconSize ? rest.iconSize : ''}
        />
      )}
      {rest.img &&
        <img src={rest.img} alt='' className='mr-1' />
      }
      {rest.invert ? '' : rest.title}
    </button>
  );
}
