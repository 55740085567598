import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import TextInput from "../../components/forms/TextInput";
import TextareaInput from "../../components/forms/TextareaInput";
import { capitalize, optionSelectGenerator } from "../../libraries/utils";
import { Field, Form } from "react-final-form";
import Footer from "../../components/layout/Footer";
import Button from "../../components/commons/Button";
import { validateEmail, validateIsfilled, composeValidators } from "../../libraries/utils";


import { withTranslation } from "react-i18next";

class NewList extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {};
  }

  componentDidMount() {
    const { state } = this.props.location;
    if(!state?.event_type){
      history.push(config.ROUTES.CHOOSE_CARD)
    }
  }

  onSubmit(values) {
    values.event_type = this.props.location.state.event_type; // agrego el dato de la pantalla anterior

    history.push({pathname: config.ROUTES.LIST_CONFIRM, state: values}) // push a ventana de confirmación
  }

  render() {
    let initialValues = this.props.location.state;

    const required = value =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const email = value =>
      validateEmail(value) ? undefined : this.t('Not a valid email');

      const validateAddress = async (value) => {
        const characters = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
        if (value) {
          if(characters.test(value)) {
            return undefined
          }
        }
        return "Billetera inválida"
      }

    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: '' }}
      >
        <section className="flex flex-row justify-around items-start gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
          <div className="card white p-5 md:w-[500px] w-full">
            <h2 className="h2 text-primary mb-4 mx-auto">{capitalize(this.t("Elegí la tarjeta"))}</h2>
            <div>
              <Form onSubmit={this.onSubmit.bind(this)} initialValues={initialValues} >
                {({ handleSubmit, form, submitting, pristine, values, invalid }) => {

                  return (
                    <form onSubmit={handleSubmit}>
                      {/* <Field component={SelectInput} empty="Ocasión" name='ocasion' options={selectOptions}/> */}
                      <Field component={TextInput} validate={required} name='title' label={capitalize(this.t('título'))} inputClassName="bg-[#F5F5F5]"/>
                      <Field component={TextareaInput} validate={required} name='details' label={capitalize(this.t("tu mensaje"))} inputClassName='bg-[#F5F5F5] resize-none' maxLength="140"/>
                      <div className="mb-4">
                        <Field component={TextInput} validate={validateAddress} name='wallet' labelClassName="font-semibold" inputClassName="bg-[#F5F5F5]" label='Address de BTC'/>
                        <p className="text-xs text-gray-400">El address ingresado aquí no puede ser modificado una vez confirmado. Poner un address incorrecto hará que los BTC se envíen a otra persona.</p>
                      </div>
                      <div className="flex gap-5">
                        <Button
                          onClick={()=>history.push({pathname: config.ROUTES.CHOOSE_CARD, state: {...values}})}
                          className="btn-outline btn-secondary"
                          title={this.t('Volver')}
                        />
                        <Button
                          disabled={invalid || submitting}
                          type='submit'
                          className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white flex-1 text-center p-2"
                          title={capitalize(this.t('siguiente'))}
                        />
                      </div>
                    </form>
                  );
                }}
              </Form>
            </div>
          </div>
        </section>
        <Footer className="hidden md:block" />
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(NewList));
