import React from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';

import Button from '../commons/Button';
import Icon from '../../libraries/icons';

import logo from '../../assets/icons/custom/Desktop/app-desktop/Logo.png';
import { Link, useLocation } from 'react-router-dom';
import { capitalize } from '../../libraries/utils';

export default function Footer({className}) {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <>
      <footer className={"relative bg-base-300 pt-8 pb-6 " + (className ? className : "")}>
        <div
          className="bottom-auto top-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-2">
          <div className="flex flex-row flex-wrap justify-between gap-20">
            <div className="px-4 flex-1">
              <div className='text-3xl'>
                <img src={logo} alt='logo' width="150px" />
                <a className="absolute top-4 right-4" href='#Inicio'><Icon name="cheveron_up" className="h-8 w-8 bg-white rounded-full"/></a>
              </div>
              <Button onClick={()=>{history.push(config.ROUTES.GIFT_CARD)}}
                className="btn-primary border-2 border-white border-opacity-50 text-white px-4 mt-4 mr-2" title={capitalize(t("regalá"))}
              />
              <Button onClick={()=>{history.push(config.ROUTES.CHOOSE_CARD)}}
                className="btn-secondary border-2 border-white border-opacity-50 text-white px-4 mt-4" title={capitalize(t("armá tu lista"))}
              />
            </div>
            <div className="flex flex-col text-white text-sm gap-2 px-4 flex-1">
              {location.pathname === '/' ? 
              <>
                <a className="" href='#why'>
                {capitalize(t("¿Por qué Bitcoin?"))}</a>
                <a className="" href='#how_works'>
                {capitalize(t("cómo funciona"))}</a>
                <a className="" href='#help'>
                {capitalize(t("ayuda"))}</a>
              </>
              : 
              <>
                <Link className="" to={config.ROUTES.HOME}>
                  {capitalize(t("¿Por qué Bitcoin?"))}</Link>
                <Link className="" to={config.ROUTES.HOME}>
                  {capitalize(t("cómo funciona"))}</Link>
                <Link className="" to={config.ROUTES.HOME}>
                  {capitalize(t("ayuda"))}</Link>
              </>
              }
                
                <button className='text-start' onClick={()=>history.push(config.ROUTES.LOGIN)}>{capitalize(t("ingresá"))}</button>
                <button className='text-start' onClick={()=>history.push(config.ROUTES.SIGNUP)}>{capitalize(t("registrate"))}</button>
              </div>
              <div className=' text-white px-4 flex-1'>
                <h2 className='capitalize'>{capitalize(t("contacto"))}</h2>
                <p><a href="mailto:inforegalalebitcoins@gmail.com">inforegalalebitcoins@gmail.com</a></p>
                {/* <div className='flex gap-3 mt-2 mb-6'>
                https://www.instagram.com/regalalebitcoin/
                https://www.facebook.com/Regalale-Bitcoin-113463084945389
                  <span className='p-4 bg-white rounded-full' />
                  <span className='p-4 bg-white rounded-full' />
                  <span className='p-4 bg-white rounded-full' />
                </div> */}
              </div>
          </div>
          <hr className="my-6 border-gray-400" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <small className="text-white text-opacity-70 text-sm">
            © {new Date().getFullYear()}{' '} Apptivalo SRL . All rights reserved.
            </small>
            <div className="flex flex-wrap items-center gap-10">
              <Link
                className="text-white text-opacity-70 hover:white text-sm"
                to={config.ROUTES.TERMS}
              >
                {t("Términos y condiciones")}
              </Link>
              <Link
                className="text-white text-opacity-70 hover:white text-sm"
                to={config.ROUTES.PRIVACY}
              >
                {t("Políticas de privacidad")}
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
