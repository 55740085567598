import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import CodeInput from '../../components/forms/CodeInput';
import logo from '../../assets/icons/custom/app-mobile/illus-3.png';
import LayoutHalfScreen from '../../components/layout/LayoutHalfScreen';
import side from '../../assets/img/img-why.png'
import {getQueryParams} from '../../libraries/utils';

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      code: null,
      codeFromGet: null,
    };
  }

  componentDidMount() {
    const params = this.props.match.params;
    if (params.hash) {
      
      const get_params = getQueryParams(this.props.location?.search)
      console.log("PARAMS", get_params?.code)
      if(get_params?.code){
        this.setState({codeFromGet: get_params?.code.split("")}, () => this.checkData(params))
        this.onChange(get_params?.code)
      } else {
        this.setState({codeFromGet: []}, () => this.checkData(params))
      }


    } else {
      history.push(config.ROUTES.LOGIN);
    }
  }

  checkData = async (params) => {
    await this.checkHash(params);
    if(this.props.location?.state?.giftPath){
      this.setState({giftPath: this.props.location?.state?.giftPath})
      this.onSendAgain()
    }
  }

  checkHash = async (params) => {
    await this.props.onGetFromHash(params);
    const user = this.props.user;
    if (user.error) {
      history.push(config.ROUTES.LOGIN);
    } else {
      this.setState({ id: this.props.user.item.id, user_email: this.props.user.item.email });
    }
  };

  onSubmit = async () => {
    const {id, user_email, code} = this.state;
    const data = {id, user_email, code}
    await this.props.onVerifyPIN(data);
    const auth = this.props.auth;
    if (auth.error) {
      notify(this.t(auth.error.message));
    } else {
      //history.push(config.ROUTES.LOGIN);
      //history.push(
      //  config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash)
      //);

      history.push(
        {
          pathname: config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash),
          state: { giftPath: this.state.giftPath, }
        }
      );
    }
  };

  onSendAgain = async () => {
    const data = {email:this.state.user_email, redirect_uri: `${config.FRONTEND_URL}/verify/`}

    await this.props.onVerifyPINretry(data)
    const auth = this.props.auth;
    if (auth.error) { 
      notify(this.t(auth.error.message));
    } else {
      notify(this.t("Mail enviado"), "SUCCESS");
    }
  };

  onChange = (value) => {
    this.setState({ code: +value });
  };

  render() {
    return (
      <LayoutHalfScreen
        header={{className: 'h-[25vh]',logo: logo,left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.LOGIN)},}}
        container={{className: 'mt-10',}}
        main={{className: 'bg-white'}}
        side={{className: 'bg-white flex items-center h-full justify-center px-10',imageClassName: 'max-h-[80%] ',image: side /*images[`slide${this.slide}`]*/,}}
      >
        <ToastContainer />
        <div className="text-center mb-10">
        <h2 className="font-bold text-center mb-4 text-primary text-2xl">{this.t('Enter code')}</h2>
        </div>
        <form className="w-full max-w-lg">
          {this.state.codeFromGet && <CodeInput
            type="number"
            name="code"
            fields={6}
            onChange={this.onChange}
            autoFocus={true}
            value={this.state.codeFromGet}
          />}
          <div className="w-full px-3 text-center">
            <Button
              className="btn-link mb-10 text-primary-100 text-sm"
              title={this.t('Enviar de nuevo')}
              onClick={this.onSendAgain}
            />
            <Button
              className="btn-primary btn-block text-white"
              title={this.t('Ingresar')}
              onClick={this.onSubmit}
            />
          </div>
        </form>
      </LayoutHalfScreen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    user: state.users.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onVerifyPIN: (params) => dispatch(userActions.verifyPIN(params)),
    onGetFromHash: (params) => dispatch(userActions.getFromHash(params)),
    onVerifyPINretry: (params) => dispatch(userActions.verifyPINretry(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Verify));
