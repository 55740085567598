import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import { withTranslation } from "react-i18next";
import ButtonIcon from "../../components/commons/ButtonIcon";
import iconOcasion from "../../assets/icons/custom/app-mobile/gift.png";
import Footer from "../../components/layout/Footer";
import Loader from "../../components/commons/Loader";

import usersActions from "../../context/users/actions";
import trxActions from "../../context/transactions/actions";
import paymentsActions from "../../context/payments/actions";
import { notify, ToastContainer } from "../../libraries/notifications";
import Button from "../../components/commons/Button";
import { Form, Field } from "react-final-form";
import TextInput from "./TextInput";
import TextareaPopUpInput from "./TextareaPopUpInput";
import SelectWithIconInput from "./SelectWithIconInput";
import { validateIsfilled, composeValidators, validateEmail } from "../../libraries/utils";
import { capitalize } from "../../libraries/utils";

import aniversario from "../../assets/img/cards/aniversario.png";
import bautismo from "../../assets/img/cards/bautismo.png";
import casamiento from "../../assets/img/cards/casamiento.png";
import comunion from "../../assets/img/cards/comunion.png";
import confirmacion from "../../assets/img/cards/confirmacion.png";
import cumpleaños from "../../assets/img/cards/cumpleaños.png";
import snValentin from "../../assets/img/cards/dia de.png";
import graduacion from "../../assets/img/cards/graduacion.png";
import mudanza from "../../assets/img/cards/mudanza.png";
import nacimiento from "../../assets/img/cards/nacimiento.png";
import fiesta from "../../assets/img/cards/fiesta.png";
import navidad from "../../assets/img/cards/navidad.png";
import reyes from "../../assets/img/cards/reyes.png";
import fathers from "../../assets/img/cards/padre.png";
import mothers from "../../assets/img/cards/madre.png";
import btc from "../../assets/img/cards/btc.png";
import DatepickerInput from "./DatepickerInput";
import Icon from "../../libraries/icons";

class GiftEdit extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      from: "",
      id: "",
      item: null,
      cards: [
        {icon: navidad, label: 'Navidad', value: 'christmas'},
        {icon: reyes, label: 'Reyes', value: 'threekings'},
        {icon: fiesta, label: 'Fiesta', value: 'party'},
        {icon: cumpleaños, label: 'Cumpleaños', value: 'birthday'},
        {icon: casamiento, label: 'Casamiento', value: 'wedding'},
        {icon: aniversario, label: 'Aniversario', value: 'anniversary'},
        {icon: bautismo, label: 'Bautismo', value: 'baptism'},
        {icon: confirmacion, label: 'Confirmación', value: 'confirmation'},
        {icon: snValentin, label: 'San valentín', value: 'valenties'},
        {icon: graduacion, label: 'Gradución', value: 'graduation'},
        {icon: mudanza, label: 'Mudanza', value: 'move'},
        {icon: nacimiento, label: 'Nacimiento', value: 'birth'},
        {icon: comunion, label: 'Comunión', value: 'communion'},
        {icon: fathers, label: 'Día del padre', value: 'fathers_day'},
        {icon: mothers, label: 'Día de la madre', value: 'mothers_day'},
        {icon: btc, label: 'Bitcoin', value: 'bitcoin'},
      ],
    };
  }
  async componentDidMount() {
    const { user } = this.props.auth;
    const { item } = this.state;
  }

  async onSubmit(values) {
    let data = { ...this.props.item, json_data: {...this.props.item.json_data} }

    data.sub_type = values.event_type;
    data.json_data.message = values.message;
    data.date = values.date ? values.date : data.date;
    data.json_data.event_type = values.event_type;
    const parse = JSON.parse(data.json_data.binance_end);
    data.json_data.binance_end = parse;

    console.log(data)
    // CHECKEAR SI EL NUEVO DESTINATARIO YA TIENE USUARIO
    await this.props.onGetAllUsers({ email: values.email });
    const usersError = this.props.users.error;
    const { items } = this.props.users;
    if (usersError) {
      notify(usersError.message)
      return
    } else {
      if (items && items.length) { // SI YA TIENE USARLO EN LA NUEVA TRX
        const existingUser = items[0];
        const existingRecipient = {
          id: existingUser.id,
          email: existingUser.email,
          last_name: existingUser.json_data.last_name,
          first_name: existingUser.json_data.first_name
        };
        data.target = existingUser.id;
        data.json_data.target = existingUser.id;
        data.json_data.recipient = existingRecipient;
      } else { // SI NO TIENE CREAR UNO
        await this.props.onSaveUser({ 
          welcome_email: false, verify_email: false,
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name
        });

        const userError = this.props.user.error;
        if (userError) {
          notify(userError.message);
          return
        } else { // USAR EL NUEVO USER EN LA NUEVA TRX
          const newUser = this.props.user.item;
          const newRecipient = {
            id: newUser.id,
            email: newUser.email,
            last_name: newUser.json_data.last_name,
            first_name: newUser.json_data.first_name
          };
          data.target = newUser.id;
          data.json_data.target = newUser.id;
          data.json_data.recipient = newRecipient;
        }
      }
    }

    await this.props.onSaveOrUpadteTrx({ id: this.props.item.id, status: 'canceled' }) // CANCELAR LA TRX ORIGINAL
    const { error, item } = this.props.trx;
    if (error) { 
      notify(error.message)
      return
    } else { // AHORA CREAR UNA NUEVA TRX CON LA NUEVA DATA
      delete data.id; // ELIMIMAR EL ID DE LA NUEVA TRX PARA CREAR UNA NUEVA Y NO EDITAR
      delete data.created_by;
      delete data.created_at;
      delete data.updated_at;
      // const parseJSON = JSON.parse(data.json_data);
      // data.json_data = parseJSON;
      console.log(data);
      // return
      await this.props.onSaveOrUpadteTrx(data);
      const { error, item } = this.props.trx;
      if (error) {
        notify(error.message)
        return
      } else {
        this.props.toggleEdit();
        history.push(config.ROUTES.USER.MY_GIFT.replace(':id', item.id))
      }
    }
  }

  render() {
    const { from, id, copied, BTCprice, cards } = this.state;
    const { user } = this.props.auth;
    const { item, edit, toggleEdit } = this.props;

    const validateDate = (value) => {
      const hoy = new Date();
      hoy.setHours(0,0,0,0);
      const fecha = new Date(value);
      if (fecha >= hoy || !fecha) {
        return undefined;
      } else {
        return this.t("Fecha inválida");
      }
    }

    const required = (value) =>
      validateIsfilled(value) ? undefined : this.t("This field is required");
    const email = value =>
      validateEmail(value) ? undefined : this.t('Not a valid email');

    const validateAddress = async (value) => {
      const characters = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
      if (value) {
        if (characters.test(value)) {
          return undefined;
        }
      }
      return "Billetera inválida";
    };

    let initialValues = { ...item, ...item.json_data};
    initialValues.first_name = item.json_data.recipient.first_name;
    initialValues.last_name = item.json_data.recipient.last_name;
    initialValues.email = item.json_data.recipient.email;

    return (
      <div className={(edit ? " " : "hidden ") + "absolute top-0 left-0 z-50 grid md:place-content-center place-content-end h-screen w-full bg-black bg-opacity-40"}
      >
        <div className="card white rounded-b-none md:rounded-b-2xl p-4 md:w-[500px] w-screen h-full md:h-full md:-ml-4">
          <ButtonIcon icon='cancel' buttonClassName='z-30 absolute m-2 top-0 right-0 btn btn-ghost w-fit ml-auto' className='w-6 h-6' 
            onClick={toggleEdit}
          />
          <Form
            onSubmit={this.onSubmit.bind(this)}
            initialValues={initialValues}
            render={({ handleSubmit, values, form, pristine, submitting, invalid }) => {
              return (
                <form onSubmit={handleSubmit} className="p-2">
                  <div>
                    <div className="text-lg text-secondary font-bold mb-4">
                      {capitalize(this.t("Destinatario"))}
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                      <Field
                        component={TextInput}
                        name="first_name"
                        label={capitalize(this.t("nombre"))}
                      />
                      <Field
                        component={TextInput}
                        name="last_name"
                        label={capitalize(this.t("apellido"))}
                      />
                    </div>
                    <Field
                      component={TextInput}
                      validate={composeValidators(required, email)}
                      name="email"
                      label={capitalize(this.t("e-mail"))}
                    />
                  </div>
                  <Field name="emailError">
                    {({ input, meta }) => (
                      <label className="label">
                        {meta.error && (
                          <span className="label-text-alt invalid-feedback mb-2 -mt-2">{meta.error}</span>
                        )}
                      </label>
                    )}
                  </Field>
                  <div>
                    <Field
                      name='date'
                      validate={validateDate}
                      label={capitalize(this.t('fecha'))}
                      component={DatepickerInput}
                    />
                  </div>
                  <div>
                    <Field
                      component={SelectWithIconInput}
                      name='event_type'
                      label={capitalize(this.t('tipo de evento'))}
                      options={cards}
                    />
                  </div>
                  <div>
                    <Field
                      component={TextareaPopUpInput}
                      popup="Nada mejor que un buen mensaje para esta persona que aprecias!"
                      name="message"
                      label={capitalize(this.t("mensaje"))}
                      inputClassName="resize-none"
                      maxLength="140"
                    />
                  </div>
                  <div className="w-full">
                    <Button disabled={pristine || submitting} type="submit"
                      className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white w-full text-center p-2"
                      title={this.state.submitting ?
                          <Loader spinnerClassName="h-5 w-5" containerClassName="p-0"/>
                          : capitalize(this.t("Save"))
                      }
                    />
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    users: state.users.list,
    auth: state.users.auth,
    trx: state.transactions.current,
    list: state.giftlists.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrx: (id) => dispatch(trxActions.get(id)),
    onSaveOrUpadteTrx: (data) => dispatch(trxActions.saveOrUpdate(data)),
    onGetAllUsers: (params) => dispatch(usersActions.getAll(params)),
    onSaveUser: (data) => dispatch(usersActions.saveOrUpdate(data)),
    onCoin: (params) => dispatch(paymentsActions.getCoin(params)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(GiftEdit));
