import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import TextInput from "../../components/forms/TextInput";
import TextareaPopUpInput from "../../components/forms/TextareaPopUpInput";
import TextareaInput from "../../components/forms/TextareaInput";
import RadioInput from "../../components/forms/RadioInput";
import { capitalize, optionSelectGenerator, formatOnlyNumberDecimalInput } from "../../libraries/utils";
import { Field, Form } from "react-final-form";
import { withTranslation } from "react-i18next";
import Button from "../../components/commons/Button";
import Footer from "../../components/layout/Footer";
import currencyDollar from '../../assets/icons/solid/currency-dollar.svg';
import Gift from '../../assets/icons/outline/gift.svg';
import arrowRight from '../../assets/icons/solid/arrow-sm-right.svg';
import usersActions from '../../context/users/actions';
import { notify } from "../../libraries/notifications";
import Modal from "../../components/customs/Modal";
import Loader from "../../components/commons/Loader";
import { validateEmail, validateIsfilled, composeValidators, getQueryParams } from "../../libraries/utils";
import paymentsActions from '../../context/payments/actions';



class GiftCurrency extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.ref = React.createRef();
    this.state = {
      USDprice: 0,
      BTCprice: 0,
      btc: 0,
      fromList: this.props.location && this.props.location.state && this.props.location.state.from === 'list' || false,
      showModal: false,
      submitting: false,
      selectedCurrency: 'USD',
    };
  }

  async componentDidMount() {
    if (this.props.match.params.hash === "3434c11de9fc8bb1c0e88e09eed5d011") {
      this.setState({ dummy: true })
    }
    this.getData()    
  }

  getData = async () => {
    await this.getBTC();
    await this.getUSD();
    this.fixValues()
  }

  async getBTC() {
    const BTC = await this.props.onCoin({ coin: 'btcusdt' });
    if (BTC.error) {
      notify(BTC.error.message);
      return
    }
    this.setState({BTCprice: BTC.price})
  }

  async getUSD() {
    const USD = await this.props.onCoin({ coin: 'usd' });
    if (USD.error) {
      notify(USD.error.message);
      return
    }
    this.setState({USDprice: USD.price})
  }

  fixValues() {
    const { state } = this.props.location;
    const { USDprice, BTCprice } = this.state;
    //ARS TO BTC
    let btc = Number(state.monto / BTCprice)     
  }

  async onSubmit(values) {
    this.setState({ submitting: true})
    const { state } = this.props.location;
    if (this.state.dummy) {
      values.event_type = "birthday";
    } else {
      values.event_type = state.event_type;
    }
    const { selectedCurrency } = this.state;
    // history.push({pathname: config.ROUTES.GIFT_AMOUNT, state: {...values, selectedCurrency}})
    history.push({pathname: config.ROUTES.GIFT, state: {...values, selectedCurrency}})
  }
 

  hideModal() {
    this.setState({ showModal: false });
  }

  render() {

    const {BTCprice, USDprice, btc} = this.state
    const required = value =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const { user } = this.props;

    //Creating an object with the values from the differents currencies
    const opciones = {
      ARS: {
        values: [{value: 5000, label: '5.000 ARS'}, {value: 10000, label: '10.000 ARS'}, {value: 20000, label: '20.000 ARS'}],
        title: "Peso argentino"
      },
      USD: {
        values: [{value: 25, label: '25 USD'}, {value: 50, label: '50 USD'}, {value: 100, label: '100 USD'}],
        title: "Dolares"
      },
      BTC: {
        values: [{}],
        title: "Bitcoin"
      }
    }
    //NAME FROM THE CURRENCY ---->
    const selectedCurrency = this.state.selectedCurrency
    //NEW OBJECT FROM THE CURRENCY NAME AND THE OBJECT "OPCIONES"
    const selected = opciones[selectedCurrency]
    let initialValues = { selectedCurrency };
    if (this.props.location.state) {
      initialValues = { ...initialValues, ...this.props.location.state };
    }

    const maxAmount = (amount) => {
      const number = parseInt(amount)
      if (number) {
        if (number <= 100 && number >= 10) {
          return undefined;
        } else {
          return 'El monto debe estar entre 10 y 100';
        }
      } else {
        return 'Ingrese un monto';
      }
    };
    const maxAmountARS = (amount) => {
      const number = parseInt(amount)
      if (number) {
        if (number <= 20000 && number >= 1000) {
          return undefined;
        } else {
          return 'El monto debe estar entre 1000 y 20000';
        }
      } else {
        return 'Ingrese un monto';
      }
    };

    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: '' }}
      >
        <section className="flex flex-row justify-around gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
          {this.state.showModal && <Modal values={this.ref.current} onClose={this.hideModal.bind(this)}/>}
          <div className="card white p-5 md:w-[500px] w-full">
            <h2 className="h2 text-primary mb-4 mx-auto">Enviá</h2>
            <h3 className="text-secondary items-center font-bold ml-2">Seleccioná el monto del regalo</h3>
            <p className="text-xs text-gray-400 ml-2">Se paga a través de <span className="font-bold">mercado pago </span>
            por la cotización del día, o directamente por <span className="font-bold">BITCOIN</span>.
            </p>
          <Form
            onSubmit={this.onSubmit.bind(this)}
            initialValues={initialValues}
          >
            {({ handleSubmit, form, submitting, pristine, values, invalid }) => {
              this.ref.current = values;
              return (
                <form onSubmit={handleSubmit} className='p-2'>
                    <div className="gap-4 justify-center mt-5 w-full py-5 md:p-0 md:pt-5 ">
                      <div className="flex flex-col-2 ">
                        <div className="flex flex-col-3  rounded-lg w-3/5 h-2/3 mr-2 border-2 border-gray-300/50">
                          <button onClick={()=>this.setState({ selectedCurrency: 'USD'}) }
                            className={selectedCurrency === 'USD'
                              ? 'p-2 w-1/3 rounded-l-lg bg-secondary text-white border-r-2 border-r-gray-300/50'
                              : "p-2 w-1/3 text-gray-500 border-r-2 border-r-gray-300/50"
                            }
                          >USD</button>
                          <button onClick={()=>this.setState({ selectedCurrency: 'ARS' })} 
                            className={selectedCurrency === 'ARS'
                              ? 'p-2 w-1/3 bg-black text-white border-r-2 border-r-gray-300/50'
                              : "p-2 w-1/3 text-gray-500 border-r-2 border-r-gray-300/50"}
                          >ARS</button>
                          <button onClick={()=>this.setState({ selectedCurrency: 'BTC' })}
                            className={selectedCurrency === 'BTC'
                              ? "p-2 w-1/3 rounded-r-lg bg-yellow-400 text-white"
                              : 'text-gray-400 p-2 w-1/3'}
                          >BTC</button>
                        </div>
                        <div className="flex w-full ml-2">
                          <div className="relative w-full">
                          <Field
                            name="monto"
                            className="bg-gray-100 px-4 border-gray-500 h-12 w-full rounded-md text-black focus:outline-none"
                            inputClassName={"bg-gray-100 border-gray-300"}
                            // component={TextInput}
                            component="input"
                            placeholder={'Monto'}
                            type="number"
                            parse={(v) => formatOnlyNumberDecimalInput(v, selectedCurrency === 'USD' ? 4 : 9)}
                            validate={selectedCurrency === 'ARS' ? maxAmountARS : selectedCurrency === 'USD' ? maxAmount : required}  
                            initialValue={selectedCurrency === 'USD' ? 10 : selectedCurrency === 'ARS' ? 1000 : selectedCurrency === 'BTC' ? 0.02 : 0}                                     
                          />
                          <p className="font-bold absolute right-10 items-center top-1/2 bottom-0 -translate-y-1/2">{selectedCurrency}</p>
                          </div>
                        </div>
                      </div>
                        <div className="flex items-start gap-28 h-10 mt-5 mb-2">
                          {selectedCurrency != 'BTC' && selected.values.map((o,i)=>(
                            <Field type="radio" key={i} component={RadioInput} name='monto' option={o} value={o.value}
                              meta={null}
                              labelClassName='mb-3 cursor-pointer text-xs text-center select-none text-gray-500'
                              inputClassName='opacity-0 top-0 left-0 z-5 absolute cursor-pointer '
                              spanClassName={
                                `absolute p-2 w-24 rounded-lg border border-gray-500 after:hidden after:absolute after:content-none ease-in-out transition-all `
                                + (Number(values.monto) === o.value ? 'after:block bg-secondary text-white font-semibold shadow-[0_0_5px_1px_#00000077]' : '')
                              }
                              validate={''}                           
                            />                         
                          ))}                       
                        </div>
                        <div className="flex flex-row w-full btn-secondary text-white rounded-xl mt-5 p-3 items-center mr-2">
                          <img src={Gift} className="h-8 w-8 mr-5"/>
                          <p className="text-xs">
                            Regalas{' '}
                            {BTCprice && values.monto != undefined && selectedCurrency === 'ARS' ? Number((values.monto/USDprice) /BTCprice).toFixed(6) :
                              BTCprice && values.monto != undefined && selectedCurrency === 'USD' ? (Number((values.monto) / BTCprice)).toFixed(6).toLocaleString() : 
                              BTCprice && values.monto != undefined && selectedCurrency === 'BTC' ? '' : 0
                              }
                            {' '}Bitcoin
                          </p>
                          <p className="text-xs pl-1">= {values.monto} {selectedCurrency}</p>
                        </div>
                    </div>
                    <Field 
                      component={TextareaInput} 
                      validate={required} name='mensaje' label={capitalize(this.t("mensaje"))}
                      spanClassName="text-black"
                      inputClassName='resize-none' maxLength="140"
                    />
                    <div className="flex gap-5 mt-5">
                        <Button
                        onClick={()=>history.push({pathname: config.ROUTES.GIFT_CARD, state: { ...values}})}
                        className="btn-outline btn-secondary"
                        title={this.t('Volver')}
                        />
                        <Button
                        disabled={invalid || submitting || this.state.selectedCurrency === null}
                        type='submit'
                        className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white flex-1 text-center p-2"
                        title={this.state.submitting ? <Loader spinnerClassName='h-5 w-5' containerClassName='p-0' /> : capitalize(this.t('siguiente'))}
                        />
                    </div>
                </form>
              );
            }}
          </Form>
          </div>
          </section>
        <Footer className="hidden md:block" />

      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.auth.user,
    users: state.users.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCoin: (params) => dispatch(paymentsActions.getCoin(params)),
    getUsers: (params) => dispatch(usersActions.getAll(params))    
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(GiftCurrency));