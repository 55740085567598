import { t } from "i18next";
import { history } from "../../routes";
import config from "../../config";
import React, { useState } from "react";
import Button from "../commons/Button";

const Modal = ({ values, show, onClose }) => {
  console.log(values);
  console.log(history.location);
  values.fromPathname = history.location.pathname;
  
  const close = () => {
    onClose();
    // history.push({pathname: config.ROUTES.GIFT, state: values})
  }

  return (
    <div className="absolute top-0 left-0 bg-[#00000055] h-screen w-screen flex z-50 justify-center items-center">
      <div className="bg-base-200 card white p-8">
        <div className="mb-8">
          Este email ya tiene un usuario, ingresa o cambia de email.
        </div>
        <div className="flex justify-evenly">
          <Button
            type="button"
            title={t("Login")}
            onClick={() =>
              history.push({ pathname: config.ROUTES.LOGIN, state: values })
            }
          />
            <button
              type="button"
              onClick={close}
              className="btn btn-outline btn-primary rounded-lg"
            >
              {t("Cancel")}
            </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
