import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import {
  composeValidators,
  validateEmail,
  validateIsfilled,
  getTimeZone,
  selectGeneratorWObjChild,
  capitalizePhrase
} from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';
import LayoutSmall from '../../components/layout/LayoutSmall';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import CheckboxInput from '../../components/forms/CheckboxInput';
import DatepickerInput from '../../components/forms/DatepickerInput';
import rolesActions from '../../context/roles/actions';
import SelectInput from '../../components/forms/SelectInput';
import LayoutHalfScreen from '../../components/layout/LayoutHalfScreen';
import logo from '../../assets/icons/custom/app-mobile/illus-2.png';
import { optionSelectGenerator, extrapolateProp } from '../../libraries/utils';
import { capitalize } from '../../libraries/utils';
import side from '../../assets/img/img-why.png'


class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      roles: [],
      signupData: {}
    };
  }

  componentDidMount() {
    const params = this.props.match.params;

    if(this.props.location?.state?.from){
      this.setState({from: this.props.location.state.from}, )
      this.userToken();
      //if(this.props.auth?.logged){
      //  history.push(this.props.location?.state?.from)
      //}
    } else {  
      this.userToken();
    }
    
    if (params.hash) {
      this.checkHash(params);
    } else {
      // this.getData();
    }

  }

  userToken = async () => {
    const token = JSON.parse(localStorage.getItem(config.LOCALSTORAGE_USER_TOKEN));
    if (!this.props.auth.logged && token) {
      await this.props.onGetToken();
      if (this.props.auth.error) { 
        // notify(this.t(this.props.auth.error.message));
        await this.props.onLogout();
        history.push(config.ROUTES.HOME);
      } 
      
      if(this.props.auth.logged){
        if(this.state.from){
          history.push(this.state.from);
        } else {
          history.push(config.ROUTES.HOME);
        }       
      }
      
    } else {
      this.setState({ doLogin: true });
    }
  }


  checkHash = async (params) => {
    await this.props.onGetFromHash(params);
    const user = this.props.user;
    if (user.error) {
      history.push(config.ROUTES.LOGIN);
    } else {
      this.setState({ user: extrapolateProp(user.item, "json_data"), preRegistered: true });
    }
  };  

  checkUserMail = async (email) => {
    await this.props.onGetUsers({email});
    const users = this.props.users;
    if (users.error) {
      //history.push(config.ROUTES.LOGIN);
    } else {
      if(users.items.length){
        this.setState({ user: extrapolateProp(users.items[0], "json_data"), preRegistered: true });
        console.log("CHECKHASH", extrapolateProp(users.items[0], "json_data"))
      }
    }
  };

  finalizeSignUp = async (data, route) => {
    data.id = this.state.user.id 

    await this.props.saveOrUpdate({id: this.state.user.id, json_data: {first_name: data.first_name, last_name: data.last_name,}});
    const user = this.props.user;
    if (user.error) {
      this.setState({ submitting: false });
      notify(this.t(user.error.message));
    } else {
      history.push(
        {
          pathname: config.ROUTES.VERIFY.replace(':hash', this.props.user.item.hash),//config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash),
          state: { giftPath: route ? route : config.ROUTES.HOME, }
        }
      );
    }
  }

  onSubmit = async values => {
    this.setState({ submitting: true, signupData: { ...values } });
    const data = {
      ...values,
      time_zone: getTimeZone(),
      user_type: config.USER_TYPE.REGULAR,
      redirect_uri: `${config.FRONTEND_URL}/verify/`,
    };

    if(!data.terms) return
    delete data.terms

    // data.birth_date = dateComponents(values.birth_date)[3].dateOnly;
    if(!this.state.user){
      await this.props.onRegister(data);
      const user = this.props.user;
      if (user.error) {

        if(user.error.code){
          if(user.error.code === 'REGISTER_ERROR_NOT_ACTIVE'){
            await this.checkUserMail(values.email);
            this.finalizeSignUp(data);
            return;
          }
        }

        this.setState({ submitting: false });
        notify(this.t(user.error.message));
      } else {
        //history.push(config.ROUTES.PASSWORD.replace(':hash', this.props.user.item.hash));
        history.push(
          config.ROUTES.VERIFY.replace(':hash', this.props.user.item.hash)
        );
      }
    } else {
      this.finalizeSignUp(data, this.state.from)
    }

  };

  // getData = async () => {
  //   await this.props.onGetRoles();
  //   if (this.props.roles.error) {
  //     notify(this.props.roles.error.message);
  //     return
  //   }
  //   this.setState({
  //     roles: [...this.props.roles.items]
  //   });
  //   console.log(this.state)
  // };

  onTerms = () => {
    history.push(config.ROUTES.TERMS);
  };

  onLogin = () => {
    history.push(config.ROUTES.LOGIN);
  };

  render() {
    const { roles } = this.state;
    const required = value =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const email = value =>
      validateEmail(value) ? undefined : this.t('Not a valid email');
    /* const onlynumber = value =>
      validateOnlyNumber(value)
        ? undefined
        : this.t('El documento solo debe llevar digitos');
    const dnilength = value =>
      value.length >= 7 && value.length <= 8
        ? undefined
        : this.t('EL documento debe tener 7 u 8 numeros'); */
    /* const dni_types = config.OPTIONS.SINGUP.IDENTIFICATION.TYPES.map((o) => {
      return { value: o, label: this.t(o) };
    });
    const gender_types = config.OPTIONS.SINGUP.GENDER.TYPES.map((o) => {
      return { value: o, label: capitalize(o) };
    }); */


    return (
      <LayoutHalfScreen
        main={{className: 'bg-gradient-to-r from-primary to-primary-focus md:from-white md:to-white'}}
        container={{className: 'rounded-t-[50px] px-8 pt-8 h-full'}}
        header={{ className: 'h-[25vh]', logo: logo, left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.HOME)} }}
        side={{
          className: 'bg-primary flex items-center h-full justify-center px-10',
          imageClassName: 'max-h-[50%] ',
          image: side /*images[`slide${this.slide}`]*/,
        }}
      >
        <ToastContainer />
        <p className='font-bold text-center mb-4 text-primary text-2xl'>{this.t("Registrate")}</p>
        <Form
          initialValues={this.state.user || {}}
          onSubmit={this.onSubmit}>
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className="w-full max-w-lg mx-auto px-4">
                <div className="w-full">
                  <Field
                    name="first_name"
                    component={TextInput}
                    placeholder={this.t('Ej. Juan')}
                    label={this.t('Nombre')}
                    validate={required}
                  />
                </div>
                <div className="w-full">
                  <Field
                    name="last_name"
                    component={TextInput}
                    placeholder={this.t('Ej. Sosa')}
                    label={this.t('Apellido')}
                    validate={required}
                  />
                </div>
                <div className="w-full">
                  <Field
                    name="email"
                    component={TextInput}
                    placeholder={this.t('Ej. mail@mail.com')}
                    label="Correo electrónico"
                    validate={composeValidators(required, email)}
                    readOnly={this.state.preRegistered}
                  />
                </div>
                <div className="w-full md:mb-0">
                  <Field
                    name="terms"
                    component={CheckboxInput}
                    label={this.t('I accept terms and conditions')}
                    validate={required}
                    labelClassName='flex justify-end flex-row-reverse'
                    checkboxClassName="checkbox-primary mr-2"
                    type="checkbox"
                  />
                </div>
                <div className="w-full">
                  <Button
                    className="btn-link btn-block underline capitalize"
                    title={this.t('Terms and conditions')}
                    onClick={this.onTerms}
                  />
                </div>
                <div className="w-full">
                  <Button
                    className="capitalize font-normal text-lg bg-secondary border-btn rounded-btn border-white border-opacity-[0.25] text-white w-full mb-3"
                    title={this.t('Next')}
                    onClick={handleSubmit}
                    disabled={this.state.submitting || pristine}
                  />
                </div>
              </form>
            </>
          )}
        </Form>
        
        <div className="p-2 flex justify-center items-center">
          <p className="text-center mr-1">{this.t('Has an account?')}</p>
          <Button
            className="btn-link mb-0 underline capitalize"
            title={this.t('Login with your user')}
            onClick={this.onLogin}
          />
        </div>
      </LayoutHalfScreen>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.current,
    users: state.users.list,
    roles: state.roles.list,
    auth: state.users.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegister: params => dispatch(userActions.saveOrUpdate(params, 'register')),
    saveOrUpdate: params => dispatch(userActions.saveOrUpdate(params)),
    onGetRoles: params => dispatch(rolesActions.getAll(params)),
    onGetFromHash: (params) => dispatch(userActions.getFromHash(params)),
    onGetUsers: (params) => dispatch(userActions.getAll(params)),
    onGetToken: () => dispatch(userActions.getFromToken()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Signup));
