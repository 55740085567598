import React from "react";
import { withTranslation } from "react-i18next";
import { history } from "../../routes";
import config from "../../config";
import Footer from "../../components/layout/Footer";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";

class InfoTerms extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {};
  }

  componentDidMount() {}

  onExit = () => {
    const back =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.from
        ? this.props.location.state.from
        : config.ROUTES.HOME;
    history.push(back);
  };

  render() {
    return (
      <LayoutWithSidebar
        header={{logo: true, back: true}}
      >
        <div className="px-10 pb-20 pt-20 md:pt-32"> 
        <h2 className='h2 text-center'>Términos y condiciones generales</h2><br /><br />
        <p>
        <b>1. Alcance.</b><br />Este documento describe los términos y condiciones generales (los "Términos y Condiciones Generales") aplicables a cualquier persona que acceda, use y/o interactúe en el sitio <b>www.regalalebitcoins.com</b> (el "Sitio") de Apptivalo SRL en adelante (RB).</p><br /><br />
        <p>
          <b>2. Capacidad.</b><br/>Cualquier persona que desee acceder y/o usar el Sitio y/o interactuar a través de éste, deberá tener capacidad para contratar (los “Usuarios”). Los Usuarios deberán cumplir con los Términos y Condiciones Generales. Si no está de acuerdo con los Términos y Condiciones Generales o alguna de sus partes o no cuenta con la capacidad para contratar los servicios, recomendamos no utilizar ni interactuar en el Sitio. El acceso y/o utilización del Sitio implica aceptación de los Términos y Condiciones Generales. La relación entre RB y los Usuarios como consecuencia del uso del Sitio se regirá por las leyes de la República Argentina.
        </p>
        <p><b>3. Modificaciones</b><br/>RB podrá modificar los Términos y Condiciones Generales en cualquier momento. Dicha modificación será aplicable en forma inmediata a la publicación de los términos modificados en el Sitio.</p><br/><br/>
        <p><b>4. El Sitio</b><br/>El Sitio es de Apptivalo SRL, CUIT 30-71671196-6, con domicilio en la Marcelo T. de Alvear 405 of. 6, Ciudad Autónoma de Buenos Aires. La función del Sitio es brindar información a los Usuarios respecto de RB y de los servicios que ofrece. A través del Sitio los Usuarios pueden, entre otras cosas, adquirir tarjetas de regalo virtuales REGALALE BITCOINS (“Tarjeta Virtual”), pero para ello deben aceptar las condiciones de uso de la Tarjeta Virtual y nuestra política de tratamiento de datos personales.</p><br/><br/>
        <p><b>5. Fallas en el
        sistema</b><br/>RB no garantiza la inexistencia de errores u omisiones en el Sitio o de eventuales dificultades técnicas o fallas en los sistemas o en Internet. RB no garantiza el acceso y uso continuado o ininterrumpido del Sitio. El Sitio puede, eventualmente, no estar disponible debido a dificultades técnicas o fallas de Internet, o por cualquier otra circunstancia ajena a RB por la que ésta no se hace responsable. En tales casos se procurará restablecerlo con la mayor celeridad posible.
        </p><br/><br/>
        <p><b>6. Propiedad Intelectual</b><br/>Los contenidos del Sitio, como por ejemplo la información, imágenes, datos, textos gráficos, fotografías, audio y video clips, íconos, logotipos, redes, programas, bases de datos, archivos que permiten al Usuario acceder y operar en el Sitio, son de propiedad de RB y están protegidas por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción parcial o total de tales contenidos quedan terminantemente prohibidos, salvo autorización previa, expresa y por escrito de RB.</p><br/><br/>
        <p><b>7. Otros sitios web</b><br/>El Sitio puede contener enlaces a otros sitios web, pero ello no indica que sean propiedad u operados por RB. RB no tiene control sobre tales sitios y no será responsable por los contenidos, materiales, acciones y/o servicios prestados por ellos, ni por daños o pérdidas ocasionadas por su utilización, sean causadas directa o indirectamente.</p><br/>
        <p>La presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación, respaldo de RB a dichos sitios y sus contenidos. RB no tiene responsabilidad sobre la información proporcionada por sitios web de terceros y por las consecuencias que de su uso se deriven. RG no garantiza, ni avala de ninguna forma el acceso, el uso, la información o el contenido de cualquier otro sitio web o portal, aunque el mencionado acceso se realice mediante link, banner o cualquier dispositivo disponible en el Sitio.</p><br /><br/>
        <p><b>8. Prohibiciones</b><br/>Se prohíbe cualquier acción o uso de dispositivo, software, u otro medio tendiente a interferir en las actividades y operatoria de RB y del Sitio. Cualquier intromisión, tentativa o actividad violatoria o contraria a las leyes y/o a lo estipulado en los presentes Términos y Condiciones Generales harán pasible a su responsable de las acciones y sanciones legales pertinentes, debiendo además indemnizar los daños ocasionados.</p><br/><br/>
        <p><b>9.Sanciones.</b><br/>Sin perjuicio de otras medidas, RG podrá apercibir, suspender o inhabilitar definitivamente a los Usuarios para operar en el Sitio y/o acceder a los servicios si:<br/>
(a) sospecha que violan o incumplen las leyes vigentes; o<br />
(b) incumplen los Términos y Condiciones Generales y demás políticas de RG;<br/>
(c) incurren, a criterio exclusivo de RG, en comportamientos o actos dolosos o fraudulentos;<br/>
(d) no se puede verificar la identidad del Usuario o cualquier información suministrada es errónea.<br/>
En el caso de la suspensión o inhabilitación de un Usuario perderá todos los servicios que éste hubiera contratado y se dejarán sin efecto las ofertas realizadas, sin lugar a reclamo alguno.
        </p><br/><br/>
        <p><b>10. Jurisdicción</b><br/>Toda controversia que surja en relación con el objeto de los presentes Términos y Condiciones Generales será dirimida por ante los tribunales competentes de la Ciudad Autónoma de Buenos Aires, con exclusión de cualquier otro fuero que pudiera corresponder.</p><br/><br/>
        <p><b>Términos y condiciones de nuestras gift cards</b><br/>
        Usted está por adquirir una tarjeta en formato virtual (en adelante gift card) en el sitio de REGALALE BITCOINS (en adelante RB). Recomendamos leer atentamente este documento ya que contiene los términos y condiciones para adquirirla y utilizarla. Si paga, y/o usa la gift card se tienen por aceptados estos términos y condiciones que van a regir su relación contractual con RB. Si no está de acuerdo con estos términos y condiciones y no quiere aceptarlos, entonces recomendamos no adquirir ninguna e-gift card ni utilizarla, en caso de haberla recibido de un tercero.</p>
        <p><b>¿Qué es la giftcard?</b><br/>
        La gift card es una tarjeta con un saldo de Bitcoins en formato virtual que el cliente compra con la intención de realizar un regalo, para lo cual provee de una dirección de mail, dirección que recibirá la misma, para ello el cliente realizará el pago por los medios de pago habilitados.</p>
        <p><b>¿Cómo se adquiere una gift card?</b><br/>
        Para adquirir una gift card deberá ingresar a nuestro sitio web www.regalalebitcoins.com  Al seleccionar el tipo de gift card, deberá asignarle un monto dentro de los mínimos y máximos establecidos. El monto a pagar será lo que se cargará a la gift card menos los cargos por el uso del servicio.</p>
        <p>Una vez seleccionada la gift card y el  monto, se solicitará la dirección de mail de quien recibirá la gift card. Completada dicha información, será direccionada al sitio del check out de pago.</p><br/>
        <p>RB no opera la plataforma de pagos y se trata de un sitio ejecutado por terceros sobre el que no tenemos incidencia ni responsabilidad. Cuando sea derivado a la plataforma de pagos elegida, recomendamos consultar cuáles son los términos y condiciones que aplican. Las diferentes opciones de pago son las proporcionadas por la plataforma.</p><br/>
        <p>Una vez que se confirme el pago, la gift card se enviará al correo electrónico indicado (el suyo o el del/al agasajado/a) en ese mismo momento. Una vez que se completó el proceso de compra se envía la gift card, el destinatario recibirá un correo electrónico que contendrá la gift card indicando el monto de la misma.</p><br/>
        <p><b>¿Cómo se usa la gift card?</b><br/>Una vez que el agasajado recibe la gift card, para hacerse de los fondos cargados en la misma, se le proveerá de un link en el mail que se le envía informando sobre su regalo, para que pueda realizar la transferencia de los fondos desde la gift card a la wallet que el solicite.  RB no es responsable de problemas relacionados con la red de Bitcoin [Blockchain], errores en los que pueda incurrir el agasajado realizando la transferencia y que provoquen la pérdida de los fondos, así como también la seguridad de la cuenta que mail, razón por la cual pueden sustraerse las credenciales para obtener los fondos de la giftcard.</p>
        <p><b>Es muy importante que tenga en
        cuenta lo siguiente:</b><br/>• La gift card no tiene plazo de vigencia, sin embargo tenga en cuenta que los fondos en una wallet de RB no tienen el mismo nivel de seguridad que lo que puede obtener con una cold wallet o hardware wallet.<br />
        • La gift card no puede ser reemplazada en caso de robo de los datos y RB no es responsable por el robo de los datos, una vez que se le envió el mail con las credenciales para realizar la transferencia, razón por la cual instamos a los clientes y agasajados a realizar la transferencia a una wallet propia inmediatamente que se reciba el mail con la gift card.</p><br/>
        <p><b>Modificaciones de estos Términos y
        Condiciones</b><br/>RB puede cambiar estos Términos y Condiciones de Servicios en cualquier momento, respetando la normativa aplicable. En caso de modificación, se actualizará este documento y los nuevos términos entrarán en vigencia a partir de su publicación.</p><br/>
        <p><b>Derecho de revocación</b><br/>
        El Código Civil y Comercial de la Nación establece que, en los contratos celebrados fuera de los establecimientos comerciales y a distancia, el consumidor tiene el derecho irrenunciable de revocar la aceptación dentro de los diez días computados a partir de la celebración del contrato. Si quiere ejercer su derecho de revocación, contáctese a través del formulario en nuestro sitio web: www.regalalebitcoins.com dentro del plazo establecido. Si ya usó la Tarjeta Virtual no puede ejercer este derecho.</p><br/>
        <p><b>Legislación y Jurisdicción</b><br/>Toda controversia que surja en relación con estos Términos y Condiciones será dirimida ante los Tribunales Competentes de la Ciudad Autónoma de Buenos Aires, con exclusión de cualquier otro fuero que por cualquier otro motivo pudiera corresponder, y con arreglo a la legislación argentina.</p>
        </div>
        <Footer />
      </LayoutWithSidebar>
    );
  }
}

export default withTranslation()(InfoTerms);
