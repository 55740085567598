import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import TextInput from "../../components/forms/TextInput";
import TextareaPopUpInput from "../../components/forms/TextareaPopUpInput";
import RadioInput from "../../components/forms/RadioInput";
import { capitalize, optionSelectGenerator, formatOnlyNumberInput } from "../../libraries/utils";
import { Field, Form } from "react-final-form";
import { withTranslation } from "react-i18next";
import Button from "../../components/commons/Button";
import Footer from "../../components/layout/Footer";
import arrowRight from '../../assets/icons/arrow-right.svg';
import Icon from '../../libraries/icons';
import currencyDollar from '../../assets/icons/solid/currency-dollar.svg';
import usersActions from '../../context/users/actions';
import { notify } from "../../libraries/notifications";
import Modal from "../../components/customs/Modal";
import Loader from "../../components/commons/Loader";
import settingsActions from '../../context/settings/actions';
import paymentsActions from '../../context/payments/actions';
import { validateEmail, validateIsfilled, composeValidators, getQueryParams } from "../../libraries/utils";



class GiftAmount extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.ref = React.createRef();
    this.state = {
      fromList: this.props.location && this.props.location.state && this.props.location.state.from === 'list' || false,
      showModal: false,
      submitting: false,
      dummy: false,
      monto: 0,
      USDprice: 0,
      BTCprice: 0,
      btc: 0,
      montoBTC: 0,
    };
  }

  componentDidMount() {
    if (this.props.match.params.hash === "3434c11de9fc8bb1c0e88e09eed5d011") {
      this.setState({ dummy: true })
    }
    this.getData();
  }

  getData = async () => {
    await this.getBTC();
    this.fixValues();
  }


  async getBTC() {
    const BTC = await this.props.onCoin({ coin: 'btcusdt' });
    if (BTC.error) {
      notify(BTC.error.message);
      return
    }
    this.setState({BTCprice: BTC.price})
    console.log('BTC', BTC.price)
  }

  // async getUSD() {
  //   const USD = await this.props.onCoin({ coin: 'usd' });
  //   if (USD.error) {
  //     notify(USD.error.message);
  //     return
  //   }
  //   this.setState({USDprice: USD.price})
  //   console.log('USD', USD.price)
  // }

  fixValues(){
    const { state } = this.props.location;
    const { BTCprice } = this.state;
    console.log('MONTO', this.state.monto)
    const usd = (this.state.monto * BTCprice).toFixed(2)
    this.setState({ montoBTC: usd });
    //console.log(this.montoBTC)
    
  }

  async onSubmit(values) {
    this.setState({ submitting: true})
    const { state } = this.props.location;

    if (this.state.fromList) {
      values.wallet = state.wallet;
    }

    if (this.state.dummy) {
      values.event_type = "birthday";
    } else {
      values.event_type = state.event_type;
    }

    // if (values.remitEmail === values.destEmail) {
    //   this.setState({ submitting: false})
    //   return { emailError: 'Los emails deben ser diferentes' }
    // }
    if (!values.monto) {
      this.setState({ submitting: false})
      return { monto: "Debe ingresar un monto" }
    }

    if(values.monto === '0' && values.otros){
     values.monto = values.otros
    }
    //{console.log('VALUES::',values)}
    if (!this.props.user) {
      await this.props.getUsers({ email: values.remitEmail});
      const {users} = this.props;
      this.setState({ submitting: false})
      if (users.error) {
        notify(users.error.message)
        return
      }
      const registered = users.items.filter(e => e.email === values.remitEmail);
      if (registered.length > 0) {
        this.setState({ showModal: true })
        return;
      }
    }
    history.push({pathname: config.ROUTES.GIFT, state: {...values, selectedCurrency: this.props.location.state.selectedCurrency}})
  }
 

  hideModal() {
    this.setState({ showModal: false });
  }
  

  render() {    
    const { dummy, montoBTC, BTCprice } = this.state;
    // this.state.monto = 2
    // console.log('MONTOLOG', montoBTC);
    
    const required = value =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const email = value =>
      validateEmail(value) ? undefined : this.t('Not a valid email');

    //Creating an object with the values from the differents currencies and using it with the selected currency
    const opciones = {
      ARS: {
        values: [{value: '500', label: '500 ARS'}, {value: '1000', label: '1000 ARS'}, {value: '5000', label: '5000 ARS'}, {value: '0', label: 'Otro'}],
        title: "Peso argentino"
      },
      USD: {
        values: [{value: '25', label: 'USD 25'}, {value: '50', label: 'USD 50'}, {value: '100', label: 'USD 100'}, {value: '0', label: 'Otro'}],
        title: "Dolares"
      },
      BTC: {
        values: [{}],
        title: "Bitcoin"
      }
    }
    //Name from the currencies 
    const selectedCurrency = this.props.location.state.selectedCurrency.name
    //The new object
    const selected = opciones[selectedCurrency]

    console.log('selected:', selected.values)

    const maxAmount = (amount) => {
      const number = parseInt(amount)
      if (number) {
        if (number <= 100 && number >= 10) {
          return undefined;
        } else {
          return 'El monto debe estar entre 10 y 100';
        }
      } else {
        return 'Ingrese un monto';
      }
    };
    const maxAmountARS = (amount) => {
      const number = parseInt(amount)
      if (number) {
        if (number <= 10000 && number >= 100) {
          return undefined;
        } else {
          return 'El monto debe estar entre 100 y 10000';
        }
      } else {
        return 'Ingrese un monto';
      }
    };

    const { user } = this.props;
    let initialValues = {}
    if(selectedCurrency === 'ARS'){
      initialValues.monto = '500'
    }
    if(selectedCurrency === 'BTC'){
      initialValues.monto = 0
    }
    if(selectedCurrency === 'USD'){
      initialValues.monto = '25'
    }

    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: '' }}
      >
        <section className="flex flex-row justify-around gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
          {this.state.showModal && <Modal values={this.ref.current} onClose={this.hideModal.bind(this)}/>}
          <div className="card white p-5 md:w-[500px] w-full">
                <h2 className="h2 text-primary mb-4 mx-auto">Enviá</h2>                
                <div className={`${selectedCurrency === 'USD' && 'bg-secondary'} flex flex-row w-full rounded-lg mt-5 ${selectedCurrency === 'BTC' && 'bg-yellow-500' } ${selectedCurrency === 'ARS' && 'bg-black' }`
                // [ selectedCurrency.title === 'ARS' ("bg-black flex flex-row w-full rounded-lg mt-5")]
                // [ selectedCurrency.title === 'BTC' ("bg-yellow-300 flex flex-row w-full rounded-lg mt-5")]
                }>
                    <div className="flex flex-col justify-center items-center p-5">
                        <img src={currencyDollar} className="w-14 h-12"/>
                    </div>
                    <div className="flex flex-col text-center justify-center items-center pl-5">
                        <h3 className="text-white font-bold">{selected.title}</h3>                        
                    </div>
                </div>
                
                <Form
                    onSubmit={this.onSubmit.bind(this)}
                    initialValues={initialValues}
                    validate={values => {
                    const errors = {}                    
                    return errors
                    }}
                >
                    {({ handleSubmit, form, submitting, pristine, values, invalid }) => {
                    this.ref.current = values;
                    return (
                        <form onSubmit={handleSubmit} className='p-2'>
                          <div>
                            {selected.title === 'Bitcoin' ? 
                            <>
                            <div className="mt-5 flex flex-row">                                    
                                    <Field
                                        name="monto"
                                        className="w-1/2 text-black rounded-md pr-4 border-gray-500"
                                        inputClassName={"bg-gray-100 border-gray-300"}
                                        component={TextInput}
                                        placeholder={'BTC'}                                        
                                      />
                                      <img src={arrowRight} alt='' className="w-6 m-2"/>                                      
                                      <p className="ml-3 text-xs text-gray-500 flex justify-center items-center">{(Number(values.monto) * BTCprice) || 0} USD</p>                                   
                                  </div>                      
                                  <Field name="monto">
                                      {({ input, meta }) => (
                                          <label className="label">
                                          {(meta.error || meta.submitError) && meta.touched && (
                                          <span className="label-text-alt invalid-feedback">{meta.error || meta.submitError}</span>
                                          )}
                                          </label>
                                      )}
                                  </Field>                                  
                              </>
                            :
                              <>
                              <div className="text-lg flex justify-start items-center text-secondary font-bold mb-5 mt-10">
                              {capitalize(this.t("Regalo"))}
                              {/* <span after="Campo de los tipos de regalo Elige el monto de tu regalo. Escríbenos para mayores opciones." className="ml-1 font-normal pop-up after:content-[attr(after)]"><Icon name="information" className="h-4 w-4" /></span> */}
                              </div>                              
                                <div>
                                  <div className="flex items-start gap-24 h-10">
                                      {selected.values.map((o,i)=>(
                                      <Field type="radio" key={i} component={RadioInput} name='monto' option={o} value={o.value}
                                          labelClassName='block relative mb-3 cursor-pointer text-xs text-center select-none '
                                          inputClassName='opacity-0 top-0 left-0 z-5 absolute cursor-pointer '
                                          spanClassName={`absolute p-2 w-20 rounded-lg border border-gray-500 after:hidden after:absolute after:content-none ease-in-out transition-all `}
                                      />                         
                                      ))}                       
                                  </div>
                                  {values.monto === '0' && (
                                  <div className="mt-5">                                    
                                    <Field
                                        name="otros"
                                        component={TextInput}
                                        placeholder={
                                          selectedCurrency === 'USD'
                                            ? capitalize(this.t('Monto entre 10 y 100'))
                                            : capitalize(this.t('Monto entre 100 y 10000'))
                                        }
                                        parse={(v) => formatOnlyNumberInput(v, selectedCurrency === 'USD' ? 3 : 5)}
                                        validate={selectedCurrency === 'USD' ? maxAmount : maxAmountARS}
                                      />                                   
                                  </div>
                                  )}                            
                                  <Field name="monto">
                                      {({ input, meta }) => (
                                          <label className="label">
                                          {(meta.error || meta.submitError) && meta.touched && (
                                          <span className="label-text-alt invalid-feedback">{meta.error || meta.submitError}</span>
                                          )}
                                          </label>
                                      )}
                                  </Field>
                                </div>
                                </>
                            }
                              <Field component={TextareaPopUpInput} popup="Nada mejor que un buen mensaje para esta persona que aprecias!" validate={required} name='mensaje' label={capitalize(this.t("mensaje"))} inputClassName='resize-none' maxLength="140"/>
                          </div>                  
                          <div className="flex gap-5">
                              <Button
                              onClick={()=>history.push({pathname: config.ROUTES.GIFT_CURRENCY, state: { ...values}})}
                              className="btn-outline btn-secondary"
                              title={this.t('Volver')}
                              />
                              <Button
                              disabled={invalid || submitting}
                              type='submit'
                              className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white flex-1 text-center p-2"
                              title={this.state.submitting ? <Loader spinnerClassName='h-5 w-5' containerClassName='p-0' /> : capitalize(this.t('siguiente'))}
                              />
                          </div>
                        </form>
                    );
                    }}
                </Form>
            </div>
        </section>
        <Footer className="hidden md:block" />

      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.auth.user,
    users: state.users.list,
    settings: state.settings.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(usersActions.getAll(params)),
    onGetSettings: params => dispatch(settingsActions.getAll(params)),
    onCoin: (params) => dispatch(paymentsActions.getCoin(params)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(GiftAmount));