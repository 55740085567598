import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../libraries/icons';

export default function MenuItem({ item, action, location, ...rest }) {
  const { t } = useTranslation();
  if (!item) return null;
  const { icon, label, route, img } = item;
  const isSelected = (route, location) => {
    return location.pathname === route;
  };
  return action ? (
    <li>
      <span className='rounded-btn capitalize text-base-300 md:justify-center whitespace-nowrap font-medium px-3'
        onClick={action ? action : () => {}}>
        {img && <img src={img} alt='' className='w-3 mr-1'/>}
        {icon && <Icon className="h-6 w-6 mr-2 text-primary" name={icon} />}
        {t(label)}
      </span>
    </li>
  ) : route ? (
    <li className='rounded-btn capitalize text-base-300 md:justify-center whitespace-nowrap font-medium px-3'>
      <Link
        className={'' + (isSelected(route, location) ? 'font-bold text-secondary' : '')}
        to={route}
      >
        {img && <img src={img} alt='' className='w-3 mr-1'/>}
        {icon && <Icon className="h-6 w-6 mr-2 text-primary" name={icon} />}
        {t(label)}
      </Link>
    </li>
  ) : (
    // Only a title
    <li>
      <p className="rounded-btn capitalize text-base-300 md:justify-center whitespace-nowrap font-medium px-3">{t(label)}</p>
    </li>
  );
}
