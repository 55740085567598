const types = {
  CLEAR_CURRENT: 'PAYMENTS_CLEAR_CURRENT',
  GET_REQUEST: 'PAYMENTS_GET_REQUEST',
  GET_SUCCESS: 'PAYMENTS_GET_SUCCESS',
  GET_FAILURE: 'PAYMENTS_GET_FAILURE',

  SAVE_REQUEST: 'PAYMENTS_SAVE_REQUEST',
  SAVE_SUCCESS: 'PAYMENTS_SAVE_SUCCESS',
  SAVE_FAILURE: 'PAYMENTS_SAVE_FAILURE',

  GETALL_REQUEST: 'PAYMENTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'PAYMENTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'PAYMENTS_GETALL_FAILURE',

  DELETE_REQUEST: 'PAYMENTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'PAYMENTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PAYMENTS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'PAYMENTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PAYMENTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PAYMENTS_UPDATE_FAILURE'    

};

export default types;