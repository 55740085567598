import React from 'react';

const RadioInput = ({
  input,
  meta,
  name,
  label,
  type,
  value,
  alert,
  option,
  setInputs,
  ...rest
}) => {
  return (
    <div className=''>
          <div key={option.value} className="form-control">
            <label
              className={
                'cursor-pointer label' +
                (rest.labelClassName && rest.labelClassName)
              }
            >
              <span className={" " + rest.spanClassName + (input.checked ? `after:block bg-secondary text-white font-semibold shadow-[0_0_5px_1px_#00000077]` : '')}>{option.label}</span>
                <input
                  {...input}
                  type="radio"
                  className={rest.inputClassName}
                  value={option.value}
                  disabled={rest.readOnly || rest.disabled}
                  id={input.name}
                  checked={input.checked}
                />
                <span className="radio-mark"></span>
            </label>
            {meta?.touched && meta?.error && (
              <label className="label">
                <span className="label-text-alt invalid-feedback">
                  {meta?.error && meta?.touched && meta?.error}
                </span>
              </label>
            )}
          </div>
    </div>
  );
};

export default RadioInput;
