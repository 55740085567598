import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import { withTranslation } from "react-i18next";
import ButtonIcon from "../../components/commons/ButtonIcon";
import iconOcasion from "../../assets/icons/custom/app-mobile/gift.png";
import Footer from "../../components/layout/Footer";
import Loader from "../../components/commons/Loader";

import trxActions from "../../context/transactions/actions";
import paymentsActions from "../../context/payments/actions";
import { notify, ToastContainer } from "../../libraries/notifications";
import Button from "../../components/commons/Button";
import { Form, Field } from "react-final-form";
import TextInput from "../../components/forms/TextInput";
import { validateIsfilled, composeValidators, validateEmail } from "../../libraries/utils";
import { capitalize } from "../../libraries/utils";

class CancelGift extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      from: "",
      id: "",
      item: null,
    };
  }
  async componentDidMount() {
    const { user } = this.props.auth;
    const { item } = this.state;
  }

  onSubmit = async (values) => {
    const trx = this.props.item;
    const { user } = this.props.auth;
    console.log(user)
    const json_data = { 
        ...trx.json_data,
        target: user.id,
        recipient: {
            id: user.id,
            email: user.email,
            last_name: user.last_name,
            first_name: user.first_name
        }
    }
    const trxData  = {
        ...trx,
        target: user.id,
        json_data: json_data,
    }
    delete trxData.created_at
    delete trxData.updated_at
    const claimData = {
      id: trx.json_data.hash,
      address: values.address,
    }

    await this.props.onSaveOrUpdateTrx(trxData);
    const { error, item } = this.props.trx;
    if (error) {
        notify(error.message)
        return
    } else {
        await this.props.claim(claimData);
        let { claimed } = this.props;
        if (claimed.error) {
          notify(claimed.error.message);
          return
        }
        const json = JSON.parse(claimed.item.db_transaction.json_data);
        claimed.item.db_transaction.json_data = json;
        if (claimed.item.db_transaction.status === 'redeemed') {
          notify("Regalo reclamado")
          this.props.toggleDel(true)
        } else {
          notify('Algo salió mal, prueba mas tarde o ponte en contacto con el soporte.')
        }    
    }
  }

  render() {
    const { from, id, copied, BTCprice, cards } = this.state;
    const { user } = this.props.auth;
    const { item, del, toggleDel } = this.props;

    const required = (value) =>
      validateIsfilled(value) ? undefined : this.t("This field is required");
    const email = value =>
      validateEmail(value) ? undefined : this.t('Not a valid email');

    const validateAddress = async (value) => {
      const characters = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
      if (value) {
        if (characters.test(value)) {
          return undefined;
        }
      }
      return "Billetera inválida";
    };

    return (
      <div className={(del ? " " : "hidden ") + "absolute top-0 left-0 z-50 grid md:place-content-center place-content-end h-screen w-full bg-black bg-opacity-40"}
      >
        <div className="card white rounded-b-none md:rounded-b-2xl p-4 md:w-[500px] w-screen h-full md:h-full md:-ml-4">
          <ButtonIcon icon='cancel' buttonClassName='z-30 absolute m-2 top-0 right-0 btn btn-ghost w-fit ml-auto' className='w-6 h-6' 
            onClick={toggleDel}
          />
          <Form
            onSubmit={this.onSubmit}
            render={({ handleSubmit, values, form, pristine, submitting, invalid }) => {
              return (
                <form onSubmit={handleSubmit} className="p-2">
                  <div>
                    <div className="text-xl text-center mt-5 mb-10 text-black font-bold">
                      {capitalize(this.t("Cancelar regalo"))}
                    </div>
                    <p className="mb-16 w-3/4 text-center mx-auto">
                        Al cancelar el regalo, los BTC que habías adquirido para él serán enviados a tu billetera personal.
                        Por Ello, te pedimos que nos proporciones tu Address de BTC.
                    </p>
                    <div className="">
                      <Field component={TextInput} name='address' textClassName='text-secondary' label='Address de BTC' inputClassName="w-full bg-[#F5F5F5]"
                        validate={validateAddress}
                      />
                    </div>
                  </div>
                  <div className="w-full mt-10">
                    <Button disabled={pristine || submitting} type="submit"
                      className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white w-full text-center p-2"
                      title={this.state.submitting ?
                          <Loader spinnerClassName="h-5 w-5" containerClassName="p-0"/>
                          : capitalize(this.t("Enviar a mi wallet"))
                      }
                    />
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    auth: state.users.auth,
    trx: state.transactions.current,
    list: state.giftlists.current,
    claimed: state.payments.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrx: (id) => dispatch(trxActions.get(id)),
    onSaveOrUpdateTrx: (data) => dispatch(trxActions.saveOrUpdate(data)),
    onCoin: (params) => dispatch(paymentsActions.getCoin(params)),
    claim: (id)=> dispatch(paymentsActions.claim(id)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CancelGift));