import config from '../../config';
import { request } from '../../libraries/request';

const getAll = (params = {}, token) => {
  console.log('SERVICES::ROLE_USERS::getAll', params, token);
  if (params?.page_num && config.QUERIES_PAGE_SIZE) {
    params.page_size = config.QUERIES_PAGE_SIZE;
  }
  if (!params.order_by) {
    params.order_by = 'title';
    params.order_direction = 'ASC';
  }

  return request({
    url: `${config.BASE_API_URL}${config.API.ROLE_USERS.replace(
      '{id}',
      params.roleID
    )}`,
    accessToken: token,
    // data: params
  }).then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
      response.data.query.page_size = config.QUERIES_PAGE_SIZE;
    }
    return response;
  });
};

const save = (data, token, id) => {
  console.log('SERVICES::ROLE_USERS::save', data, token, id);
  return request({
    url: `${config.BASE_API_URL}${config.API.ROLE_USERS.replace('{id}', id)}`,
    accessToken: token,
    method: 'POST',
    data
  }).then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const update = (data, token) => {
  console.log('SERVICES::ROLE_USERS::update', data, token);
  /*  return request({
    url: `${config.BASE_API_URL}${config.API.ROLE_USERS.replace('{id}', data.id)}`,
    accessToken: token,
    method: 'PATCH',
    data,
  })
  .then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {

    }
    return response;
  }) */
};

const get = (params, token) => {
  console.log('SERVICES::ROLE_USERS::get', params, token);
  return request({
    url: `${config.BASE_API_URL}${config.API.ROLE_USER.replace(
      '{roleId}',
      params.roleID
    ).replace('{id}', params.id)}`,
    accessToken: token
  }).then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const del = (params, token) => {
  console.log('SERVICES::ROLE_USERS::remove', params, token);
  return request({
    url: `${config.BASE_API_URL}${config.API.ROLE_USER.replace(
      '{roleId}',
      params.roleID
    ).replace('{id}', params.id)}`,
    accessToken: token,
    method: 'DELETE'
  }).then(response => {
    console.log('RETURN FROM API', response);
    if (response.success) {
    }
    return response;
  });
};

const services = {
  getAll,
  save,
  get,
  del,
  update
};

export default services;
