import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import userActions from "../../context/users/actions";
import txActions from "../../context/transactions/actions";
import { notify, ToastContainer} from "../../libraries/notifications";
import { history } from "../../routes";
import config from "../../config";
import { capitalize, getObjectWithJsonDataToFromValues } from "../../libraries/utils";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import avatar from '../../assets/icons/custom/Desktop/app-desktop/icon-1.png';
import gift from '../../assets/icons/custom/Desktop/app-desktop/icon-gift.png';
import list from '../../assets/icons/custom/Desktop/app-desktop/icon-list.png';
import MenuItem from '../../components/commons/MenuItem';
import Footer from "../../components/layout/Footer";
import Icon from "../../libraries/icons";


import aniversario from '../../assets/img/cards/aniversario.png';
import bautismo from '../../assets/img/cards/bautismo.png';
import casamiento from '../../assets/img/cards/casamiento.png';
import comunion from '../../assets/img/cards/comunion.png';
import confirmacion from '../../assets/img/cards/confirmacion.png';
import cumpleaños from '../../assets/img/cards/cumpleaños.png';
import snValentin from '../../assets/img/cards/dia de.png';
import graduacion from '../../assets/img/cards/graduacion.png';
import mudanza from '../../assets/img/cards/mudanza.png';
import nacimiento from '../../assets/img/cards/nacimiento.png';
import fiesta from '../../assets/img/cards/fiesta.png';
import navidad from '../../assets/img/cards/navidad.png';
import reyes from '../../assets/img/cards/reyes.png';
import fathers from '../../assets/img/cards/padre.png';
import mothers from '../../assets/img/cards/madre.png';
import btc from '../../assets/img/cards/btc.png';

import g_in from '../../assets/icons/custom/Desktop/app-desktop/icon-3.png';
import g_out from '../../assets/icons/custom/Desktop/app-desktop/icon-2.png'; 

import Loader from "../../components/commons/Loader";


class MyGifts extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      user: null,
      gifts: [],
      cards: {
        christmas: navidad,
        threekings: reyes,
        party: fiesta,
        birthday: cumpleaños,
        wedding: casamiento,
        anniversary: aniversario,
        baptism: bautismo,
        confirmation: confirmacion,
        valentines: snValentin,
        graduation: graduacion,
        move: mudanza,
        birth: nacimiento,
        communion: comunion,
        fathers_day: fathers,
        mothers_day: mothers,
        bitcoin: btc,
        video: false,
      },
      loading: true,
    };
  }

  async componentDidMount() {
    await this.getUser();
    await this.getGifts();
  }

  getUser = async () => {
    await this.props.onGetUser({ id: this.props.auth.user.id });
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      const user = getObjectWithJsonDataToFromValues(this.props.user.item, [
        "id",
        "first_name",
        "last_name",
        "phone",
        "email",
        "profile_image",
        "identification",
        "identification_type",
        "gender",
        "birth_date",
      ]);

      this.setState({ user });
    }
  };

  getGifts = async (params = {}) => {
    // params.target = this.state.user.id;
    params.where = { json_data: [{ field: "source", value: this.state.user.id },{ field: "target", value: this.state.user.id }] }
    params.status = ["approved","completed","redeemed","canceled","scheduled"]
    params.owner = "system"
    // Esto va a funcionar cuando Ale suba los cambios

    // params.source = this.state.user.id;
    await this.props.onGetTxs({ ...params });
    const { error, items } = this.props.gifts;
    if (error) {
      notify(error.message);
      return
    }
    const { user } = this.state;
    const filteredGifts = items.filter( i => {
      if (i.json_data.target === user.id && i.status === "scheduled") return false;
      return true
    })

    this.setState({ gifts: filteredGifts, loading: false });
  };

  toggleVideo = () => {
    this.setState(prev=>{
      return { video: !prev.video }
    })
  }

  render() {
    const { user, gifts, loading, video } = this.state;
    return (
      <LayoutWithSidebar
      header={{logo: true}}
      main={{ className: 'text-content-400' }}
      container={{ className: '' }}
      >
        {/* <ToastContainer /> */}
        <div onClick={this.toggleVideo} className={`absolute top-0 left-0 ${video ? "" : "hidden"} h-full w-full bg-[#00000088] z-50 grid place-content-center`}>
            {video &&
              <iframe className='w-[90vw] h-[45vw]'
                src="https://www.youtube.com/embed/2hFxvv07n_Q" title="YouTube video player" 
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
              </iframe>
            }
          </div>
        <div className="flex flex-row justify-center items-start gap-10 md:py-20 md:mt-10 min-h-[calc(100%-297px)]">
        <div className="hidden w-[300px] md:flex rounded-xl card overflow-visible white  mt-2">
        <ToastContainer />
          <div className='bg-gradient-to-r  from-primary to-primary-focus w-full flex-col items-center gap-2 justify-center p-4 pb-2 rounded-t-xl h-[130px]'>
            <div className="rounded-full -mt-10 border-gray-100 border-4 overflow-hidden flex mx-auto mb-4 text-center w-20 h-20">
              {user && user.profile_image ? (
                <img
                  className="w-full h-auto"
                  src={user && user.profile_image}
                  alt=""
                />
              ) : (
                <span className=" text-white text-2xl">
                  {user && user.username}
                </span>
              )}
            </div>
            <p className='text-center text-white font-bold text-lg'>{user && capitalize(user.first_name)}</p>
              <p className='text-center text-white'>{user && user.email}</p>
          </div>
          <div className='rounded-b-xl bg-white w-full'>
            <ul
              className="menu py-4 text-base-300 cursor-pointer">
              {[ {label: capitalize(this.t('Profile')), route: config.ROUTES.USER.INFO, img: avatar},
              {label: capitalize(this.t('regalos')), route: config.ROUTES.USER.MY_GIFTS, img: gift},
              {label: capitalize(this.t('mis listas')), route: config.ROUTES.USER.MY_LISTS, img: list}].map(
                (item, index) => (
                  <MenuItem location={this.props.location} key={index} item={item} />
                )
              )}
            </ul>
          </div><p className="text-xs text-center mb-2">Crea tu wallet y recibí los Bitcoin que te regalaron!</p>
          <div onClick={this.toggleVideo}
              className='text-white text-xs p-2 mx-3 mb-3 rounded-lg grid place-content-center bg-gradient-to-r from-primary to-primary-focus cursor-pointer'
            >
              {/* <Icon className="h-8 w-8" name="video_camera" /> */}
              Ver video
            </div>
        </div>
        <div className='flex h-full flex-col md:max-w-[450px] rounded-none md:rounded-xl w-full card white'>
          <section className="">
            <div className="bg-gradient-to-r text-white from-primary to-primary-focus px-4 pb-3 pt-10 flex justify-between md:hidden">
              <div>
                <p className="text-2xl">Hola,</p>
                <p className="text-3xl">
                  {user && user.first_name ? capitalize(user.first_name) : ""}
                </p>
                <div className="text-lg mt-4">{capitalize(this.t('regalos'))}</div>
              </div>
              
            </div>
            <div className="hidden p-8 pb-2 md:flex flex-col items-start">
              <h2 className="h2 text-primary"><img src={gift} alt='' className='w-4 inline mr-2'/>{capitalize(this.t("regalos"))}</h2>
            </div><p className="md:hidden text-sm text-center mt-4 mb-1 px-2">Crea tu wallet y recibí los Bitcoin que te regalaron!</p>
            <div onClick={this.toggleVideo}
              className='md:hidden text-white text-xs p-2 mx-3 mb-3 rounded-lg grid place-content-center bg-gradient-to-r from-primary to-primary-focus cursor-pointer'
            >
              {/* <Icon className="h-8 w-8" name="video_camera" /> */}
              Ver video
            </div>
          </section>
          <section className="md:max-h-[500px] px-4 pb-4 overflow-y-auto flex flex-col gap-2">
            {gifts.length !== 0 && !loading ?
              gifts.map((gift, i) => {
                return (
                  <div key={i}
                    onClick={()=>history.push({pathname: config.ROUTES.USER.MY_GIFT.replace(':id', gift.id)})}
                    className={"p-2 flex bg-secondary bg-opacity-10 justify-start cursor-pointer hover:bg-base-200 rounded-lg "
                      + ((gift.status === 'redeemed' || gift.status === 'completed') ? 'opacity-50' : '')
                      + ((gift.status === 'canceled') ? 'bg-error' : '')
                    }
                  >
                    <div className="relative w-28 h-28 mr-3">
                      <img src={this.state.cards[gift.json_data.event_type]} alt='card' className="object-contain w-28 h-28" />
                      {gift.json_data.source === user.id && <img src={g_out} className='absolute w-7 bottom-0 right-0' />}
                      {gift.json_data.target === user.id && gift.json_data.source !== user.id && <img src={g_in} className='absolute w-7 bottom-0 right-0' />}
                      {gift.status === "scheduled" && <Icon name='calendar' size={28} className='absolute text-primary bg-white rounded-full p-1 top-0 left-0' />}
                    </div>
                    <div className="flex-1 flex flex-col justify-between py-1">
                      <div>
                        <p className="text-lg mb-2 leading-5">
                          {gift.json_data.source === user.id && ('Regalaste a ' + capitalize(gift.json_data.recipient.first_name))} {/* dar vuelta target y source cuando mariano lo arregle */}
                          {gift.json_data.target === user.id && gift.json_data.source !== user.id && ('Recibiste de ' + capitalize(gift.json_data.sender.first_name))}
                        </p>
                        <p className={`${gift.json_data.source === user.id ? 'text-error' : 'text-success'} font-bold`}>{gift.total} Bitcoin</p>
                        {gift.json_data.total_ARS && <p>${gift.json_data.total_ARS} ARS</p>}
                      </div>
                      <div className="flex justify-between items-center w-full">
                        <p className="text-sm text-gray-500">{this.t("timeRemains", { date: new Date(gift.date) })}</p>
                        {(gift.status === 'redeemed' || gift.status === 'completed') && <p className="badge badge-sm badge-outline">Reclamado</p>}
                        {(gift.status === 'canceled') && <p className="badge badge-sm badge-outline text-error">Cancelado</p>}
                      </div>
                    </div>
                  </div>
                );
              }) : loading ? <Loader /> : <p className="p-8">{this.t("No has realizado regalos")}</p>
                
              }
              
          </section>
        </div>
        </div>
        <Footer className="hidden md:block" />
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    auth: state.users.auth,
    gifts: state.transactions.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: (params) => dispatch(userActions.get(params)),
    onGetToken: () => dispatch(userActions.getFromToken()),
    onGetTxs: (params) => dispatch(txActions.getAll(params)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(MyGifts));
