import React from 'react';

const TextWithIconInput = ({ input, meta, label, ...rest }) => {
  return (
    <div className={'form-control ' + (rest.className ? rest.className : '')}>
      {label && (
        <label
          htmlFor={input.name}
          className={
            'label ' + (rest.labelClassName ? rest.labelClassName : '')
          }
        >
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className="relative">
        <input
          {...input}
          type={rest.showPassword === false ? 'password' : 'text'}
          className={
            'input w-full ' +
            (meta?.error && meta?.touched
              ? 'input-error invalid-shadow '
              : '') +
            (rest.inputClassName ? rest.inputClassName : 'input-bordered')
          }
          placeholder={rest.placeholder}
          disabled={rest.disabled}
          readOnly={rest.readOnly}
        />
        {typeof rest.showPassword !== 'undefined' && (
          <button
            type="button"
            className="absolute right-0 mt-3 mr-4"
            onClick={rest.clickPassword && rest.clickPassword}
          >
            {rest.showPassword && (
              <svg
                className="text-gray-600 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            )}
            {!rest.showPassword && (
              <svg
                className="text-gray-600 h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                />
              </svg>
            )}
          </button>
        )}
      </div>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">
            {meta.error && meta.touched && meta.error}
          </span>
        </label>
      )}
    </div>
  );
};

export default TextWithIconInput;
