import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { PrivateRoute } from '../components/commons/PrivateRoute';
import config from '../config';
import Error from '../pages/commons/Error';
import Home from '../pages/Home';

import UserLogin from '../pages/auth/UserLogin';
import UserSignup from '../pages/auth/UserSignup';
import UserPassword from '../pages/auth/UserPassword';
import UserPasswordChange from '../pages/auth/UserPasswordChange';
import UserVerify from '../pages/auth/UserVerify';
import UserInfo from '../pages/auth/UserInfo';
import UserForgot from '../pages/auth/UserForgot';
import UserProfile from '../pages/auth/UserProfile';
import InfoTerms from '../pages/info/InfoTerms';
import InfoContactUs from '../pages/info/InfoContactUs';
import InfoPrivacy from '../pages/info/InfoPrivacy';
import InfoHelp from '../pages/info/InfoHelp';

import NewList from '../pages/list/NewList';
import ConfirmList from '../pages/list/ConfirmList';
import Gift from '../pages/gift/Gift';
import GiftCard from '../pages/gift/GiftCard';
import Congratulations from '../pages/Congratulations';
import GiftConfirm from '../pages/gift/GiftConfirm';
import GiftCurrency from '../pages/gift/GiftCurrency';
import GiftAmount from '../pages/gift/GiftAmount';
import List from '../pages/list/FromList';
import ChooseCard from '../pages/list/ChooseCard';
import MyGifts from '../pages/user/MyGifts';
import MyLists from '../pages/user/MyLists';
import OperationCheck from '../pages/gift/OperationCheck';
import Details from '../pages/user/MyDetails';
import RedirectRedeem from '../pages/RedirectRedeem';
import GitfBTC from '../pages/gift/GitfBTC';
import {PublicRoute} from '../components/commons/PublicRoute';

export const history = createBrowserHistory();

export const Routes = () => {
  const location = useLocation();
  const { t } = useTranslation();  

  return (
    <Switch location={location}>
      <Route path={config.ROUTES.TERMS} exact component={InfoTerms} />
      <Route path={config.ROUTES.CONTACT_US} exact component={InfoContactUs} />
      <Route path={config.ROUTES.PRIVACY} exact component={InfoPrivacy} />
      <Route path={config.ROUTES.HELP} exact component={InfoHelp} />

      <Route path={config.ROUTES.LOGIN} exact component={UserLogin} />
      <Route path={config.ROUTES.SIGNUP} exact component={UserSignup} />
      <Route path={config.ROUTES.SIGNUP_PRE} exact component={UserSignup} />
      <Route path={config.ROUTES.PASSWORD} exact component={UserPassword} />
      <Route path={config.ROUTES.VERIFY} exact component={UserVerify} />
      <Route path={config.ROUTES.FORGOT} exact component={UserForgot} />

      <PrivateRoute path={config.ROUTES.USER.PASSWORD_CHANGE} exact component={UserPasswordChange} />
      <PrivateRoute path={config.ROUTES.USER.PROFILE} exact component={UserProfile} />
      <PrivateRoute path={config.ROUTES.USER.INFO} exact component={UserInfo} />
      <PrivateRoute path={config.ROUTES.USER.MY_GIFTS} exact component={MyGifts} />
      <PrivateRoute path={config.ROUTES.USER.MY_LISTS} exact component={MyLists} />
      <PrivateRoute path={config.ROUTES.USER.MY_GIFT} exact component={Details} />
      <PrivateRoute path={config.ROUTES.USER.MY_LIST} exact component={Details} />

      <PrivateRoute path={config.ROUTES.CHOOSE_CARD} exact component={ChooseCard} />
      <PrivateRoute path={config.ROUTES.LIST_NEW} exact component={NewList} />
      <PrivateRoute path={config.ROUTES.LIST_CONFIRM} exact component={ConfirmList} />
      
      <PublicRoute path={config.ROUTES.LIST} exact component={List} />
      
      <PublicRoute path={config.ROUTES.GIFT} exact component={Gift} />
      <PublicRoute path={config.ROUTES.GIFT_CARD} exact component={GiftCard} />
      <PublicRoute path={config.ROUTES.GIFT_CONFIRM} exact component={GiftConfirm} />
      <PublicRoute path={config.ROUTES.GIFT_CURRENCY} exact component={GiftCurrency} />
      <PublicRoute path={config.ROUTES.GIFT_AMOUNT} exact component={GiftAmount} />
      <PublicRoute path={config.ROUTES.GIFT_BTC} exact component={GitfBTC} />
      <PublicRoute path={config.ROUTES.GIFT_DUMMY} exact component={Gift} />

      <PublicRoute path={config.ROUTES.OP_CHECK} exact component={OperationCheck} />
      <PublicRoute path={config.ROUTES.CONGRATULATIONS} exact component={Congratulations} />

      <Route path={config.ROUTES.REDIRECT_REDEEM} exact component={RedirectRedeem} />

      <PublicRoute path={config.ROUTES.HOME} exact component={Home} />

      <Route>
        <Error title={t('404')} headline={t('Page not found!')} button={t('Go back')}/>
      </Route>        
    </Switch>
  );
}