import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import config from '../../config';
import logo from '../../assets/img/logo.png';
import logoNegative from '../../assets/img/logo-negative.png';
import Icon from '../../libraries/icons';
import Button from '../commons/Button';
import ButtonIcon from '../commons/ButtonIcon';
import MenuItem from '../commons/MenuItem';
import UserProfileImage from '../commons/UserProfileImage';
import usersActions from '../../context/users/actions';
import { history } from '../../routes';
import { useTranslation } from 'react-i18next';
import { capitalize } from "../../libraries/utils";

import avatar from '../../assets/icons/custom/Desktop/app-desktop/icon-1.png';
import gift from '../../assets/icons/custom/Desktop/app-desktop/icon-gift.png';
import list from '../../assets/icons/custom/Desktop/app-desktop/icon-list.png';

const images = Object.freeze({ avatar, gift, list });

export default function SidebarDesktop({ header }) {
  const location = useLocation();
  const [collapseShow, setCollapseShow] = React.useState('close');
  const user = useSelector(state => state.users.auth.user);
  const logged = useSelector(state => state.users.auth.logged);
  const notifications = useSelector(state => state.notifications);
  const { t } = useTranslation();
  const mainMenu = config.SIDEBAR.MENU.MAIN;
  const secondaryMenu = config.SIDEBAR.MENU.SECONDARY;

  const dispatch = useDispatch();
 
  const doLogout = e => {
    e.preventDefault();
    dispatch(usersActions.logout());
  };

  return (
    <nav className="bg-gray-200">
      {notifications && !!notifications?.list?.items?.length && 
        <div
          onClick={()=>history.push(config.ROUTES.USER.MY_GIFTS)}
          className="bg-amber-400 alert w-fit rounded-full p-2 pop-up after:!left-[-260px] after:content-[attr(after)] after:!p-3 after:font-bold !absolute right-0 m-1 md:m-3 md:mr-6 cursor-pointer"
          after={`¡Tienes ${notifications?.list?.items?.length} regalos sin reclamar!`}
        >
                <div>
                  <Icon name='notification' className="w-6 h-6" />
                  {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg> */}
                  {/* <span>¡Tienes {notifications?.list?.items?.length} regalos sin reclamar!</span> */}
                </div>
              </div>
      }

      <div className={"fixed w-full z-10 py-3 px-4 left-0 right-0 top-0 md:bottom-auto md:overflow-y-auto md:overflow-hidden border-l-0  xs:p-0 " 
      + (mainMenu && location.pathname !== config.ROUTES.HOME ? 'md:bg-white ' : 'bg-transparent') }
      >
        <div className="container mx-auto flex flex-wrap items-center justify-between md:flex-row md:flex-no-wrap">
        {header?.logo ? (
          <div className="relative h-20 w-[150px]">
            {header?.back && 
              <div onClick={()=>{history.goBack()}} className='md:hidden cursor-pointer z-[55]'>
                <Icon name='arrow_left' className='w-6 h-6 m-4'/>
              </div>
            }
            <img onClick={()=>history.push(config.ROUTES.HOME)} className={"cursor-pointer absolute top-0 left-0 " + (collapseShow === 'open' ? ' ' : 'z-[100] ') + (mainMenu && location.pathname !== config.ROUTES.HOME ? 'hidden md:block' : '')} src={header.logo == 'negative' ? logoNegative : logo} alt="" width="150px" />
          </div>
        ) : (
          <div onClick={()=>{history.goBack()}} className='cursor-pointer z-[55]'>
            <Icon name='arrow_left' className='w-6 h-6 m-4'/>
            {header?.title && <h2 className="h2 md:hidden">{header.title}</h2>}
          </div>
        )}
        <ButtonIcon
          buttonClassName="btn-ghost md:hidden pr-1 pt-2"
          className="w-10 h-10 rotate-180"
          onClick={() => setCollapseShow('open')}
          icon="menu_alt_1"
        />
        
        {header?.breadcrumbs && (
          <ol className="list-none p-0 inline-flex md:hidden">
            {header.breadcrumbs.map((item, index) => (
              <li key={`bc${index}`} className="flex items-center">
                <span className="pr-2">{item}</span>
                {header.breadcrumbs.length > index + 1 ? (
                  <Icon className="w-8 h-8 pr-2" name="cheveron_right" />
                ) : null}
              </li>
            ))}
          </ol>
        )}
        
          {header && header.right && (
          <div className="w-12">
            <ButtonIcon
              buttonClassName="btn-ghost btn-sm"
              className={
                header.right.className ? header.right.className : 'h-6 w-6'
              }
              onClick={header.right.action}
              icon={header.right.icon}
            />
           </div>
          )}

        <div
          className={
            'bg-white md:bg-transparent container mx-auto text-base-content z-50 transform top-0 left-0 right-0 bottom-0 md:bottom-auto w-full md:w-full fixed h-full md:h-auto overflow-auto ease-in-out transition-all duration-300 flex justify-between md:justify-end flex-col md:translate-x-0 md:flex-row ' +
            (collapseShow === 'close' ? '-translate-x-full' : 'translate-x-0')
          }>
          <div className="flex flex-col md:flex-row md:justify-between w-full md:w-fit container">
            <div className="w-full bg-base-200 md:bg-transparent bg-opacity-50 md:hidden">
              <div className="flex items-center justify-between p-3">
                <img className="md:hidden" width="150px" src={logo} alt="" />
                <ButtonIcon
                  buttonClassName=" bg-transparent border-none text-neutral-focus md:hidden"
                  className="w-10 h-10"
                  onClick={() => setCollapseShow('close')}
                  icon="close"
                />
              </div>
              <div className="py-3 px-4 bg-base-200 md:hidden">
                {logged &&
                  <>
                    <div className="flex items-center px-5">
                      { user && <UserProfileImage data={user}  />}  
                    </div>
                    <ul
                      className="menu pt-4 text-base-300"
                      style={{ cursor: 'pointer' }}>
                      {
                        secondaryMenu.map(
                        (item, index) => (
                          <MenuItem key={index} item={item} img={images[item.image]} action={()=> history.push(item.route)}/>
                        )
                      )}
                    </ul>
                  </>}
                {!logged && 
                  <div className=''>
                    <div>Bienvenido</div>
                    <div className='flex items-center gap-2 text-secondary'>
                      <Button
                        className='btn-link btn-sm text-secondary p-0'
                        title='Ingresá'
                        onClick={()=>history.push(config.ROUTES.LOGIN)}
                      /> / 
                      <Button
                        className='btn-link btn-sm text-secondary p-0'
                        title='Registrate'
                        onClick={()=>history.push(config.ROUTES.SIGNUP)}
                      />
                    </div>
                  </div>
                }
              </div>
              
            </div>
            <div className='mt-10 pl-7 md:hidden uppercase text-xs md:text-white text-[#B9B9B9] leading-[12px] '>{t("navegación")}</div>
            <ul className="menu justify-end md:flex-row mt-2">
              {mainMenu && location.pathname !== config.ROUTES.HOME &&
                mainMenu.map((item, index) => (
                  <MenuItem key={index} item={item} location={location} />
              ))}
              {mainMenu && location.pathname === config.ROUTES.HOME &&
                mainMenu.map((item, index) => (
                  <li onClick={()=>setCollapseShow('close')} className='rounded-btn text-base-300 md:text-white md:justify-center whitespace-nowrap font-medium px-3' key={index}>
                    <a href={`#${item.anchor}`}>{capitalize(t(item.label))}</a>
                  </li>
                )) 
              }
              
            </ul>
          </div>
          <div className='flex w-fit'>
            <Button className='m-4 font-normal text-lg btn-primary border-btn rounded-btn border-white border-opacity-[0.25] text-white'
              onClick={()=>history.push(config.ROUTES.GIFT_CARD)} title={capitalize(t("regalá"))}
            />
            <Button className='m-4 font-normal text-lg btn-secondary border-btn rounded-btn border-white border-opacity-[0.25] text-white'
              onClick={()=>history.push(config.ROUTES.CHOOSE_CARD)} title={capitalize(t("armá tu lista"))} 
            />
          </div>
          {!logged &&
            <span className='m-4 w-fit hidden md:flex text-white items-center gap-2'>
              <Button
                className={'btn-link btn-sm p-0 ' + (mainMenu && location.pathname === config.ROUTES.HOME ? 'text-white ' : '')}
                title='Ingresá'
                onClick={()=>history.push(config.ROUTES.LOGIN)}
              />
              / 
              <Button
                className={'btn-link btn-sm p-0 ' + (mainMenu && location.pathname === config.ROUTES.HOME ? 'text-white ' : '')}
                title='Registrate'
                onClick={()=>history.push(config.ROUTES.SIGNUP)}
              />
            </span>
          }
          {logged &&
            <div className="md:flex items-center px-5 hidden cursor-pointer" onClick={()=>history.push(config.ROUTES.USER.INFO)}>
              
              {user && <UserProfileImage textContainer={(mainMenu && location.pathname === config.ROUTES.HOME ? 'text-white ' : '')} data={user} />}
               
            </div>
            
            /* <ul
              className="menu pt-4 text-base-300"
              style={{ cursor: 'pointer' }}>
              {[ {label: t('Profile'), action: ()=>history.push(config.ROUTES.USER.INFO), img: avatar},
              {label: t('Regalos'), action: ()=>history.push(config.ROUTES.USER.MY_GIFTS), img: gift},
              {label: t('Mis listas'), action: ()=>history.push(config.ROUTES.USER.MY_LISTS), img: list}].map(
                (item, index) => (
                  <MenuItem key={index} item={item} />
                )
              )}
            </ul> */
          }
        </div>
        <div
          onClick={() => setCollapseShow('close')}
          className={
            'fixed inset-0 z-10 w-screen h-screen bg-black bg-opacity-25 md:hidden ' +
            (collapseShow === 'open' ? '' : 'hidden')
          }></div>
          </div>
      </div>
    </nav>
  
  );
}
