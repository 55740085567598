import React from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../components/layout/LayoutWithSidebar";
import Navbar from "../components/layout/Navbar";
import { history } from "../routes";
import config from "../config";
import Footer from "../components/layout/Footer";
import CollapseQuestion from "../components/customs/CollapseQuestion";
import cheveronLeft from "../assets/icons/cheveron-left.svg";
import cheveronRight from "../assets/icons/cheveron-right.svg";
import { capitalize, capitalizePhrase } from "../libraries/utils";
import { withTranslation } from "react-i18next";
import Button from "../components/commons/Button";

import coverArmar from "../assets/img/bg-lista-cover.png";
import coverRegala from "../assets/img/bg-regala-cover.bitcoin-coin.png";
import ok1 from "../assets/icons/custom/Landing/ok-1.png";
import ok2 from "../assets/icons/custom/Landing/ok-2.png";
import ok3 from "../assets/icons/custom/Landing/ok-3.png";
import ok4 from "../assets/icons/custom/Landing/ok-4.png";
import illus_1 from '../assets/icons/custom/Landing/illus-1.png';
import illus_2 from '../assets/icons/custom/Landing/illus-2.png';
import illus_3 from '../assets/icons/custom/Landing/illus-3.png';
import illus_4 from '../assets/icons/custom/Landing/illus-4.png';
import illus_5 from '../assets/icons/custom/Landing/illus-5.png';
import illus_6 from '../assets/icons/custom/Landing/illus-6.png';
import illus_7 from '../assets/icons/custom/Landing/illus-7.png';
import arrow from '../assets/icons/custom/app-mobile/arrow-right.png';
import moneda from '../assets/icons/custom/Landing/Moneda-BTC.png';

const HomeSlide = (props) => {
  const [slide, setSlide] = React.useState(true)
  const swapSlide = () => {setSlide(!slide)}

  React.useEffect(() => {
    const interval = setInterval(() => {
      setSlide(slide => !slide)
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {slide &&
        <div className="bg-gradient-to-br from-primary-focus to-primary text-white">
          <div className="cover-regala relative">
            <div className="flex-1 ">
              <img src={coverRegala} alt='cover' className="h-56 max-w-none mx-auto md:hidden" />
            </div>
            <div className="w-3/5">
              <h1 className="text-center md:text-left">
                {capitalize(props.t('el mejor regalo que podes hacer'))}
              </h1>
              <p className="text-center md:text-left subtitle my-5">Sumá a tus familiares y amigos a la <b>Revolución Bitcoin</b></p>
              <div className="flex flex-col md:flex-row gap-4 items-center w-full mt-10">
                <Button className="px-12 btn-primary btn-lg bg-primary-focus border-white border-opacity-50 btn-block md:w-auto text-white mb-4 md:mb-0 box-shadow-lg" title={capitalize(props.t('regalá bitcoins'))} onClick={()=>history.push(config.ROUTES.GIFT_CARD)}></Button>
                <Button className="btn-sm btn-link md:btn-lg text-white" onClick={props.scrollToHow} title={(props.t('¿Cómo funciona?'))}></Button>
              </div>
              <div className="flex justify-center items-center md:justify-start gap-2 absolute bottom-5 left-0 right-0">
                <span className="w-3 h-3 rounded-full bg-white" />
                <span onClick={swapSlide} className="w-3 h-3 rounded-full bg-white bg-opacity-40" />
              </div>
            </div>
          </div>
        </div>
      }
      {!slide &&
        <div className="bg-gradient-to-br from-secondary-focus to-secondary text-white">
          <div className="cover-regala relative">
            <div className="flex-1">
              <img src={coverArmar} alt='cover' className="h-56 max-w-none mx-auto md:hidden" />
            </div>
            <div className="w-3/5">
              <h1 className="text-center md:text-left">
                {capitalize(props.t('armá tu lista de regalo en Bitcoin'))}
              </h1>
              <p className="text-center md:text-left subtitle my-5">Sumá a tus familiares y amigos a la <b>Revolución Bitcoin</b></p>
              <div className="flex flex-col md:flex-row gap-4 items-center w-full mt-10">
                <Button className="px-12 btn-secondary btn-lg bg-secondary-focus border-white border-opacity-50 btn-block md:w-auto text-white mb-4 md:mb-0 box-shadow-lg" title={capitalize(props.t('armá tu lista'))} onClick={()=>history.push(config.ROUTES.CHOOSE_CARD)}></Button>
                <Button className="btn-sm btn-link md:btn-lg text-white" onClick={props.scrollToHow} title={(props.t('¿Cómo funciona?'))}></Button>
              </div>
              <div className="flex justify-center items-center md:justify-start gap-2 absolute bottom-5 left-0 right-0">
                <span onClick={swapSlide} className="w-3 h-3 rounded-full bg-white bg-opacity-40" />
                <span className="w-3 h-3 rounded-full bg-white" />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.interval = 
    this.state = {
      como: "regala",
      slide: true,
      testimonio: 0,
      showFAQ: false,
      interval: undefined,

    };

    this.howRef = React.createRef();
  }

  componentDidMount() {
    const scrollHeader = document.querySelector("div.flex-1.overflow-y-auto");

    scrollHeader.addEventListener("scroll", event => {
      if (scrollHeader.scrollTop > 120) {
        document.querySelector("nav .fixed").classList.add('bg-primary-focus');
        document.querySelector("nav .fixed").classList.remove('bg-transparent');
      } else {
        document.querySelector("nav .fixed").classList.remove('bg-primary');
        document.querySelector("nav .fixed").classList.add('bg-transparent');
      }
    }, { passive: true });

  }

  scrollToHow = () => {
    this.howRef.current.scrollIntoView({behavior: "smooth"})
  }

  comoFunciona(como) {
    this.setState({como})
  }

  nextTest() {
    this.setState(prev => {
      if (prev.testimonio < 2) {
        return { testimonio: prev.testimonio + 1 }
      }  
    })
  }
  prevTest() {
    this.setState(prev => {
      if (prev.testimonio > 0) {
        return { testimonio: prev.testimonio - 1 }
      }  
    })
  }

  FAQvisibility() {
    this.setState(prev => {
      return { showFAQ: !prev.showFAQ }
    })
  }

  render() {
    const { como, testimonio, showFAQ } = this.state;

    const FAQ = [
      {question: '¿Por qué Bitcoin es el mejor regalo?',answer: 'Creemos que Bitcoin es el mejor regalo para cualquier ocasión y para el futuro del agasajado porque es un regalo muy original y si alguna vez pensaste en regalar un sobrecito con plata, una gift card o un Big-Box no lo pienses más, regalar Bitcoin es todo eso pero con esteroides. Cuando regalás Bitcoin no sólo estás regalando una porción del único activo monetario realmente escaso del mundo y que tiende a apreciarse con el tiempo, sino que invita a repensar sobre la confianza, la transparencia, la propiedad y el origen del valor.'},
      {question: '¿Qué es y cómo funciona Regalale Bitcoins?',answer: 'RegalaleBitcoins.com es la forma más fácil de regalar o de que te regalen Bitcoins. No requiere abrir cuentas en un Exchange, ni poseer billeteras virtuales, y podés pagarlos con tarjeta o Mercado Pago. Para ello eliges la tarjeta con la "ocasión especial" del regalo y una dedicatoria, Indicas el monto a regalar, el mail de la persona del destinatario y pagás. Le llegará un mail a tu agasajado con las instrucciones para reclamarlos.Más adelante podrás sumarle otros elementos físicos a tu regalo, incluso enviar los Bitcoins en billeteras de papel inviolable.'},  
      {question: '¿Hay límites de monto para las operaciones?',answer: 'Por el momento hemos limitado los regalos a sólo 3 montos posibles: 25, 50 y 100 dólares (pagaderos en ARS). Podrás sin embargo regalar a la misma persona más de una tarjeta ;-). Más adelante abriremos más opciones e incluso, si ya posees Bitcoins y quieres regalarlos en forma fácil para el receptor que aún no sabe del tema, te permitiremos que envíes la cantidad que quieras con nuestra plataforma sin necesidad de comprarlos a través nuestro.'},
      {question: '¿Cuál es el costo del servicio?',answer: 'Quién regale Bitcoins pagará la compra de los mismos a valor de mercado (según Binance pero en pesos argentinos). A eso nosotros le sumaremos 7 dólares fijos por nuestro servicio. Quién recibe los Bitcoins, al momento de retirarlo hacia una billetera propia (cosa que aconsejaremos), se le descontarán aproximadamente 1 milésimas de Bitcoin (centavos de dólar) por comisiones propias de la red de Bitcoin.'},
      {question: '¿Tiene alguna demora la acreditación de los fondos en la tarjeta de regalo?',answer: 'En nuestro sistema el proceso de asignación es automático tras el pago, pero el envío de Bitcoin (Blockchain) a la billetera dependerá de Binance y la congestión de la blockchain, razón por la cual puede llegar a demorar 10 o más minutos.'},
      {question: '¿Cómo se envía el regalo y qué recibe el destinatario?',answer: 'Una vez que hayamos recibido el pago, el sistema envíará un email al destinatario y también le ofrecerá a usted bajarse un link para enviarlo manualmente. En breve permitiremos también programar una fecha diferida para el envío del mail. Dicho mail (o link) le permite al agasajado crear instantáneamente un usuario y ver su regalo con su saludo y el monto regalado en Bitcoin. También podrá poner una dirección de Bitcoin a donde desee retirarlos. Si el receptor no posee aún billetera, un sencillo vídeo le enseñará a hacerlo y a retirar los Bitcoins. En breve permitiremos imprimir desde ahí mismo una billetera de papel.'}, 
      {question: '¿Cómo se hace para vender los Bitcoins?',answer: 'Una de las razones para regalar Bitcoin es que es el activo monetario más escaso del mundo y que probablemente es mejor atesorarlo para cuando uno sea grande o para sus hijos, que venderlo. Sin embargo hay varias posibilidades para poder vender o usar los bitcoins, sea en plataformas con registro o entre personas. En este <a href="https://coinmonitor.info/" class="text-blue-600">link<a> podrás comparar el precio de Bitcoin y las distintas empresas de compraventa y elegir en la que harás tu operación.'},
      {question: '¿Es legal comprar o vender Bitcoins?',answer: '100% legal. En la enorme mayoría de los países de latinoamérica y del mundo, no hay restricciones legales a ninguna de estas operatorias, según su país y el monto mantenido raramente deberá analizar la necesidad de declararlo entre sus bienes, pero puede consultarlo con su contador.'},  
      {question: '¿Podré ver si el destinatario redimió el regalo?',answer: 'Una vez que el agasajado redimió su regalo, te notificaremos del retiro de los fondos de la “tarjeta de Regalo” y también te aparecerá como tarjeta redimida. Si éste aún no lo hizo, podrás mandarle el link que te provee la plataforma y recordarle por ejemplo por whatsapp. Todo esto lo puedes hacer desde el administrador de tu usuario en la plataforma. Pronto agregaremos también la posibilidad de saber si al menos abrió el link pero aún no lo redimió.'},
      {question: '¿Cómo hago para compartir mi lista de regalos con mis amigos?',answer: 'Una vez que armes una Lista de Regalo y en la cual nos habrás indicado a qué wallet querés que te mandemos los fondos, te aparecerá un link para compartir con tus amigos. Ellos deberán crear un usuario para poder comprar y mandarte, proceso que les llevará menos de 1 minuto. Finalmente desde tu administrador, podrás ver quienes te regalaron, sus salutaciones y reclamar el envío de esos Bitcoins a tu casilla.'}, 
      {question: '¿Se puede regalar algo físico además de los Bitcoins?',answer: 'Muy pronto. Al elegir la tarjeta y el monto podrás además agregar más productos junto al servicio de envío a domicilio en Argentina, de una Billetera de Bitcoin de papel inviolable, o algunos elementos tipo monedas, gorras, hardware wallets, o etc.'}, 
      {question: '¿Quién está detrás de Regalale Bitcoins?', answer: 'Somos un grupo de emprendedores argentinos y colombianos con más de 10 años en el espacio y convencidos de que Bitcoin es el mejor activo monetario del mundo y motivados por dar a conocer y facilitar la adquisición del mismo para cada vez más gente'},
    ];

    const testimonios = [
      {img: "", title: "titulo lorem input", text: "lorem ipsum dolor sit amet, consectetur adipiscing elit utaliquam, purus sit amet luctus venenatis, lectus magna fringilla urna"},
      {img: "", title: "titulo lorem", text: "lorem ipsum dolor sit amet, consectetur adipiscing elit utaliquam, purus sit amet luctus venenatis, lectus magna fringilla urna"},
      {img: "", title: "titulo", text: "lorem ipsum dolor sit amet, consectetur adipiscing elit utaliquam, purus sit amet luctus venenatis, lectus magna fringilla urna"}
    ];

    return (
      <LayoutWithSidebar header={{logo: 'negative'}}>
        {/* <Navbar>
        </Navbar> */}
        <section id='' className="">{/* px-10 pt-8 */}
        
        <HomeSlide scrollToHow={this.scrollToHow} t={this.t}/>
           
        </section>
        <section id='why' className="p-10 pb-0 bg-white">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold text-center">
            {(this.t('¿Por qué Bitcoin es el mejor regalo?'))}
            </h2>
            <div className="grid md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1">
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('evitá perder tiempo en encontrar el regalo ideal.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('es un regalo original.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('un regalo que le podría cambiar la vida.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('fácil, seguro y divertido.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('no ocupa lugar.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('se ajusta al bolsillo de cada uno.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('es interesante para todas las edades.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('es el mismo para todos pero diferente para cada uno.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('será el tema central de las conversaciones de la noche.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('se hace más interesante mientras más tiempo lo guardan.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('es tecnológico pero no necesita computadora.'))}
                </p>
              </div>
              <div className="flex flex-col items-center mt-10">
                <img src={moneda} alt='ok' className="w-14" />
                <p className="text-lg pt-6 text-center w-[250px]">
                {capitalize(this.t('sobre todo tiene un mensaje potente.'))}
                </p>
              </div>
            </div>
            <img src={illus_1} alt='leaf' className="mx-auto mt-16" />
          </div>
        </section>
        <section className="bg-primary text-white text-center py-20">
          <div className="container mx-auto flex flex-wrap justify-center md:justify-between gap-10">
            <div className="flex flex-col gap-2 items-center">
              <div className="text-5xl font-bold py-2">+50 Millones</div>
              <div className="text-lg font-bold" style={{ letterSpacing: "6px" }}>
                {this.t('DE USUARIOS')}
              </div>
              <div className="text-base">{capitalize(this.t('en el mundo'))}</div>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <div className="text-5xl font-bold py-2">+1300 Billones</div>
              <div className="text-lg font-bold" style={{ letterSpacing: "6px" }}>
                {this.t('DE DÓLARES DE CAPITALIZACIÓN')}
              </div>
              <div className="text-base">{capitalize(this.t('de mercado al 30 de mayo de 2024.'))}</div>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <div className="text-5xl font-bold py-2">$73.737</div>
              <div className="text-lg font-bold" style={{ letterSpacing: "6px" }}>
                {this.t('DE USD')}
              </div>
              <div className="text-base">{capitalize(this.t('es el precio máximo (14 de Marzo, 2024).'))}</div>
            </div>
          </div>
        </section>
        <section id='how_works' ref={this.howRef} className="py-20 container mx-auto">
            <h2 className="text-4xl font-bold text-center mb-8">
                {(this.t('Cómo funciona'))}
            </h2>
            <div className="flex text-lg justify-center gap-10 items-center">
              <div className={`${como === "regala" ? "relative z-[1] text-white after:absolute after:top-0 after:left-0 after:z-[-1] after:bg-primary after:w-full after:h-full after:rotate-[-5deg]" : ""} px-4 py-2`} onClick={this.comoFunciona.bind(this, "regala")}>
                {capitalize(this.t('regalá'))}
              </div>
              <div className={`${como === "armar" ? "relative z-[1] text-white after:absolute after:top-0 after:left-0 after:z-[-1] after:bg-primary after:w-full after:h-full after:rotate-[5deg]" : ""} px-4 py-2`} onClick={this.comoFunciona.bind(this, "armar")}>
                {capitalize(this.t('armá tu lista'))}
              </div>
            </div>
            {como === "armar" &&
              <div className="py-20 flex flex-col lg:flex-row justify-center items-center gap-4">
                <div className="flex flex-col items-center">
                  <div className="flex flex-col items-center">
                    <img src={illus_3} alt='how' className="w-20 mb-4"/>
                    <div className="flex items-center gap-5">
                      <p className="text-lg w-[250px] text-center">
                        {capitalize(this.t('creá tu cuenta'))}
                      </p>
                    </div>
                  </div>
                </div>
                <img src={arrow} alt='arrow' className="rotate-90 lg:rotate-0" />
                <div className="flex flex-col items-center">
                  <div className="flex flex-col items-center">
                    <img src={illus_7} alt='how' className="w-20 mb-4"/>
                    <div className="flex items-center gap-5">
                      <p className="text-lg w-[250px] text-center">
                      {capitalize(this.t('elegí el evento, mensaje y wallet donde van a ir los Bitcoin.'))}
                      </p>
                    </div>
                  </div>
                </div>
                <img src={arrow} alt='arrow' className="rotate-90 lg:rotate-0" />
                <div className="flex flex-col items-center">
                  <div className="flex flex-col items-center">
                    <img src={illus_4} alt='how' className="w-20 mb-4"/>
                    <div className="flex items-center gap-5">
                      <p className="text-lg w-[250px] text-center">
                      {capitalize(this.t('compartí el link con tus amigos y familia.'))}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }
            {como === "regala" &&
              <div className="py-20 flex flex-col lg:flex-row justify-center items-center gap-4">
              <div className="flex flex-col items-center">
                <div className="flex flex-col items-center">
                  <img src={illus_7} alt='how' className="w-20 mb-4"/>
                  <div className="flex items-center gap-5">
                    <p className="text-lg w-[250px] text-center">
                    {capitalize(this.t('elegí el evento y el monto que vas a pagar.'))}
                    </p>
                  </div>
                </div>
              </div>
              <img src={arrow} alt='arrow' className="rotate-90 lg:rotate-0" />
              <div className="flex flex-col items-center">
                <div className="flex flex-col items-center">
                  <img src={illus_3} alt='how' className="w-20 mb-4"/>
                  <div className="flex items-center gap-5">
                    <p className="text-lg w-[250px] text-center">
                    {capitalize(this.t('completá los datos del agasajado.'))}
                    </p>
                  </div>
                </div>
              </div>
              <img src={arrow} alt='arrow' className="rotate-90 lg:rotate-0" />
              <div className="flex flex-col items-center">
                <div className="flex flex-col items-center">
                  <img src={illus_4} alt='how' className="w-20 mb-4"/>
                  <div className="flex items-center gap-5">
                    <p className="text-lg w-[250px] text-center">
                    {capitalize(this.t('pagás y listo. El agasajado recibe tu regalo en Bitcoins'))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            }
            
        </section>
        <section className="p-5 flex items-center min-h-[150px] md:p-20 bg-secondary justify-center">
          <h2 className="text-xl md:text-5xl text-white text-center font-bold ">Toda ocasión es buena para regalar Bitcoin</h2>
          {/* TESTIMONIOS - No borrar */}
          {/* <div className="container mx-auto">
          <img src={illus_5} alt="stars" className="-mt-32 ml-auto" /> 
          <div className="text-2xl text-center mb-8 text-white">
            {capitalize(this.t('Testimonios'))}
          </div>
          
          <div className="hidden md:flex justify-center items-center gap-12">
            {testimonios.map((e,i)=>(
              <div key={i} className="bg-white text-center rounded-lg p-4 flex flex-col shadow-2xl items-center justify-between w-[350px]">
                <div className="w-20 h-20 rounded-full bg-gray-500 my-4" />
                <div className="text-xl my-6 font-bold">{capitalizePhrase(this.t(e.title))}</div>
                <p className="text-base mb-8">
                  {capitalize(this.t(e.text))}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center md:hidden">
            <div className="flex items-center w-full justify-between">
              <img src={cheveronLeft} onClick={this.prevTest.bind(this)} className='w-8 invert'/>
              <div className="bg-white text-center rounded-lg p-4 flex flex-col shadow-2xl items-center justify-between">
                <div className="w-20 h-20 rounded-full bg-gray-500 my-4" />
                <div className="text-xl my-6 font-bold">{capitalizePhrase(this.t(testimonios[testimonio].title))}</div>
                <p className="text-base mb-8">
                  {capitalize(this.t(testimonios[testimonio].text))}
                </p>
              </div>
              <img src={cheveronRight} onClick={this.nextTest.bind(this)} className='w-8 invert'/>
            </div>
            <div className="flex justify-center items-center mt-6">
              {testimonios.map((e,i)=>(
                <span key={i} className={`${testimonio === i ? "w-5 h-5" : "w-3 h-3"} rounded-full bg-white mx-3`} />
              ))}
            </div>
          </div>
          </div> */}
        </section>
        <section id='help' className="py-20 container mx-auto">
          <h2 className="text-4xl font-bold text-center mb-8">
          {capitalizePhrase(this.t('preguntas frecuentes'))}
          </h2>
          <div className="flex flex-col md:flex-row items-start">
            <div className="mb-4 w-full px-5">
              <div className={"w-full md:px-0 mb-4" + (showFAQ ? " " : " h-72 overflow-y-scroll")}>
                {FAQ.map((e,i)=>(
                  <CollapseQuestion key={i} question={e.question} answer={e.answer} />
                ))}
              </div>
              <Button title={(this.t(showFAQ ? this.t('ver menos') : this.t('ver más')))} onClick={this.FAQvisibility.bind(this)} className='btn-secondary' />
            </div>
            <img src={illus_6} alt='robot' className="object-contain w-full" />
          </div>
        </section>
        <Footer />
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = state => {
  return {
    logged: state.users.logged,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Home));
