import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form'

import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { composeValidators, validateEmail, validateIsfilled } from '../../libraries/utils';
import { capitalize } from '../../libraries/utils';

import { history } from '../../routes';
import config from '../../config';

import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import LayoutHalfScreen from '../../components/layout/LayoutHalfScreen';
import logo from '../../assets/icons/custom/app-mobile/illus-5.png';
import side from '../../assets/img/img-why.png'



class Forgot extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      password: false,
    }
  }
  
  componentDidMount() {
  }
  
  onSubmit = async values => {
    const data = { ...values, redirect_uri: `${config.FRONTEND_URL}/password/`, };
    await this.props.onForgot(data);
    const error = this.props.auth.error;
    if (error) { 
      notify(this.t(error.message));
    } else {
      history.push(config.ROUTES.LOGIN);        
    }
  }


  render() {

    const required = value => (validateIsfilled(value) ? undefined : this.t("This field is required"))
    const email = value => (validateEmail(value) ? undefined : this.t("Not a valid email"))

    return (
      <LayoutHalfScreen
      header={{className: 'h-[25vh]',logo: logo,left: {icon: 'arrow_left',action: () => history.push(config.ROUTES.USER.INFO),},}}
      container={{className: 'mt-10',}}
      main={{className: 'bg-white'}}
      side={{className: 'bg-white flex items-center h-full justify-center px-10',imageClassName: 'max-h-[80%] ',image: side /*images[`slide${this.slide}`]*/,}}
      >
        <ToastContainer/>
        <h2 className="font-bold text-center mb-4 text-primary text-2xl">{this.t("Reset password")}</h2>
        <Form onSubmit={this.onSubmit}>
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto px-4">
              <div className="w-full mb-3 md:mb-0">
                <Field name="email" component={TextInput} placeholder={this.t("Email")} label={capitalize(this.t("ingrese su email"))} validate={composeValidators(required, email)}/>
              </div>
              <Button
                className="btn-primary btn-block text-white"
                title={this.t("Send")}
                onClick={handleSubmit}
              />
            </form>
          )}
        </Form>
      </LayoutHalfScreen>
    ) 
  }
}

const mapStateToProps = state => {
  return {
    auth: state.users.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onForgot: (values) => dispatch(userActions.forgot(values)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Forgot));

