import React from 'react';
import { history } from '../routes';
import config from '../config';
import transactionsActions from "../context/transactions/actions";
import userActions from "../context/users/actions";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { notify } from '../libraries/notifications';


class Redirect extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }

  componentDidMount() {
    const params = this.props.match.params;
    this.checkHash(params.hash)
  }

  checkHash = async (hash) => {
    if (hash){
      const params = {"json_data":[{"field":"hash","value":hash}], }

      if(this.props.auth?.user?.id){
        params.target = this.props.auth?.user?.id
      }

      //this.props.onGetTransactions({params})
      //target: "b1366eeb-b83a-457e-b7a4-a046a28ae57a",
      await this.props.onGetTransactions(params)
      const transactions = this.props.transactions.items;

      if(!transactions.length){
        //history push error
        history.push(config.ROUTES.HOME);
        return
      }

      let transaction = transactions[0]
      if (transaction.json_data?.redeem_address) {
        notify("Este regalo ha sido cancelado por el remitente")
        setTimeout(()=>history.push(config.ROUTES.HOME), 4000)
        return
      }

      await this.props.onGetUser({id: transaction.target})
      const user = this.props.user.items;

      if(!user){
        //history push error
        history.push(config.ROUTES.HOME);
        return
      }

      if(user.status == "new"){
        //history push register
        history.push({ pathname: config.ROUTES.SIGNUP_PRE.replace(":hash", user.hash),  state: { from: config.ROUTES.USER.MY_GIFT.replace(":id", transaction.id) } });
        return
      }

      if(user.status == "active"){
        //history push gift
        history.push({ pathname: config.ROUTES.LOGIN, state: { fromPathname: config.ROUTES.USER.MY_GIFT.replace(":id", transaction.id) } });
        return
      }

    }
  }

  render() {
    return (<></>)
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.list,
    auth: state.users.auth,
    transactions: state.transactions.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: (params) => dispatch(userActions.getAll(params)),
    onGetTransactions: (params) => dispatch(transactionsActions.getAll(params)),
    onGetToken: () => dispatch(userActions.getFromToken()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Redirect));
