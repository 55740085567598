import React from "react";
import { withTranslation } from "react-i18next";
import { history } from "../../routes";
import config from "../../config";

import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import Footer from "../../components/layout/Footer";

class InfoPrivacy extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {};
  }

  componentDidMount() {}

  onExit = () => {
    const back =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.from
        ? this.props.location.state.from
        : config.ROUTES.HOME;
    history.push(back);
  };

  render() {
    return (
      <LayoutWithSidebar
        header={{logo: true, back: true}}
      >
        <div className="px-10 pb-20 pt-20 md:pt-32">
          <h2 className="h2 text-center">Política de privacidad y tratamiento de datos personales</h2><br/>
          <p>Este documento describe la política de privacidad y tratamiento de datos personales de Regalale Bitcoins (“RG”). Cualquier persona que acceda, use y/o interactúe en el sitio <b>www.regalalebitcoins.com</b> (el "Sitio") estará alcanzada por esta política de privacidad y tratamiento de datos personales (la “política de Privacidad”). RG cumple con la ley de protección de datos personales Nº 25.326 y sus normas complementarias. Si usted no está de acuerdo con esta Política de Privacidad y/o no desea prestar su consentimiento para el tratamiento de sus datos personales, le recomendamos abstenerse de utilizar el Sitio y/o adquirir y/o utilizar las tarjetas (gift cards) comercializadas por RG, Regalalebitcoins.com es un sitio propiedad de Apptivalo SRL, CUIT 30-71671196-6, con domicilio en la calle Marcelo T. de Alvear 405 of. 6.</p>
          <p>  
          Mediante la utilización del Sitio y de las gift cards y/o la compra de estas últimas los usuarios prestan su consentimiento libre, expreso e informado para que RG efectúe el tratamiento de los datos personales. RG recolecta y trata los datos personales de los usuarios de conformidad con la ley Nº 25.326 de protección de datos personales y sus normas complementarias.</p>
          <p><br />
            <b>1.Titular</b><br />Cada usuario es titular de sus datos personales, que son recolectados a través de la utilización del Sitio, de las gift cards o mediante el registro en el Sitio a los efectos de adquirir una gift card.</p>
          <p><br />  
            <b>2. Responsable</b><br />Apptivalo  S.R.L., CUIT <b>30-71671196-6</b>, con domicilio en la Marcelo t de Alvear 405 of. 6, Ciudad Autónoma de Buenos Aires, es la responsable del banco en el que se almacenaran los datos personales de los usuarios.</p>
          <p><br />  
            <b>3. Recolección</b><br />Los datos personales que se podrán recabar de los usuarios son: nombre, apellido, fecha de nacimiento, correo electrónico y numero de su documento de identidad. La recolección se hará por medios electrónicos. En el caso de que las giftcards sean adquiridas en favor de un tercero, se solicitará el correo electrónico de este último y el usuario garantiza que dicha información fue obtenida de manera legitima y con el debido consentimiento del titular, en cumplimiento con la ley Nº 25.326 y sus normas complementarias.</p>
          <div><br />  
            <p><b>4. Motivo, finalidad y obligatoriedad</b><br />
            Los datos personales serán recabados para que los usuarios puedan utilizar el Sitio y/o las giftcards y para enviarles publicidad respecto de los productos y servicios de RG. Los datos personales serán utilizados únicamente con esa finalidad. Si bien no es obligatorio proporcional los datos personales, resulta imprescindible para poder utilizar el Sitio, la negativa a facilitarlos o su inexactitud impide su utilización.</p>
            <p>En el supuesto de que los datos personales sean utilizados para enviar publicidad, el titular podrá solicitar el retiro o bloqueo de su nombre de los bancos de datos en cualquier momento.</p></div>
          <p><br /> 
            <b>5. Almacenamiento</b><br />Los datos personales serán almacenados toda la Información Personal es recolectada y almacenada en servidores ubicados físicamente en los Estados Unidos, Comunidad Europea y/o Argentina.</p>
          <p><br />
            <b>6. Cesión y transferencia internacional</b><br />
            Los datos personales no serán cedidos a terceros, excepto que fuera necesario para su almacenamiento. A los fines del almacenamiento, los datos personales podrían ser transferidos fuera de la República Argentina, incluso a países que no posean los mismos niveles de protección que la ley Nº 25.326. En este supuesto RG exigirá al proveedor que cumpla con las medidas de seguridad exigidas por la ley de la ley Nº 25.326 y aquellas disposiciones que resulten aplicables.</p>
          <p><br /> 
            <b>7. Derechos del titular</b><br />El titular de los datos personales podrá ejercer los derechos de acceso, rectificación y supresión de sus datos en cualquier momento, efectuando una solicitud al responsable del banco de datos. La Agencia de Acceso a la Información Pública es el órgano de control de la Ley N° 25.326 y tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.
Para ejercer sus derechos o realizar consultas acerca de la Política de Privacidad, los usuarios pueden contactarse a través de <b>inforegalalebitcoins@gmail.com</b>
          </p> 
        </div>
        <Footer />
      </LayoutWithSidebar>
    );
  }
}

export default withTranslation()(InfoPrivacy);
