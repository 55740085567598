import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { history } from '../../routes';
import config from '../../config';
import SwitchboxInput from '../../components/forms/SwitchboxInput';
import UserProfileImage from '../../components/commons/UserProfileImage';
import MenuItemAdvance from '../../components/commons/MenuItemAdvance';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      settings: {
        notification: 0,
      },
    };
    this.breadcrumbs = [this.t('Perfil')];
    this.mainMenu = [
      {
        icon: { left: 'user_info', right: 'arrow_right' },
        label: { text: this.t('Personal information') },
        action: { route: config.ROUTES.USER.INFO },
      },
      {
        icon: { left: 'notification' },
        label: { text: this.t('Notifications') },
        action: {
          field: {
            // component: <Field name="notification" component={SwitchboxInput} placeholder="notification" className="h-12 pt-3" />,
            component: SwitchboxInput,
            name: 'notification',
            placeholder: this.t('Notifications'),
            className: 'h-12 pt-3',
          },
        },
      },
      {
        icon: { left: 'user_info', right: 'arrow_right' },
        label: { text: this.t('Change password') },
        action: { route: config.ROUTES.USER.PASSWORD_CHANGE },
      },
    ];
  }

  componentDidMount() {}

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.settings === this.state.settings) {
      return false;
    }
    return true;
  }

  getUser = async () => {
    await this.props.onGetUser({ id: this.props.auth.user.id });
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
      await this.props.onLogout();
      history.push(config.ROUTES.LOGIN);
    } else {
      this.setState({ settings: { ...this.props.user.item.accessibility } });
    }
  };

  onSubmit = async (field, value) => {
    let data = {
      id: this.props.auth.user.id,
      user_type: config.USER_TYPE.REGULAR,
      accessibility: {},
    };
    data.accessibility[field] = value;
    await this.props.onUpdate(data);
    const error = this.props.user.error;
    if (error) notify(this.t(error.message));
  };

  render() {
    const user = this.props.auth.user;
    const { settings } = this.state;
    return (
      <LayoutWithSidebar
        main={{ className: 'text-content-400' }}
        header={{title: 'Logo'}}
        container={{ className: 'px-8' }}
      >
        <ToastContainer />
        <section className="px-3 pb-10">
          {/* <UserProfileImage
            data={user}
            picture={{
              onClick: () => history.push(config.ROUTES.USER.USERINFO),
            }}
          /> */}
        </section>
        <section className="px-4 -mt-5">
          <Form
            initialValues={settings || {}}
            onSubmit={this.onSubmit}
            mutators={{ setFieldData }}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full max-w-lg">
                <div className="card white">
                  {this.mainMenu.map((item, index) => (
                    <MenuItemAdvance
                      key={index}
                      item={item}
                      onSubmit={this.onSubmit}
                    />
                  ))}
                </div>
              </form>
            )}
          </Form>
        </section>
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    auth: state.users.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onLogout: () => dispatch(userActions.logout()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Profile));
