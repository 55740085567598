import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import userActions from "../../context/users/actions";
import giftlistsActions from "../../context/giftlists/actions";
import { notify, ToastContainer } from "../../libraries/notifications";
import { capitalize, getObjectWithJsonDataToFromValues } from "../../libraries/utils";
import { history } from "../../routes";
import config from "../../config";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import avatar from '../../assets/icons/custom/Desktop/app-desktop/icon-1.png';
import gift from '../../assets/icons/custom/Desktop/app-desktop/icon-gift.png';
import list from '../../assets/icons/custom/Desktop/app-desktop/icon-list.png';
import MenuItem from '../../components/commons/MenuItem';
import Footer from "../../components/layout/Footer";
import Loader from "../../components/commons/Loader";

import aniversario from '../../assets/img/cards/aniversario.png';
import bautismo from '../../assets/img/cards/bautismo.png';
import casamiento from '../../assets/img/cards/casamiento.png';
import comunion from '../../assets/img/cards/comunion.png';
import confirmacion from '../../assets/img/cards/confirmacion.png';
import cumpleaños from '../../assets/img/cards/cumpleaños.png';
import snValentin from '../../assets/img/cards/dia de.png';
import graduacion from '../../assets/img/cards/graduacion.png';
import mudanza from '../../assets/img/cards/mudanza.png';
import nacimiento from '../../assets/img/cards/nacimiento.png';
import fiesta from '../../assets/img/cards/fiesta.png';
import navidad from '../../assets/img/cards/navidad.png';
import reyes from '../../assets/img/cards/reyes.png';
import fathers from '../../assets/img/cards/padre.png';
import mothers from '../../assets/img/cards/madre.png';
import btc from '../../assets/img/cards/btc.png';


class MyLists extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      selectedFile: null,
      profile_image: null,
      user: null,
      lists: [],
      cards: {
        christmas: navidad,
        threekings: reyes,
        party: fiesta,
        birthday: cumpleaños,
        wedding: casamiento,
        anniversary: aniversario,
        baptism: bautismo,
        confirmation: confirmacion,
        valentines: snValentin,
        graduation: graduacion,
        move: mudanza,
        birth: nacimiento,
        communion: comunion,
        fathers_day: fathers,
        mothers_day: mothers,
        bitcoin: btc,
      },
      loading: true,
    };
  }

  async componentDidMount() {
    await this.getUser();
    await this.getLists();
  }

  getUser = async () => {
    await this.props.onGetUser({ id: this.props.auth.user.id });
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      const user = getObjectWithJsonDataToFromValues(this.props.user.item, [
        "id",
        "first_name",
        "last_name",
        "phone",
        "email",
        "profile_image",
        "identification",
        "identification_type",
        "gender",
        "birth_date",
      ]);

      this.setState({ user });
    }
  };

  getLists = async (params = {}) => {
    params.created_by = this.state.user.id;

    await this.props.onGetLists(params);

    if (this.props.list.error) {
      notify(this.props.list.error.message)
    }

    this.setState({ lists: this.props.list.items, loading: false });
  };

  gotoBack = async () => {
    await this.props.onGetToken();
    if (this.props.auth.error) notify(this.t(this.props.auth.error.message));
    history.push(config.ROUTES.USER.HOME);
  };

  render() {
    const { user, lists, loading } = this.state;
    return (
      <LayoutWithSidebar
      header={{logo: true}}
      main={{ className: 'text-content-400' }}
      container={{ className: '' }}
      >
        {/* <ToastContainer /> */}
        <div className="flex flex-row justify-center items-start gap-10 md:py-20 md:mt-10 min-h-[calc(100%-297px)]">
        <div className="hidden w-[300px] md:flex rounded-xl card overflow-visible white mt-2">
          <ToastContainer />
          <div className="bg-gradient-to-r from-primary to-primary-focus w-full flex-col items-center gap-2 justify-center p-4 pb-2 rounded-t-xl h-[130px]">
            <div className="rounded-full -mt-10 border-gray-100 border-4 overflow-hidden flex mx-auto mb-4 text-center w-20 h-20">
              {user && user.profile_image ? (
                <img
                  className="w-full h-auto"
                  src={user && user.profile_image}
                  alt=""
                />
              ) : (
                <span className=" text-white text-2xl">
                  {user && user.username}
                </span>
              )}
            </div>
            <p className='text-center text-white font-bold text-lg'>{user && capitalize(user.first_name)}</p>
            <p className='text-center text-white'>{user && user.email}</p>
          </div>
          <div className="rounded-b-xl bg-white w-full">
            <ul className="menu py-4 text-base-300 cursor-pointer">
              {[
                {
                  label: capitalize(this.t("Profile")),
                  route: config.ROUTES.USER.INFO,
                  img: avatar,
                },
                {
                  label: capitalize(this.t("regalos")),
                  route: config.ROUTES.USER.MY_GIFTS,
                  img: gift,
                },
                {
                  label: capitalize(this.t("mis listas")),
                  route: config.ROUTES.USER.MY_LISTS,
                  img: list,
                },
              ].map((item, index) => (
                <MenuItem
                  location={this.props.location}
                  key={index}
                  item={item}
                />
              ))}
            </ul>
          </div>
        </div>
        <section className="flex h-full flex-col md:max-w-[450px] rounded-none md:rounded-xl w-full card white">
          <div className="">
            <div className="bg-gradient-to-r text-white from-primary to-primary-focus px-4 pb-3 pt-10 flex justify-between md:hidden">
              <div>
                <p className="text-2xl">Hola,</p>
                <p className="text-3xl">
                  {user && user.first_name ? capitalize(user.first_name) : ""}
                </p>
                <div className="text-lg mt-4">{capitalize(this.t("mis listas"))}</div>
              </div>
            </div>
            <div className="hidden p-8 pb-2 md:flex flex-col items-start">
              <h2 className="h2 text-primary"><img src={list} alt='' className='w-4 inline mr-2'/>{capitalize(this.t("listas"))}</h2>
            </div>
          </div>
          <div className="md:max-h-[500px] px-4 overflow-y-auto pb-4">
            {lists.length !== 0 ?
              lists.map((list, i) => {
                
                return (
                  <div
                    key={i}
                    onClick={() =>
                      history.push({
                        pathname: config.ROUTES.USER.MY_LIST.replace(':id', list.id)
                      })
                    }
                    className="p-2 border-b border-base-200 flex justify-start cursor-pointer hover:bg-base-200 rounded-lg"
                  >
                    <img src={this.state.cards[list.event_type]} alt='card' className="w-24 h-24 object-contain mr-3" />
                    <div className=" flex flex-col">
                      <p className="text-xl text-primary leading-5">Lista de {capitalize(this.t(list.event_type))}</p>
                        <p className="font-light"> 
                          <span className="font-bold">Address de Bitcoin: </span>
                          {list.wallet}
                        </p>
                        <p className="">
                          <span className="font-bold">Ocasión: </span> 
                          {capitalize(this.t(list.event_type))}
                        </p>
                    </div>
                  </div>
                );
              }) : loading ? <Loader /> : <p className="p-8">{this.t("No has realizado listas")}</p>
              }
          </div>
        </section>
        </div>
        <Footer className="hidden md:block" />
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    auth: state.users.auth,
    list: state.giftlists.list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: (params) => dispatch(userActions.get(params)),
    onGetToken: () => dispatch(userActions.getFromToken()),
    onGetLists: (params) => dispatch(giftlistsActions.getAll(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MyLists));
