import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../routes';
import config from '../../config';
import LayoutSmall from '../../components/layout/LayoutSmall';

class InfoContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
    }
  }
  
  componentDidMount() {
    ;
  }

  onExit = async values => {
    const back = (this.props.location && this.props.location.state &&  this.props.location.state.from) ? this.props.location.state.from : config.ROUTES.HOME;
    history.push(back);
  }
 
  render() {
    return (
      <LayoutSmall
        header={{ 
          className: "bg-primary",
          title: this.t("Contact us"),
          left: { icon: 'arrow_left', action: this.onExit }
        }}      
        main={{ 
          className: "light-primary-bg text-gray-700",
        }}
      >
        <h3 className="mb-1 mt-5"> <strong> Encuentranos en</strong></h3>
      </LayoutSmall>
    ) 
  }
}

export default withTranslation()(InfoContactUs);
