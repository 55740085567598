import React from 'react';

import { ReactComponent as adjustments } from '../assets/icons/outline/adjustments.svg';
import { ReactComponent as arrow_left } from '../assets/icons/arrow-left.svg';
import { ReactComponent as arrow_right } from '../assets/icons/arrow-right.svg';
import { ReactComponent as book_open } from '../assets/icons/outline/book-open.svg';
import { ReactComponent as calculator } from '../assets/icons/calculator.svg';
import { ReactComponent as checkmark } from '../assets/icons/checkmark.svg';
import { ReactComponent as check_circle_solid } from '../assets/icons/solid/check-circle.svg';
import { ReactComponent as x_circle } from '../assets/icons/solid/x-circle.svg';
import { ReactComponent as cheveron_down } from '../assets/icons/cheveron-down.svg';
import { ReactComponent as cheveron_left } from '../assets/icons/cheveron-left.svg';
import { ReactComponent as cheveron_right } from '../assets/icons/cheveron-right.svg';
import { ReactComponent as clean_inside } from '../assets/icons/car-clean-inside.svg';
import { ReactComponent as close } from '../assets/icons/close.svg';
import { ReactComponent as calendar } from '../assets/icons/calendar.svg';
import { ReactComponent as cancel } from '../assets/icons/cancel.svg';
import { ReactComponent as chat_alt } from '../assets/icons/outline/chat-alt.svg';
import { ReactComponent as cog } from '../assets/icons/outline/cog.svg';
import { ReactComponent as collection } from '../assets/icons/outline/collection.svg';
import { ReactComponent as color_swatch } from '../assets/icons/outline/color-swatch.svg';
import { ReactComponent as dashboard } from '../assets/icons/dashboard.svg';
import { ReactComponent as download } from '../assets/icons/download.svg';
import { ReactComponent as edit_pencil } from '../assets/icons/edit-pencil.svg';
import { ReactComponent as gout } from '../assets/icons/gout.svg';
import { ReactComponent as help } from '../assets/icons/help.svg';
import { ReactComponent as home } from '../assets/icons/outline/home.svg';
import { ReactComponent as impact1 } from '../assets/icons/custom/1impact.svg';
import { ReactComponent as impact3 } from '../assets/icons/custom/3impact.svg';
import { ReactComponent as impact5 } from '../assets/icons/custom/5impact.svg';
import { ReactComponent as location } from '../assets/icons/location.svg';
import { ReactComponent as location_marker } from '../assets/icons/outline/location-marker.svg';
import { ReactComponent as logout } from '../assets/icons/outline/logout.svg';
import { ReactComponent as menu } from '../assets/icons/outline/menu.svg';
import { ReactComponent as minus_no_outline } from '../assets/icons/solid/minus.svg';
import { ReactComponent as notification } from '../assets/icons/notification.svg';
import { ReactComponent as office_building } from '../assets/icons/outline/office-building.svg';
import { ReactComponent as pharmacy } from '../assets/icons/custom/pharmacy.svg';
import { ReactComponent as photograph } from '../assets/icons/outline/photograph.svg';
import { ReactComponent as phone } from '../assets/icons/phone.svg';
import { ReactComponent as pin } from '../assets/icons/pin.svg';
import { ReactComponent as plus } from '../assets/icons/plus.svg';
import { ReactComponent as plus_circle } from '../assets/icons/outline/plus-circle.svg';
import { ReactComponent as presentation_chart_line } from '../assets/icons/outline/presentation-chart-line.svg';
import { ReactComponent as receipt_refund } from '../assets/icons/outline/receipt-refund.svg';
import { ReactComponent as search } from '../assets/icons/search.svg';
import { ReactComponent as sharypoints_main } from '../assets/icons/custom/sharypoints_main.svg';
import { ReactComponent as stethoscope } from '../assets/icons/custom/stethoscope.svg';
import { ReactComponent as switch_horizontal } from '../assets/icons/outline/switch-horizontal.svg';
import { ReactComponent as table } from '../assets/icons/outline/table.svg';
import { ReactComponent as tag } from '../assets/icons/outline/tag.svg';
import { ReactComponent as terms } from '../assets/icons/terms.svg';
import { ReactComponent as time } from '../assets/icons/time-circle.svg';
import { ReactComponent as trash } from '../assets/icons/trash.svg';
import { ReactComponent as truck } from '../assets/icons/outline/truck.svg';
import { ReactComponent as user_circle } from '../assets/icons/outline/user-circle.svg';
import { ReactComponent as user_group } from '../assets/icons/outline/user-group.svg';
import { ReactComponent as user_info } from '../assets/icons/user-info.svg';
import { ReactComponent as view_hide } from '../assets/icons/view-hide.svg';
import { ReactComponent as view_show } from '../assets/icons/view-show.svg';
import { ReactComponent as whatsapp } from '../assets/icons/whatsapp.svg';
import { ReactComponent as play } from '../assets/icons/play.svg';
import { ReactComponent as pause } from '../assets/icons/pause.svg';
import { ReactComponent as clip_board } from '../assets/icons/outline/clipboard.svg';
import { ReactComponent as paper_clip } from '../assets/icons/outline/paper-clip.svg';
import { ReactComponent as cheveron_up } from '../assets/icons/cheveron-up.svg';
import { ReactComponent as menu_alt_1 } from '../assets/icons/solid/menu-alt-1.svg';
import { ReactComponent as menu_alt_2 } from '../assets/icons/solid/menu-alt-2.svg';
import { ReactComponent as menu_alt_3 } from '../assets/icons/solid/menu-alt-3.svg';
import { ReactComponent as menu_alt_4 } from '../assets/icons/solid/menu-alt-4.svg';
import { ReactComponent as question } from '../assets/icons/question.svg';
import { ReactComponent as information } from '../assets/icons/information-solid.svg';
import { ReactComponent as video_camera } from '../assets/icons/outline/video-camera.svg';



// stroke="none" fill="currentColor"

const iconMap = {
  adjustments,
  arrow_left,
  arrow_right,
  book_open,
  calculator,
  calendar,
  cancel,
  chat_alt,
  checkmark,
  check_circle_solid,
  x_circle,
  cheveron_down,
  cheveron_left,
  cheveron_right,
  cheveron_up,
  clean_inside,
  close,
  cog,
  collection,
  color_swatch,
  dashboard,
  download,
  edit_pencil,
  gout,
  help,
  home,
  impact1,
  impact3,
  impact5,
  location,
  location_marker,
  logout,
  menu,
  minus_no_outline,
  notification,
  office_building,
  pharmacy,
  photograph,
  phone,
  pin,
  plus,
  plus_circle,
  presentation_chart_line,
  receipt_refund,
  sharypoints_main,
  search,
  stethoscope,
  switch_horizontal,
  table,
  tag,
  terms,
  time,
  trash,
  truck,
  user_circle,
  user_group,
  user_info,
  view_hide,
  view_show,
  whatsapp,
  play,
  pause,
  clip_board,
  paper_clip,
  menu_alt_1,
  menu_alt_2,
  menu_alt_3,
  menu_alt_4,
  question,
  information,
  video_camera
};

const Icon = ({ name, size, color, ...rest }) => {
  const Icon = iconMap[name];
  if (typeof Icon === 'undefined') return null;
  return <Icon color={color} style={{ width: size, height: size }} {...rest} />;
};

export default Icon;
