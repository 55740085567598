const types = {
  CLEAR_CURRENT: 'PERMISSION_ROLES_CLEAR_CURRENT',
  GET_REQUEST: 'PERMISSION_ROLES_GET_REQUEST',
  GET_SUCCESS: 'PERMISSION_ROLES_GET_SUCCESS',
  GET_FAILURE: 'PERMISSION_ROLES_GET_FAILURE',

  SAVE_REQUEST: 'PERMISSION_ROLES_SAVER_REQUEST',
  SAVE_SUCCESS: 'PERMISSION_ROLES_SAVE_SUCCESS',
  SAVE_FAILURE: 'PERMISSION_ROLES_SAVE_FAILURE',

  GETALL_REQUEST: 'PERMISSION_ROLES_GETALL_REQUEST',
  GETALL_SUCCESS: 'PERMISSION_ROLES_GETALL_SUCCESS',
  GETALL_FAILURE: 'PERMISSION_ROLES_GETALL_FAILURE',

  DELETE_REQUEST: 'PERMISSION_ROLES_DELETE_REQUEST',
  DELETE_SUCCESS: 'PERMISSION_ROLES_DELETE_SUCCESS',
  DELETE_FAILURE: 'PERMISSION_ROLES_DELETE_FAILURE',    

  UPDATE_REQUEST: 'PERMISSION_ROLES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PERMISSION_ROLES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PERMISSION_ROLES_UPDATE_FAILURE'    

};

export default types;