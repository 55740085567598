import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import userActions from '../../context/users/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { validateIsfilled } from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';
import LayoutHalfScreen from '../../components/layout/LayoutHalfScreen';
import Button from '../../components/commons/Button';
import TextWithIconInput from '../../components/forms/TextWithIconInput';
import logo from '../../assets/icons/custom/app-mobile/illus-2.png';
import side from '../../assets/img/img-why.png'

class PasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      showPassword: false,
      showNewPassword: false,
      showNewPassword2: false,
      user: null,
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = async () => {
    await this.props.onGetUser({ id: this.props.auth.user.id });
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({ user: this.props.user.item });
    }
  };

  onSubmit = async (values) => {
    const user = this.state.user;
    this.setState({ submitting: true });
    await this.props.onUpdate({
      password: values.password,
      newpassword: values.newpassword,
      id: user.id,
      user_type: config.USER_TYPE.REGULAR,
    });
    if (this.props.user.error) {
      this.setState({ submitting: false });
      notify(this.t(this.props.user.error.message));
    } else {
      history.push(config.ROUTES.USER.INFO);
    }
  };

  onPasswordClick = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onNewPasswordClick = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  };

  onNewPasswordClick2 = () => {
    this.setState({ showNewPassword2: !this.state.showNewPassword2 });
  };

  render() {
    const required = (value) =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const validate = (values) => {
      let errors = [];
      if (values.newpassword !== values.newpassword2)
        errors.newpassword2 = this.t('Passwords must match');
      return errors;
    };
    return (
      <LayoutHalfScreen
        header={{className: 'h-[25vh]',logo: logo,left: {icon: 'arrow_left',action: () => history.push(config.ROUTES.USER.INFO),},}}
        container={{className: 'mt-10',}}
        main={{className: 'bg-white'}}
        side={{className: 'bg-white flex items-center h-full justify-center px-10',imageClassName: 'max-h-[80%] ',image: side /*images[`slide${this.slide}`]*/,}}
        loading={this.props.user.loading}
      >
        <ToastContainer />
        <section className="px-4 mt-5">
          <p className='font-bold text-center mb-4 text-primary text-2xl capitalize'>{this.t("cambiar contraseña")}</p>
          <Form onSubmit={this.onSubmit} validate={validate}>
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto">
                <div className="py-3">
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="password"
                      component={TextWithIconInput}
                      placeholder={this.t('Password')}
                      label={this.t('Current password')}
                      className="text-gray-700"
                      showPassword={this.state.showPassword}
                      clickPassword={this.onPasswordClick}
                      validate={required}
                    />
                  </div>
                  <div className="w-full px-3 mb-3 md:mb-0">
                    <Field
                      name="newpassword"
                      component={TextWithIconInput}
                      placeholder={this.t('New password')}
                      label={this.t('New password')}
                      className="text-gray-700"
                      showPassword={this.state.showNewPassword}
                      clickPassword={this.onNewPasswordClick}
                      validate={required}
                    />
                  </div>
                  <div className="w-full px-3 mb-10 md:mb-0">
                    <Field
                      name="newpassword2"
                      component={TextWithIconInput}
                      placeholder={this.t('Repite new password')}
                      className="text-gray-700"
                      showPassword={this.state.showNewPassword2}
                      clickPassword={this.onNewPasswordClick2}
                      validate={required}
                    />
                  </div>
                  <div className="w-full px-3">
                    <Button
                      className="btn-primary btn-block text-white"
                      title={this.t('Confirm')}
                      onClick={handleSubmit}
                      disabled={this.state.submitting}
                    />
                  </div>
                </div>
              </form>
            )}
          </Form>
        </section>
      </LayoutHalfScreen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    user: state.users.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (values) => dispatch(userActions.saveOrUpdate(values, 'update')),
    onGetUser: (params) => dispatch(userActions.get(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PasswordChange));
