import types from './types';

const setStrings = (strings) => { return { type: types.SET_STRINGS, strings } };
const setLanguage = (langCode) => { return { type: types.SET_LANGUAGE, langCode } };
const loading = (loading) => { return { type: types.LOADING_LANGUAGE, loading } };


const fetchTranslations = (language = 'en') =>
  import(`../../localization/${language}`).then((module) => {
    return module.default;
  }
);

const init = (langCode) => {
  console.log('ACTIONS::LOCALIZATION::init');
  return async dispatch => {
    dispatch(loading(true));
    const strings = await fetchTranslations(langCode)
    dispatch(setLanguage(langCode));
    dispatch(setStrings(strings));
  };  
  
}


const actions = {
  init,
  setLanguage,
  setStrings,
};

export default actions;