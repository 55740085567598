import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import { withTranslation } from "react-i18next";
import giftListsActions from '../../context/giftlists/actions'
import { notify } from "../../libraries/notifications";
import { capitalize, capitalizePhrase } from "../../libraries/utils";
import Button from "../../components/commons/Button";
import aniversario from '../../assets/img/cards/aniversario.png';
import bautismo from '../../assets/img/cards/bautismo.png';
import casamiento from '../../assets/img/cards/casamiento.png';
import comunion from '../../assets/img/cards/comunion.png';
import confirmacion from '../../assets/img/cards/confirmacion.png';
import cumpleaños from '../../assets/img/cards/cumpleaños.png';
import snValentin from '../../assets/img/cards/dia de.png';
import graduacion from '../../assets/img/cards/graduacion.png';
import mudanza from '../../assets/img/cards/mudanza.png';
import nacimiento from '../../assets/img/cards/nacimiento.png';
import fiesta from '../../assets/img/cards/fiesta.png';
import navidad from '../../assets/img/cards/navidad.png';
import reyes from '../../assets/img/cards/reyes.png';
import fathers from '../../assets/img/cards/padre.png';
import mothers from '../../assets/img/cards/madre.png';
import btc from '../../assets/img/cards/btc.png';
import Loader from "../../components/commons/Loader";

class List extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      item: null,
      cards: {
        birthday: cumpleaños,
        wedding: casamiento,
        anniversary: aniversario,
        baptism: bautismo,
        confirmation: confirmacion,
        valentines: snValentin,
        graduation: graduacion,
        move: mudanza,
        birth: nacimiento,
        communion: comunion,
        threekings: reyes,
        party: fiesta,
        christmas: navidad,
        fathers_day: fathers,
        mothers_day: mothers,
        bitcoin: btc,
      },
      loading: true
    };
  }

  componentDidMount() {
    this.getList()
  }

  async getList() {
    const params = this.props.match.params;
    const id = params.id

    await this.props.getList(id);
    const { list } = this.props;

    if (list.error) {
      notify(this.t(list.error.message))
    } else {
      this.setState({ item: list.item })
    }
    this.setState({ loading: false })
  }

  continue() {
    const state = {
      from: 'list',
      ...this.props.list.item,
    }
    
    history.push({pathname: config.ROUTES.GIFT_CURRENCY, state: state})
  }

  render() {
    const { item, loading } = this.state;
    const { cards } = this.state;
    return (
      <Layout
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: '' }}
      >
        <section className="flex flex-row justify-around items-start gap-5 pt-16 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
        <div className="card white md:w-[500px] w-full">
            <h2 className="h2 text-primary my-4 mx-auto">
                {item ? capitalize(this.t(item.event_type)) : 'Regalo'} de {item ? capitalizePhrase(item.json_data.owner.first_name) : ''}
            </h2>
            <img src={cards[item?.event_type]} className='w-full md:w-80 md:h-80 mx-auto object-contain' alt='' />
            <div className="p-10 text-center">
              <div className="font-bold">
                <p className="text-2xl mb-4">{item ? capitalize(item.title) : ''}</p>
                <p className="text-base">{item ? capitalize(item.details) : ''}</p>
              </div>
              {item && ! loading?
              <Button
                className="bg-secondary border-2 mt-4 border-white border-opacity-50 rounded-btn text-white w-full text-center p-2"
                onClick={this.continue.bind(this)}
                title={capitalize(this.t('continuar'))}
              /> : <p>Ocurrió un error</p>
              }
              {loading && <Loader />}
              <Button title={capitalize(this.t('volver'))} className='btn-link' onClick={()=>history.push(config.ROUTES.HOME)} />
            </div>
        </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.giftlists.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (id) => dispatch(giftListsActions.get(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(List));