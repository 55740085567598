import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import Back from '../../assets/icons/cheveron-outline-left.svg';
import Dolar from '../../assets/icons/outline/currency-dollar.svg'
import Right from '../../assets/icons/arrow-right.svg'
import usersActions from "../../context/users/actions";
import { capitalize } from '../../libraries/utils';
import { withTranslation } from "react-i18next";
import ButtonIcon from "../../components/commons/ButtonIcon";

class ModalBuy extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      fromList: this.props.location && this.props.location.state && this.props.location.state.from === 'list' || false,
      payMethod: '',
    };
  }
  async componentDidMount() {
  }

  onClickMP = () =>{
    const {values} = this.props;
    values.payMethod = 'MP'
    history.push({pathname: config.ROUTES.GIFT_CONFIRM, state: values})
  };
  onClickBTC = () =>{
    const {values} = this.props;
    values.payMethod = 'BTC'
    history.push({pathname: config.ROUTES.GIFT_CONFIRM, state: values})
  };

  render() {
    const {toggleModal } = this.props;
    
    return (   
      <div className={"absolute top-0 left-0 z-50 grid md:place-content-center place-content-end h-screen w-full bg-black bg-opacity-40"}> 
        <div className="card white rounded-b-none md:rounded-b-2xl p-4 md:w-[500px] w-screen h-full md:h-full md:-ml-4">
            <ButtonIcon icon='cancel' buttonClassName='z-30 absolute m-2 top-0 right-0 btn btn-ghost w-fit ml-auto' className='w-6 h-6' 
              onClick={toggleModal}
            />
          <div>
            <h2 className='text-secondary text-center font-bold my-4'>Selecciona método de pago</h2>
            <p className='text-gray-500 text-center'>Se paga através de <span className='font-bold'>mercado pago </span>por la cotización del día. O directamente por <span className='font-bold'>BITCOIN.</span></p>          
          </div>
            <button 
              className='bg-sky-100 flex mt-5 gap-4 rounded-lg shadow-sm py-3 w-full'
              onClick={this.onClickMP}
            >
              <img src={Dolar} className='w-20 h-20 justify-center items-center' />
                <div className='w-full text text-left'>
                  <p className='text-sky-400 text-xl '>Mercado Pago</p>
                  <p className=''>Cambio a blue del día a</p>
                  <p className=''>traves de mercado pago</p>
                </div>
                <img src={Right} className='w-4 h-5 mt-5 justify-center items-center mr-3' />
            </button>
            <button 
              className='bg-yellow-100 flex mt-5 gap-4 rounded-lg shadow-sm py-3'
              onClick={this.onClickBTC}
            >
              <img src={Dolar} className='w-20 h-20 justify-center items-center' />
              <div className='w-full text-left'>
                <p className='text-yellow-400 text-xl font-bold'>Bitcoin</p>
                <p className=''>Red: bitcoin</p>
              </div>
              <img src={Right} className='w-4 h-5 mt-5 justify-center items-center mr-3' />
          </button>
        </div>
      </div>  
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    users: state.users.list,
    auth: state.users.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(usersActions.getAll(params))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ModalBuy));