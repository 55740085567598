import React from 'react';

export default function CollapseQuestion ( { question, answer }) {
  return(
    <>
    <div className=" collapse collapse-plus border border-base-100 bg-base-100 rounded-lg shadow-2xl mb-2">
      <input type='checkbox' />
      <h4 className="collapse-title text-xl text-primary">
        {question}
      </h4>
      <div className="collapse-content">
        <p dangerouslySetInnerHTML={{ __html: answer }}></p>
      </div>
    </div>
    </>
  )
}