import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import userActions from '../../context/users/actions';
import notificationsActions from '../../context/notifications/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import {
  composeValidators,
  validateEmail,
  validateIsfilled,
} from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';
import LayoutHalfScreen from '../../components/layout/LayoutHalfScreen';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import TextWithIconInput from '../../components/forms/TextWithIconInput';
import Splash from '../../components/commons/Splash';

import google from '../../assets/icons/custom/app-mobile/icon-google.png';
import facebook from '../../assets/icons/custom/app-mobile/icon-facebook.png';
import logo from '../../assets/icons/custom/app-mobile/illus-1.png';
import { Link } from 'react-router-dom';
import side from '../../assets/img/img-why.png'

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.from = (this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromPathname) || '/' ;
    this.state = {
      showPassword: false,
      submitting: false,
      credentials: {},
    };
    this.slide = Math.floor(Math.random() * 3);
  }

  componentDidMount() {
    this.userToken();
  }
  
  onForgotPassword = async (values) => {
    history.push(config.ROUTES.FORGOT);
  };

  onSubmit = async (values) => {
    this.setState({ submitting: true, credentials: { ...values } });
    await this.props.onLogin({
      ...values,
      user_type: config.USER_TYPE.REGULAR,
    });
    if (this.props.auth.error) {
      this.setState({ submitting: false });
      notify(this.t(this.props.auth.error.message));
    } else {
      await this.props.onGetUnclaimed({target:this.props.auth.user.id});
      history.push({pathname: this.from, state: this.props.location.state}); //config.ROUTES.HOME
    }
  };

  userToken = async () => {
    const token = JSON.parse(
      localStorage.getItem(config.LOCALSTORAGE_USER_TOKEN)
    );
    if (!this.props.auth.logged && token) {
      await this.props.onGetToken();
      if (this.props.auth.error) {
        await this.props.onLogout();
        history.push(config.ROUTES.HOME);
      } else {
        history.push({pathname: this.from, state: this.props.location.state}); //config.ROUTES.HOME
      }
    }
  };

  onPasswordClick = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onSignup = () => {
    history.push(config.ROUTES.SIGNUP);
  };

  render() {
    if (
      !this.props.auth.logged &&
      this.props.auth.loading &&
      !this.state.submitting
    )
      return <Splash />;

    const required = (value) =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    const email = (value) =>
      validateEmail(value) ? undefined : this.t('Not a valid email');

    return (
      <LayoutHalfScreen
        main={{className: 'bg-gradient-to-r from-primary to-primary-focus md:from-white md:to-white'}}
        container={{className: 'rounded-t-[50px] px-8 pt-8 h-full'}}
        side={{
          className: 'bg-primary flex items-center h-full justify-center px-10',
          imageClassName: 'max-h-[50%] ',
          image: side /*images[`slide${this.slide}`]*/,
        }}
        header={{ className: '', logo: logo,left: { icon: 'arrow_left', action: () => history.push(config.ROUTES.HOME)}, }}
      >
        <ToastContainer />
        {/* <Button onClick={()=>history.push(config.ROUTES.HOME)} title='Volver atrás' className='bg-primary border-none capitalize text-white' /> */}
        <h2 className="h2 text-primary font-bold text-center my-10">
          {this.t('Log in')}
        </h2>
        <Form
          initialValues={this.state.credentials || {}}
          onSubmit={this.onSubmit}
        >
          {({ handleSubmit, form, submitting, pristine, values }) => (
            <form
              onSubmit={handleSubmit}
              className="w-full max-w-lg mx-auto px-4"
            >
              <div className="w-full mb-3 md:mb-0">
                <Field
                  name="email"
                  component={TextInput}
                  placeholder={this.t('Email')}
                  validate={composeValidators(required, email)}
                />
              </div>
              <div className="w-full mb-3 md:mb-0">
                <Field
                  name="password"
                  component={TextWithIconInput}
                  placeholder={this.t('Password')}
                  showPassword={this.state.showPassword}
                  clickPassword={this.onPasswordClick}
                  validate={required}
                />
              </div>
              <div className="flex flex-wrap">
                <Button
                  className="capitalize font-normal text-lg bg-secondary border-btn rounded-btn border-white border-opacity-[0.25] text-white w-full mb-3"
                  title={this.t('Log in')}
                  onClick={handleSubmit}
                  disabled={this.state.submitting}
                />
                <Button
                  className="w-fit ml-auto text-xs capitalize p-0 px-2 bg-transparent border-none"
                  title={this.t('Forgot your password?')}
                  onClick={this.onForgotPassword}
                />
              </div>
            </form>
          )}
        </Form>
        <div className='flex flex-col items-center justify-center mt-6'>
          {/* <p>Puedes ingresar con</p>
          <div>
            <Button img={google} className='bg-transparent p-0 border-none' />
            <Button img={facebook} className='bg-transparent p-0 border-none' />
          </div> */}
          <p>No tienes cuenta? <Link to={config.ROUTES.SIGNUP}><b className='text-primary'>Registrate</b></Link></p>
        </div>
        {/* <div className="mt-20 p-4 flex justify-center items-center">
          <p className="text-primary-100 text-center mr-3">{this.t("Does not have an account?")}</p>
          <Button
              className="btn btn-link mb-0 underline"
              title={this.t("Register")}
              onClick={this.onSignup}
            />
        </div> */}
      </LayoutHalfScreen>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (values) => dispatch(userActions.login(values)),
    onLogout: () => dispatch(userActions.logout()),
    onGetToken: () => dispatch(userActions.getFromToken()),
    onGetUnclaimed: (params) => dispatch(notificationsActions.getUnclaimedGifts(params)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Login));
