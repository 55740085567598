import {
  format,
  addMinutes,
  subMinutes,
  parse,
  formatDistance,
  startOfWeek,
  startOfMonth,
  addDays,
  addMonths
} from 'date-fns';
import publicIp from 'public-ip';
import config from '../config';

export const selectIconSize = (ctrClass, defaultClass = 'h-4 w-4') => {
  if (!ctrClass) return defaultClass;
  const iconclass = ctrClass.split(' ');
  if (iconclass.includes('btn-lg')) return 'h-5 w-5';
  if (iconclass.includes('btn-sm')) return 'h-3 w-3';
  if (iconclass.includes('btn-xs')) return 'h-2 w-2';
  return defaultClass;
};

export const id = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

//generates random id;
export const guid = () => {
  return (
    id() +
    id() +
    '-' +
    id() +
    '-' +
    id() +
    '-' +
    id() +
    '-' +
    id() +
    id() +
    id()
  );
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : r & (0x3 | 0x8);
    return v.toString(16);
  });
};

export const validateURL = (URL = null) => {
  if (URL === null) return false;
  // regex source: https://regexr.com/39nr7
  const URLRegex =
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;
  return URLRegex.test(URL);
};

export const validateEmail = (email = null) => {
  if (email === null) return false;
  // const re    = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const validatePassword = (pass = null) => {
  if (pass === null) return false;
  return pass.length > 3;
};

export const validateStringNotEmpty = (str = null) => {
  if (str === null) return false;
  return str.length > 0;
};

export const validateOnlyNumber = (num = null, allowNegative = false) => {
  // const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;  // For phonenumber //TODO: test it
  // allowNegative /^-?
  const regex = /^(?:\d+)(?:(\.|,)\d+)?$/;
  return regex.test(num);
  // if (num === null) return false;
  // return !isNaN(num);
};

export const formatOnlyNumberInput = (value, maxLength, extras = []) => {
  if (!value) return value;
  //const reg = RegExp(`(?![\\d+|\\${extras.join("")}]+)\\w+`);
  return value.replace(/\D+/g, '').slice(0, maxLength);
};

//For Decimal Numbers
// export const formatOnlyNumberDecimalInput = (value, maxLength) => {
//   if(!value) return value
//   return value.replace(/[^0-9,.]/g, '').slice(0, maxLength)
// }

//For Decimal Numbers and can't use ','(coma), automatically convert coma into dot
export const formatOnlyNumberDecimalInput = (value, maxLength) => {
  if(!value) return value
  value = value.replace(/,/g, '.');
  value = value.replace(/[^0-9,.]/g, match => {
    if(match === '.'){
      if(value.includes('.')) return ''
    }
    return match
  })
  value = value.slice(0, maxLength);
  return value
}

export const parseOnlyLetterAndSpace = str => str.replace(/[^A-Za-z ]/g, '');

export const parseLength = (str, length) => str.substring(0, length);

export const validateAtLeastLength = (str, length) =>
  str && str.trim().length >= length;

export const validateIsfilled = expression =>
  expression && (expression.length > 0 || expression > 0);

export const validateIsTrue = expression => expression;

export const composeValidators =
  (...validators) =>
  value =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

// export const optionSelectGenerator = (array = [], type = 'vector') => {
//   let options = [];
//   array.forEach(e => {
//     if (type === 'vector') {
//       options.push({label: e, value: capitalize(e)});
//     }
//   });
//   return options;
// }

export const optionSelectGenerator = (array = []) =>
  array?.map(o => ({ value: o, label: capitalize(o) })) || [];

// For arrays of objects coming from redux state
// def properties if necessary
// -JC
export const selectGeneratorWObjChild = (
  array = [],
  optProp1 = '',
  optProp2 = ''
) => {
  if(!Array.isArray(array)) return []
  return (
    array?.map(o => ({
      value: optProp1 ? o[optProp1] : o,
      label: isFunction(optProp2)
        ? optProp2(o)
        : optProp2
        ? capitalize(o[optProp2])
        : o
    })) || []
  );
};

export const getOwner = user => {
  //return id to save in owner field
  let owner;
  if (user) {
    if (config.USER_ACCOUNTS_INTEGRATED && user.accounts) {
      let account = user.accounts.filter(
        acc => acc.ownership === config.USER_ACCOUNTS.TYPES.OWNER
      );
      owner =
        config.USER_ACCOUNTS_INTEGRATED && account.length
          ? account[0].id
          : user.id;
    } else {
      owner = user.id;
    }
  }
  return owner;
};

/** OBJECT & DATA CHECK & MANIPULATION */

export const searchJSON = (json, root, search) => {
  // console.log('json', json, search);
  if (!isObject(search)) return false;
  if (isObject(json)) {
    let json_root = '';
    if (!root.startsWith('$')) return false;
    if (root.startsWith('$.')) {
      json_root = root.substring(2, root.length);
    }
    const json_root_array = json_root !== '' ? json_root.split('.') : [];
    const search_names = Object.keys(search);
    const search_values = Object.values(search);
    // console.log('JSON', json_root, json_root_array, search_names, search_values);
    if (json_root_array.length) {
      let level = 0;
      for (let i = 0; i < json_root_array.length; i++) {
        const el = json_root_array[i];
        if (json.hasOwnProperty(el)) {
          // console.log('JSON HAS', el, level);
          if (level === json_root_array.length - 1) {
            // console.log('LAST LEVEL');
            for (let j = 0; j < search_names.length; j++) {
              const name = search_names[j];
              const value = search_values[j];
              // console.log('SEARCH', name, value, json[el]);
              if (!Array.isArray(json[el])) {
                if (json[el].hasOwnProperty(name) && json[el][name] === value) {
                  // console.log('FOUND', level, name, value);
                  return true;
                }
              } else {
                const result = json[el].filter(el => {
                  console.log('EL', el);
                  return el.hasOwnProperty(name) && el[name] === value;
                });
                // console.log('RESULT', result, result.length);
                if (result.length) {
                  return result;
                } else {
                  return false;
                }
              }
            }
            level++;
          }
        }
      }
    }
    return true;
  }
  return false;
};

export const complexAPIObjectToFormValues = obj => {
  let response = { ...obj };
  Object.keys(obj).forEach(k => {
    // console.log('object key', k, obj[k], isObject(obj[k]) );
    if (isObject(obj[k])) {
      response[k] = obj[k].id;
    }
  });
  return response;
};

export const getObjectWithJsonDataToFromValues = (obj, fields = []) => {
  let response = { ...obj };
  if (!obj) return response;
  if (obj && obj.hasOwnProperty('json_data')) {
    const json_data = obj.json_data;
    delete response.json_data;
    response = { ...response, ...json_data };
  }
  console.log('RESPONSE', response);
  if (fields.length) {
    response = Object.assign(
      ...Object.keys(response)
        .filter(key => fields.includes(key) === true)
        .map(key => ({ [key]: response[key] }))
    );
  }
  return response;
};
export const chunkArray = (array, chunkSize) => {
  const numberOfChunks = Math.ceil(array.length / chunkSize);

  return [...Array(numberOfChunks)].map((value, index) => {
    return array.slice(index * chunkSize, (index + 1) * chunkSize);
  });
};

/*
  Return a object to use in a form returning only the id for child objects
*/
export const getObjectWithChildObjectsToFormValues = obj => {
  let response = { ...obj };
  Object.keys(obj).forEach(k => {
    // console.log('object key', k, obj[k], isObject(obj[k]) );
    if (isObject(obj[k])) {
      response[k] = obj[k].id;
    }
  });
  return response;
};

//extrapolateProp(obj,"prop") transforms {a:1,prop:{b:2,c:3}} into {a:1,b:2,c:3}
export const extrapolateProp = (obj, prop) => {
  if (Object.keys(obj).includes(prop)) {
    const new_obj = { ...obj };
    delete new_obj[prop];
    return { ...new_obj, ...obj[prop] };
  } else {
    return obj;
  }
};

/*
  Return object with first level json_data at root level and filtered by fields
*/
export const getObjectWithJsonDataToFormValues = (obj, fields = []) => {
  if (isEmptyObject(obj)) return {};
  let response = extrapolateProp(obj, 'json_data');
  if (fields.length) {
    response = Object.assign(
      ...Object.keys(response)
        .filter(key => fields.includes(key) === true)
        .map(key => ({ [key]: response[key] }))
    );
  }
  return response;
};

const getObjectWithJsonDataFilter = (obj, fields = []) => {
  let response = { ...obj };
  if (!obj) return response;
  if (obj && obj.hasOwnProperty('json_data')) {
    response.json_data = getObjectWithJsonDataFilter(
      response.json_data,
      fields
    );
  }
  //if any of the fields or a json_data field is in the object, return an empty object
  if (!fields.some(x => Object.getOwnPropertyNames(response).includes(x))) {
    if (!response.json_data) {
      return {};
    } else {
      if (
        !fields.some(x =>
          Object.getOwnPropertyNames(response.json_data).includes(x)
        )
      ) {
        return {};
      }
    }
  }

  if (fields.length) {
    response = Object.assign(
      ...Object.keys(response)
        .filter(key => fields.includes(key) === true || key === 'json_data') //json_data is not given in fields
        .map(key => {
          return { [key]: response[key] };
        })
    );
  }
  return response;
};

//pass an object to specifies how to filter json data
//{"main": ["field1", "field2", "fieldN"], "object1": ["field1", "field2", "fieldN"], "objectN": ["field1", "field2", "fieldN"], }
//main: fields outside any object or inside json_data
const getObjectWithJsonObjectFilter = (obj, fields) => {
  const new_obj = { json_data: {} };
  Object.keys(fields).forEach(key => {
    if (key === 'main') {
      new_obj[key] = getObjectWithJsonDataFilter(obj, fields[key]);
      new_obj.json_data = { ...new_obj.json_data, ...new_obj[key].json_data };
      delete new_obj[key].json_data;
    } else {
      new_obj.json_data = {
        ...new_obj.json_data,
        [key]: getObjectWithJsonDataFilter(obj.json_data[key], fields[key])
      };
      new_obj.json_data[key] = extrapolateProp(
        new_obj.json_data[key],
        'json_data'
      );
    }
  });

  return extrapolateProp(new_obj, 'main');
};

export const getObjectWithJsonArrayFilter = (obj, fields) => {
  const object_fields = {
    ...Object.assign({}, ...fields.filter(f => isObject(f))),
    main: fields.filter(f => !isObject(f))
  };
  return getObjectWithJsonObjectFilter(obj, object_fields);
};

export const isEmptyObject = obj => {
  if (typeof obj === 'undefined') {
    return true;
  } else if (isObject(obj)) {
    return Object.entries(obj).length === 0;
  }
  return false;
};

export const isObject = obj => {
  if (Object.prototype.toString.call(obj) === '[object Object]') return true;
  // ref: https://medium.com/javascript-in-plain-english/javascript-check-if-a-variable-is-an-object-and-nothing-else-not-an-array-a-set-etc-a3987ea08fd7
  // alternatives: if (obj && obj.constructor.name === 'Object') return true;
  return false;
};

export const isEmptyOrUndefined = str => {
  if (typeof str === 'undefined' || !str || str.length === 0) {
    return true;
  }
  return false;
};

export const isNotSetOrTrue = str => {
  if (
    typeof str === 'undefined' ||
    str === null ||
    (typeof str === 'boolean' && str)
  ) {
    return true;
  } else if (typeof str === 'boolean' && str === false) {
    return false;
  }
  return false;
};

export const isJSON = str => {
  try {
    const json = JSON.parse(str);
    if (json && isObject(json)) return true;
  } catch (e) {
    return false;
  }
  return false;
};

export const isFunction = val => {
  //If our variable is an instance of "Function"
  if (val instanceof Function) {
    return true;
  }
  return false;
};

/** DATE MANIPULATION */

/* enumerateTimeSlots v0.0.4 */
export const enumerateTimeSlots = ({
  slot = null,
  wait = 0,
  start = null,
  end = null,
  timeOffset = 0,
  ampm = null
}) => {
  let timeSlots = [];
  if (slot === null) slot = 15;
  if (wait !== 0) slot = slot + wait;
  if (ampm === null) ampm = true;
  if (start === null) start = '00:00';
  if (end === null) end = '23:59';
  if (timeOffset === null) timeOffset = 0;
  const date = new Date();

  let startTime = parse(start, 'HH:mm', date);
  let endTime = parse(end, 'HH:mm', date);

  // console.log('BEFORE ', startTime, endTime);
  // console.log('TIMEOFFSET', timeOffset);

  if (timeOffset > 0) {
    // console.log('MAS');
    startTime = addMinutes(startTime, timeOffset);
    endTime = addMinutes(endTime, timeOffset);
  } else if (timeOffset < 0) {
    // console.log('MENOS');
    startTime = subMinutes(startTime, timeOffset);
    endTime = subMinutes(endTime, timeOffset);
  }
  console.log('AFTER', startTime, endTime);

  let loop = 0;
  while (startTime <= endTime && loop <= 24) {
    // console.log('startTime', startTime);
    // console.log('endTime', endTime);
    let timeSlot = format(startTime, 'HH:mm');
    // console.log('SLOT', timeSlot);
    // if (ampm) timeSlot = moment(timeSlot, 'HH:mm').format('hh:mm A');
    timeSlots.push(timeSlot);
    startTime = addMinutes(startTime, slot);
    loop++;
  }
  return timeSlots;
};

export const toDate = (dateStr, separator = '/') => {
  const parts = dateStr.split(separator);
  return new Date(parts[2], parts[1] - 1, parts[0]);
};

export const fromISO = (dateISO, separator = '/') => {
  const parts = dateISO.substring(0, 10).split('-');
  return `${parts[2]}${separator}${parts[1]}${separator}${parts[0]}`;
};

export const fromISOtoDate = (dateISO, tz = true) => {
  let date = new Date(dateISO);
  let userTimezoneOffset = 0;
  if (tz === false)
    userTimezoneOffset = new Date(date).getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};

export const isDate = val => {
  return (
    val &&
    Object.prototype.toString.call(val) === '[object Date]' &&
    !isNaN(val)
  );
};

export const capitalize = string => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizePhrase = string => {
  if (typeof string !== 'string') return '';
  return string
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
};

export const deleteAccentMarks = str =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const dateComponents = date => {
  if (!isDate(date)) return null;
  let response = [];
  response.push({ short: format(date, 'd') });
  response.push({ short: format(date, 'cccc') });
  response.push({ short: format(date, 'MMM'), long: format(date, 'MMMM') });
  response.push({ dateOnly: format(date, 'yyyy-MM-d') });
  return response;
};

export const timeDistance = (from, to, locale) => {
  console.log('>>>> timeDistance', from, to);
  return formatDistance(to, from, {
    addSuffix: true,
    includeSeconds: true,
    locale
  });
};

export const changeTimeInDate = (time, day) => {
  return parse(time, 'HH:mm', new Date(day));
};

export const now = (separator = '-', type = 'date') => {
  const now = new Date();
  // const offsetMs = now.getTimezoneOffset() * 60 * 1000;
  // const dateLocal = new Date(now.getTime() - offsetMs);
  const dateLocal = new Date(now.setHours(0, 0, 0, 0));
  if (type === 'string') {
    return dateLocal
      .toISOString()
      .slice(0, 19)
      .replace(/-/g, separator)
      .replace('T', ' ');
  } else if (type === 'date') {
    return dateLocal;
  }
};

// export const setTimeZeroDateWithTZ = (date, tz = false) => {
//   const baseDate = new Date(date).setUTCHours(0,0,0,0);
//   const dateZero = new Date(baseDate);
//   const offsetMs = new Date(baseDate).getTimezoneOffset() * 60 * 1000;
//   // console.log('setTimeZeroDateWithTZ',date);
//   // console.log('setTimeZeroDateWithTZ',dateZero);
//   // console.log('setTimeZeroDateWithTZ',offsetMs);
//   if (!tz) {
//     return dateZero;
//   } else {
//     return new Date(dateZero.getTime() + offsetMs);
//   }
// }
export const setTimeZeroDateWithTZ = (date, tz = true) => {
  const baseDate = new Date(date).setHours(0, 0, 0, 0);
  const dateZero = new Date(baseDate);
  return dateZero;
};

export const getDateOnly = date => {
  const date1 = new Date(date);
  return new Date(format(date1, 'yyy-MM-dd')).toISOString().slice(0, 10);
};

export const setDateTZ = date => {
  const date1 = new Date(date);
  const dateTZ = new Date(
    date1.getTime() + date1.getTimezoneOffset() * 60 * 1000
  );
  return dateTZ;
};

export const months = (m = null) => {
  const month = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'setiembre',
    'octubre',
    'noviembre',
    'diciembre'
  ];
  return m !== null ? month[m] : month;
};

export const getMonthsOfYear2 = () => {
  // ** We use this format to get the names of the months
  const dateFormat = 'MMMM';
  // ** Get the fist day of the month
  const startDate = startOfMonth(new Date());
  const months = [];
  for (let i = 0; i < 12; i++) {
    // ** Add days adds 1 day with each iteration. The function does it 7 times to get the full week
    // ** First month is December, so December + 1 month === January
    months.push(format(addMonths(startDate, i), dateFormat));
  }
  return months;
};
export const getMonthsOfYear = (type = 'strings', startMonth) => {
  // ** Get the fist day of the month
  const startDate = startOfMonth(new Date());
  const stringMonths = [];
  for (let i = 0; i < 12; i++) {
    // ** Add days adds 1 day with each iteration. The function does it 7 times to get the full week
    // ** First month is December, so December + 1 month === January
    stringMonths.push(
      addMonths(startDate, i).toLocaleDateString(undefined, { month: 'long' })
    );
  }
  const months = stringMonths.map((e, i) => ({ name: e, value: i + 1 }));
  const orderedMonths = [];
  // ** If startMonth parameter exist we re order the array based on the start day that we recieved
  if (startMonth) {
    // ** First, we set a boolean variable that indicates if the first day of month its added to the array
    let firstMonth = false;
    months.forEach(e => {
      // ** We ask if the day its the same as the startMonth from the parameter
      if (deleteAccentMarks(e.name) === deleteAccentMarks(startMonth)) {
        // ** Push it into the array and set firstMonth to true
        orderedMonths.push(e);
        firstMonth = true;
      }
      // ** Now we want to add after that, the rest of days
      if (
        firstMonth &&
        deleteAccentMarks(e.name) !== deleteAccentMarks(startMonth)
      )
        orderedMonths.push(e);
    });
    // ** As we probably lost the days before the startMonth, we need to add them at the end of the array
    if (firstMonth)
      for (
        let i = 0;
        deleteAccentMarks(months[i].name) !== deleteAccentMarks(startMonth);
        i++
      ) {
        console.log('HERE');
        console.log(deleteAccentMarks(months[i].name));
        console.log(deleteAccentMarks(startMonth));
        orderedMonths.push(months[i]);
      }
  }
  // ** If start day parameter exist we send the orderedMonths, if not just the months array
  const monthsForReturn =
    startMonth && orderedMonths.length ? orderedMonths : months;

  if (!orderedMonths.length)
    console.error(
      `${startMonth} is not a valid startMonth, returning default ordered array`
    );
  // ** In case type is strings we want to map our array of months to return strings instead of objects
  if (type === 'strings') return monthsForReturn.map(e => e.name);
  // ** In case type is objects we want to return the array without changing it
  else if (type === 'objects') return monthsForReturn;
};

export const getDaysOfWeek2 = () => {
  // ** We use this format to get the names of the days
  const dateFormat = 'EEEE';
  // ** Get the fist day of the week
  const startDate = startOfWeek(new Date());
  const days = [];
  for (let i = 0; i < 7; i++) {
    // ** Add days adds 1 day with each iteration. The function does it 7 times to get the full week
    // ** First day is Sunday, so Sunday + 1 day === Monday
    days.push(format(addDays(startDate, i), dateFormat));
  }
  console.log(days);
  return days;
};

export const getDaysOfWeek = (type = 'strings', startDay) => {
  const stringDays = [];
  // ** Get the fist day of the week
  const startDate = startOfWeek(new Date());
  // ** Add days adds 1 day with each iteration. The function does it 7 times to get the full week
  // ** First day is Sunday, so Sunday + 1 day === Monday
  for (let i = 0; i < 7; i++)
    stringDays.push(
      addDays(startDate, i).toLocaleDateString(undefined, { weekday: 'long' })
    );
  const days = stringDays.map((e, i) => ({ name: e, value: i + 1 }));
  const orderedDays = [];

  // ** If startDay parameter exist we re order the array based on the start day that we recieved
  if (startDay) {
    // ** First, we set a boolean variable that indicates if the first day of week its added to the array
    let firstDay = false;
    days.forEach(e => {
      // ** We ask if the day its the same as the start day from the parameter
      if (deleteAccentMarks(e.name) === deleteAccentMarks(startDay)) {
        // ** Push it into the array and set firstDay to true
        orderedDays.push(e);
        firstDay = true;
      }
      // ** Now we want to add after that, the rest of days
      if (firstDay && deleteAccentMarks(e.name) !== deleteAccentMarks(startDay))
        orderedDays.push(e);
    });
    // ** As we probably lost the days before the startDay, we need to add them at the end of the array
    if (firstDay)
      for (
        let i = 0;
        deleteAccentMarks(days[i].name) !== deleteAccentMarks(startDay);
        i++
      )
        orderedDays.push(days[i]);
  }
  // ** If start day parameter exist we send the orderedDays, if not just the days array
  const daysForReturn = startDay && orderedDays.length ? orderedDays : days;

  if (!orderedDays.length)
    console.error(
      `${startDay} is not a valid startDay, returning default ordered array`
    );

  // ** In case type is strings we want to map our array of days to return strings instead of objects
  if (type === 'strings') return daysForReturn.map(e => e.name);
  // ** In case type is objects we want to return the array without changing it
  else if (type === 'objects') return daysForReturn;
};

export const numToTime = number => {
  // Check sign of given number
  const sign = number >= 0 ? 1 : -1;
  number = number * sign;

  let hour = Math.floor(number);
  let decpart = number - hour;

  let min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);
  let minute = Math.floor(decpart * 60);
  // Add padding if need
  return (
    (sign === 1 ? '' : '-') +
    formatCompleteWith(hour, 2) +
    ':' +
    formatCompleteWith(minute, 2)
  );
};

/** FORMAT */

export const formatCompleteWith = (str, len = 0, char = '0') => {
  const length = len === 0 ? str.toString().length : len;
  return char.repeat(length - str.toString().length) + str.toString();
};

export const formatAddressFromGoogle = address => {
  return `${address.components[1].short_name} ${address.components[0].short_name}`;
};

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ',',
  thousands = '.'
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? '-' : '';
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log('FORMATMONEY error:', e);
  }
};

export const formatPhoneNumber = phoneNumberString => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const formatTax = str => {
  if (isEmptyOrUndefined(str)) return null;
  let match = str.match(/^(\d{2})(\d{8})(\d{1})$/);
  if (match) {
    return [match[1], match[2], match[3]].join('-');
  }
  return null;
};

export const currencyTranslate = (current, translation) => {
  return translation && translation[current] ? translation[current] : current;
};

/** OTHERS */

export const paginate = (
  totalItems,
  currentPage = 1,
  pageSize = 10,
  maxPages = 0
) => {
  // calculate total pages
  let totalPages = Math.ceil(totalItems / pageSize);

  maxPages = maxPages === 0 ? totalPages : maxPages;
  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage, endPage;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  let pages = 0;
  if (totalPages) {
    pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i
    );
  }

  const disablePrevious = currentPage === 1 ? true : false;
  const disableNext = currentPage === endPage ? true : false;

  // return object with all pager properties required by the view
  return {
    totalItems,
    currentPage,
    pageSize,
    totalPages,
    startPage,
    endPage,
    startIndex,
    endIndex,
    disablePrevious,
    disableNext,
    pages
  };
};

export const deleteDuplicatedEntries = array => {
  const result = array.reduce((acc, item) => {
    if (!acc.includes(item)) {
      acc.push(item);
    }
    return acc;
  }, []);
  return result;
};

export const permissionsCheck = (path, userType, permissions, action = 'route') => {
  // If permissions is not defined, can't access anything
  if (userType === "superadmin") return true;
  if (permissions === undefined || permissions.length === 0) return false;
  // cut the path into a string after the /, and split that string with / separator
  let route = null;
  if (!path.includes('user/')) {
    route = path.substr(1, path.length).split('/');
  } else if (path.includes('user/')) {
    // in this special case we'll just make an array with one element, we will not split
    route = [path.substr(1, path.length)];
  }


  // if the first position of the route includes these options, or there is nothing there
  // true, everyone can access these paths
  if (['login', 'logout', 'icons'].includes(route[0]) || route[0] === '')
    return true;

  // function to check permissions (invoked below)
  function actionType(perm) {

    // if route action and route is one
    // return true if we either are allowed to read or write
    if (action === 'route' && route.length === 1) {
      return perm.action_type === 'read' || perm.action_type === 'insert';
    }
    // if 2nd pos is new, we must have write permissions
    if (action === 'route' && route.length > 1 && (route[1].includes('new') || route[1].includes('profile'))) {
      return perm.action_type === 'insert';
    }
    // if it's id, we must have permission to either read or write
    if (action === 'route' && route.length > 1 && route[1].includes(':id')) {
      return perm.action_type === 'read' || perm.action_type === 'insert';
    }
    // if we want to delete update insert we must be allowed to write
    // refactor target?
    // if (action !== 'route' && ['delete'].includes(action)) {return perm.action_type === 'delete'}
    if (action !== 'route' && ['insert'].includes(action)) {
      return perm.action_type === 'insert';
    }
    if (action !== 'route' && ['update'].includes(action)) {
      return perm.action_type === 'update';
    }
    if (action !== 'route' && ['delete'].includes(action)) {
      return perm.action_type === 'delete';
    }
    if (action !== 'route' && ['enable'].includes(action)) {
      return perm.action_type === 'enable';
    }
  }

  // if permissions is not an array or there are no permissions, and we're trying to do a route
  // true
  if ((!Array.isArray(permissions) || !permissions) && action === 'route') {
    return true;
  } else if (
    // if the action is delete update or insert without an array or permissions, no permissions
    (!Array.isArray(permissions) || !permissions) &&
    ['delete', 'update', 'insert'].includes(action)
  ) {
    return false;
  }
  // generates array based on what permissions we have
  const found = permissions.filter(
    // object types that fit first position of route array
    // call actionType func with the permission
    p => p.object_type === route[0] && actionType(p)
    // p.action_type === action
  );

  // returns true if we have found permissions
  return found.length > 0;
};

export const getTimeZone = () => {
  if (Intl) return Intl.DateTimeFormat().resolvedOptions().timeZone;
  return null;
};

export const getClientIp = async () =>
  await publicIp.v4({
    fallbackUrls: ['https://ifconfig.co/ip']
  });

export const getLatLng = callback => {
  // Try HTML5 geolocation.
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        if (callback) callback({ lat, lng });
      },
      err => {
        console.log('ERROR WITH GEOLOCATION', err);
        if (callback) callback(null);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  } else {
    console.log('NO HTML GEOLOCATION');
    if (callback) callback(null);
  }
};

export const vehicleString = ({ brand, model, color = '', patent = '' }) => {
  return `${capitalize(brand)} ${capitalize(model)} ${color} ${patent}`;
};

export const hideColumns = (number, windowWidth = 0) => {
  if (windowWidth < number) {
    return 'lg';
  }
  return null;
};


export const getQueryParams = (url) => {
  const paramArr = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};
  paramArr.map(param => {
      const [key, val] = param.split('=');
      params[key] = decodeURIComponent(val);
  })
  return params;
}