import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import userActions from '../../context/users/actions';
import filesActions from '../../context/files/actions';
import notificationsActions from '../../context/notifications/actions';
import { ToastContainer, notify } from '../../libraries/notifications';
import { validateIsfilled, getObjectWithJsonDataToFromValues, capitalize, capitalizePhrase } from '../../libraries/utils';
import { history } from '../../routes';
import config from '../../config';
import LayoutWithSidebar from '../../components/layout/LayoutWithSidebar';
import Button from '../../components/commons/Button';
import TextInput from '../../components/forms/TextInput';
import logout from '../../assets/icons/custom/app-mobile/deslog.png';
import avatar from '../../assets/icons/custom/Desktop/app-desktop/icon-1.png';
import gift from '../../assets/icons/custom/Desktop/app-desktop/icon-gift.png';
import list from '../../assets/icons/custom/Desktop/app-desktop/icon-list.png';
import MenuItem from '../../components/commons/MenuItem';
import Footer from "../../components/layout/Footer";
import Loader from '../../components/commons/Loader';
import Icon from '../../libraries/icons';


class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      submitting: false,
      loading: false,
      selectedFile: null,
      profile_image: null,
      user: null,
    };
  }

 

  getUser = async () => {
    await this.props.onGetUser({ id: this.props.auth.user.id });
    const error = this.props.user.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      const user = getObjectWithJsonDataToFromValues(this.props.user.item, [
        'id',
        'first_name',
        'last_name',
        'phone',
        'email',
        'profile_image',
        'identification',
        'identification_type',
        'gender',
        'birth_date',
      ]);

      this.setState({ user });
     
      
    }
  };

  componentDidMount() {
    
    this.getUser(); 
    
  }

  doLogout = () => {
    this.props.onLogout();
    this.props.onClearNotifications();
    history.push(config.ROUTES.HOME)
  };

  gotoBack = async () => {
    await this.props.onGetToken();
    if (this.props.auth.error) notify(this.t(this.props.auth.error.message));
    history.push(config.ROUTES.USER.HOME);
  };

  onSubmit = async (values) => {
    this.setState({ submitting: true });
    const data = { ...values, user_type: config.USER_TYPE.REGULAR };
    await this.props.onUpdate(data);
    const error = this.props.user.error;
    if (error) {
      this.setState({ submitting: false });
      notify(this.t(error.message));
    } else {
      this.gotoBack();
    }
  };

  

  onFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('myFile', file, file.name);
    await this.props.onPictureUpload(formData);
    const error = this.props.files.error;
    if (error) {
      notify(this.t(error.message));
    } else {
      this.setState({
        profile_image: this.props.files.files.fileInfo.location,
      });
      await this.props.onUpdate({
          id: this.state.user.id,
          user_type: config.USER_TYPE.REGULAR,
          profile_image: this.props.files.files.fileInfo.location,
         
        })
      const error = this.props.user.error;
      
      if (error) notify(this.t(error.message));
        
    }
  };
 
  onFileChange = async (event) => {
    this.setState({ submitting: true });
    this.setState({ loading: true });
   
    this.setState({ selectedFile: event.target.files[0] });
    await this.onFileUpload(event.target.files[0]);

    history.go(0)   //FIXME: arreglo temporal hasta que se fixee el auth que no actualiza. 
    this.setState({ loading: false });
    this.setState({ submitting: false });
  };
  render() {
    const { user  } = this.state;

   

    if (this.state.profile_image) {
      user.profile_image = this.state.profile_image;
    }

    

    const required = (value) =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: '' }}
      >
        <div className="flex flex-row justify-center items-start gap-10 md:py-20 md:mt-10 min-h-[calc(100%-297px)]">
          <section className='hidden w-[300px] md:flex rounded-xl card white overflow-visible mt-2'>
            <ToastContainer />
            <div className='bg-gradient-to-r  from-primary to-primary-focus w-full h-fit flex-col items-center gap-2 justify-center p-4 pb-2 rounded-t-xl'>
              <div className="rounded-full -mt-10 border-gray-100 border-4 overflow-hidden flex mx-auto mb-4 text-center w-20 h-20">
                {user && user.profile_image ? (
                  <img
                    className="w-full h-auto"
                    src={user && user.profile_image}
                    alt="profile"
                  />
                ) : (
                  <span className=" text-white text-2xl">
                    {user && capitalizePhrase(user.username)}
                  </span>
                )}
              </div>
              <p className='text-center text-white font-bold text-lg'>{user && capitalizePhrase(user.first_name)}</p>
              <p className='text-center text-white'>{user && user.email}</p>
            </div>
            <div className='rounded-b-xl bg-white w-full'>
              <ul
                className="menu py-4 text-base-300 cursor-pointer">
                {[ {label: capitalize(this.t('Profile')), route: config.ROUTES.USER.INFO, img: avatar},
                {label: capitalize(this.t('regalos')), route: config.ROUTES.USER.MY_GIFTS, img: gift},
                {label: capitalize(this.t('mis listas')), route: config.ROUTES.USER.MY_LISTS, img: list}].map(
                  (item, index) => (
                    <MenuItem location={this.props.location} key={index} item={item} />
                  )
                )}
              </ul>
            </div>
            
          </section>
          <section className='flex flex-col md:max-w-[450px] rounded-none md:rounded-xl w-full card white'>
            <div className="">
              <div className="bg-gradient-to-r text-white from-primary to-primary-focus px-4 pb-3 pt-20 md:pt-2 flex justify-between md:rounded-t-xl md:hidden">
                <div>
                  <p className='text-2xl'>Hola,</p>
                  <p className='text-3xl'>{user && user.first_name ? capitalizePhrase(user.first_name) : ''}</p>
                  <div className='text-lg mt-4'>Mi perfil</div>
                </div>
                <div className="rounded-lg">
                  <div className="rounded-full md:hidden bg-primary-focus overflow-hidden flex ml-auto text-center w-16 h-16">
                    {user && user.profile_image ? (
                      <img
                        className="w-full h-auto"
                        src={user && user.profile_image}
                        alt=""
                      />
                    ) : (
                      <span className=" text-white text-2xl">
                        {user && capitalizePhrase(user.username)}
                      </span>
                    )}
                  </div>
                  {/* <label className="cursor-pointer mt-6">
                    {capitalize(this.t('change photo'))}
                    <span className="btn bg-white bg-opacity-[0.45] border-btn border-white text-white btn-sm mt-3 md:mt-14 text-sm capitalize font-normal">
                    </span>
                    <input
                      type="file"
                      className="hidden"
                      multiple="multiple"
                      accept="accept"
                      onChange={this.onFileChange}
                    />
                  </label> */}
                </div>
              </div>
              <div className='hidden md:flex flex-col items-start p-8 pb-0'>
                <h2 className='h2 text-primary flex items-center gap-2'>{this.t("Información personal")}</h2>
                <label className="cursor-pointer">
                {this.state.loading ? (
                          <Loader spinnerClassName="text-primary h-12 w-12" /> ) :  <span className="btn bg-secondary border-btn border-white text-white btn-sm mt-3 text-sm font-normal">
                          {capitalize(this.t('change photo'))}
                        </span>}
                 
                  <input
                    type="file"
                    className="hidden"
                    multiple="multiple"
                    accept="accept"
                    onChange={this.onFileChange}
                  />
                </label>
              </div>
            </div>
            <Form initialValues={user || {}} onSubmit={this.onSubmit}>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="py-4 px-8">
                    <div className="w-full md:mb-0">
                      <Field
                        name="first_name"
                        component={TextInput}
                        label={this.t('First name')}
                        validate={required}
                        inputClassName="input-bordered shadow-none bg-[#F5F5F5]"
                      />
                    </div>
                    <div className="w-full md:mb-0">
                      <Field
                        name="last_name"
                        component={TextInput}
                        label={this.t('Last name')}
                        validate={required}
                        inputClassName="input-bordered shadow-none bg-[#F5F5F5]"
                      />
                    </div>
                    <div className="w-full md:mb-0">
                      <Field
                        name="email"
                        component={TextInput}
                        label={this.t('Email')}
                        validate={required}
                        disabled={true}
                        inputClassName="input-bordered shadow-none bg-[#F5F5F5]"
                      />
                    </div>
                    <div className="w-full mb-4">
                      <Button
                        className="bg-transparent text-sm text-primary border-none btn-sm"
                        title={this.t('Save')}
                        type="submit"
                        onClick={handleSubmit}
                        // disabled={this.state.submitting || pristine} //FIXME: no me deja volver a cambiar el nombre 
                      />
                    </div>
                  </div>
                </form>
              )}
            </Form>
            <div className='flex flex-col justify-start items-start px-4 pb-4'>
              <Button title={this.t('Change password')} className='bg-transparent text-sm text-primary border-none btn-sm'
                onClick={()=>history.push(config.ROUTES.USER.PASSWORD_CHANGE)} />
              <Button img={logout} iconSize="20" title={this.t('Logout')} className='bg-transparent text-sm text-base-300 border-none btn-sm'
                onClick={this.doLogout} />
            </div>
            {/* <div onClick={this.toggleVideo}
              className='absolute right-0 bottom-0 m-3 h-10 w-10 border-2 border-primary rounded-lg grid place-content-center hover:bg-gradient-to-r hover:from-primary hover:to-primary-focus hover:border-0 cursor-pointer'
            >
              <Icon className="h-8 w-8" name="video_camera" />
            </div> */}
          </section>
        </div>
        <Footer className="hidden md:block" />
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    files: state.files,
    user: state.users.current,
    auth: state.users.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdate: (params) => dispatch(userActions.saveOrUpdate(params)),
    onGetUser: (params) => dispatch(userActions.get(params)),
    onPictureUpload: (params) => dispatch(filesActions.upload(params)),
    onGetToken: () => dispatch(userActions.getFromToken()),
    onLogout: () => dispatch(userActions.logout()),
    onClearNotifications: () => dispatch(notificationsActions.clearList())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserInfo));
