import { combineReducers } from 'redux';

import accounts from './accounts';
import discounts from './discounts';
import files from './files';
import giftlists from './giftlists';
import internals from './internals';
import localization from './localization'; //TODO: REMOVE
import messages from './messages';
import notifications from './notifications';
import oauth from './oauth';
import permissions from './permissions';
import permissions_roles from './permissions_roles';
import roles from './roles';
import roles_users from './roles_users';
import settings from './settings';
import transactions from './transactions';
import users from './users';
import payments from './payments';

export default combineReducers({
  payments: payments.reducers,
  accounts: accounts.reducers,
  discounts: discounts.reducers,
  files: files.reducers,
  giftlists: giftlists.reducers,
  internals: internals.reducers,
  locale: localization.reducers, //TODO: REMOVE
  messages: messages.reducers,
  notifications: notifications.reducers,
  oauth: oauth.reducers,
  permissions: permissions.reducers,
  permissions_roles: permissions_roles.reducers,
  roles: roles.reducers,
  roles_users: roles_users.reducers,
  settings: settings.reducers,
  transactions: transactions.reducers,
  users: users.reducers,
});
