import React from 'react';
import { isNotSetOrTrue } from '../../libraries/utils';

import Loader from '../commons/Loader';
import ButtonIcon from '../commons/ButtonIcon';
import logo from '../../assets/img/logo-negative.png';

export default function LayoutSmall({
  header,
  main,
  container,
  footer,
  children,
  loading,
}) {
  /*
  {
    header: {
      logo: false,
      title: '',
      className: '',
      left: {
        className: '',
        icon: '',
        action: '',
        visible: true/false
      },
      right: {
        className: '',
        icon: '',
        action: '',
        visible: true/false
      },
      content: "html"
    },
    main: {
      className: '',
    },
    container: {
      className: '',
    }
    footer: {
      className: '',
      content: "HTML"
    }
  }
  */
  // flex items-center justify-between w-full p-3 h-16
  return (
    //bg-primary h-full w-full overflow-auto
    <main
      className={
        'flex flex-col h-screen ' +
        (main && main.className ? main.className : '')
      }
    >
      <div>
        {header && (
          <header
            className={
              'header--layout__small ' +
              (header && header.className ? header.className : '')
            }
          >
            <div className="w-12">
              {header && header.left && isNotSetOrTrue(header.left.visible) && (
                <ButtonIcon
                  buttonClassName="btn-ghost btn-sm"
                  className={
                    header.left.className ? header.left.className : 'h-6 w-6'
                  }
                  onClick={header.left.action || (() => true)}
                  icon={header.left.icon}
                />
              )}
            </div>
            {header && header.title ? (
              <h2 className="">{header.title}</h2>
            ) : (
              header &&
              header.logo && (
                <div>
                  <img src={logo} alt="" width="150px" />
                </div>
              )
            )}
            <div className="w-12">
              {header &&
                header.right &&
                isNotSetOrTrue(header.right.visible) && (
                  <ButtonIcon
                    buttonClassName="btn-ghost btn-sm"
                    className={
                      header.right.className
                        ? header.right.className
                        : 'h-6 w-6'
                    }
                    onClick={header.right.action}
                    icon={header.right.icon}
                  />
                )}
            </div>
          </header>
        )}
        {header.content && header.content}
      </div>
      <div
        className={
          'flex-1 h-60 overflow-y-auto ' +
          (container && container.className ? container.className : 'px-4')
        }
      >
        {loading ? <Loader size="full" /> : children}
      </div>
      {footer && footer.content && (
        <footer
          className={
            'bg-transparent' +
            (footer && footer.className ? footer.className : '')
          }
        >
          {footer.content}
        </footer>
      )}
    </main>
  );
}
