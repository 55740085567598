import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { history } from "../../routes";
import { capitalize, getQueryParams } from "../../libraries/utils";
import config from "../../config";
import paymentsActions from "../../context/payments/actions";

import Loader from "../../components/commons/Loader";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import Button from "../../components/commons/Button";
import Icon from "../../libraries/icons";
import Footer from "../../components/layout/Footer";

import giftError from '../../assets/img/icon cards/icon-cumpleaños.png';

class OperationCheck extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      loading : true
    };
  }

  componentDidMount() {
    this.getPayment();
  }

  async getPayment() {
    const params = getQueryParams(this.props.location.search)

    const data = {
      redirect_uri: `${config.FRONTEND_URL}${config.ROUTES.REDIRECT_REDEEM.replace(":hash", "")}`,
      ...params
    };
    if (data.status !== 'approved') {
      this.setState({loading: false, responsable: 'Mercado Pago', message: 'Ocurrió un error con mercado pago, puedes intentar de nuevo'})
      await this.props.onPayment(data);
    } else  {
      await this.props.onPayment(data);
      if (this.props.payment.error) {
        this.setState({loading: false, responsable: 'Binance', message: this.props.payment.error.message})
      } else {
        const payment = this.props.payment.item;
     
        const routeState = {
          from: "gift",
          payment_id: payment.transaction_id,
          total_BTC: payment.total_BTC,
          price_BTC: payment.price_BTC,
          total_USD: payment.total_USD,
          fee: payment.fee,
          total_ARS: payment.total_ARS,
          sender: payment.sender,
          recipient: payment.recipient,
          date: payment.date,
          event_type: payment.event_type,
          message: payment.message,
        }
  
        history.push({pathname: config.ROUTES.CONGRATULATIONS, state: routeState})
      }
    }
  }



  render() {
    const state = this.state

    return (
      state.loading ? (
        <LayoutWithSidebar container={{ className: "p-10 flex flex-col justify-center items-center" }}>
          <Loader />
          <div className="text-black text-2xl text-center">
              {capitalize(this.t("cargando"))}
          </div>
        </LayoutWithSidebar>
      ):(
        <LayoutWithSidebar
          header={{logo: true}}
          main={{ className: 'text-content-400' }}
          container={{ className: '' }}
        >
          <section className="flex flex-row justify-around items-start gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
            <div className="card white md:w-[500px] w-full">
              <div className="bg-error h-56 flex items-center justify-center">
                <div className="relative">
                  <img src={giftError} width="100px" />
                  <div className="bg-error w-14 h-14 rounded-full absolute -right-7 -bottom-7 flex items-center justify-center">
                    <Icon className="w-12 h-12 text-white" name="x_circle" />
                  </div>
                </div>
              </div>
              <div className="flex-1 p-5">
                  <div className="mb-5 text-center font-bold text-2xl mt-5">
                      Ocurrio un error de {capitalize(state.responsable)}
                  </div>
                  {/* <p className="text-center">
                    {capitalize(state.message)}
                  </p> */}
              </div>
              <div className="flex flex-col gap-4 justify-center p-5 mt-5">
                {state.responsable !== 'Binance' &&
                  <Button
                    className="btn-secondary"
                    title={capitalize(this.t('volver a intentar'))}
                    onClick={()=>history.push(config.ROUTES.GIFT_CARD)}
                  />
                }
                {state.responsable === 'Binance' &&
                  <>
                  <p className="font-semibold text-center">Contactá a nuestro soporte!</p>
                  <a href='mailto:inforegalalebitcoins@gmail.com' className="cursor-pointer text-center">inforegalalebitcoins@gmail.com</a>
                  </>
                }
                <Button
                  className="btn-outline"
                  onClick={()=>history.push(config.ROUTES.HOME)}
                  title={capitalize(this.t('volver a inicio'))}
                />
              </div>
            </div>
          </section>
          <Footer className="hidden md:block" />
        </LayoutWithSidebar>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payment: state.payments.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPayment: (data) => dispatch(paymentsActions.saveCapture(data))

  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OperationCheck));