const types = {
  GETIPINFO_REQUEST: 'INTERNALS_GETIPINFO_REQUEST',
  GETIPINFO_SUCCESS: 'INTERNALS_GETIPINFO_SUCCESS',
  GETIPINFO_FAILURE: 'INTERNALS_GETIPINFO_FAILURE',

  SENDMAIL_REQUEST: 'INTERNALS_SENDMAIL_REQUEST',
  SENDMAIL_SUCCESS: 'INTERNALS_SENDMAIL_SUCCESS',
  SENDMAIL_FAILURE: 'INTERNALS_SENDMAIL_FAILURE',
};

export default types;