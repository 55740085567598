import React from 'react';

function SelectWithIconInput({ input, meta, label, options, empty, ...rest }) {
  const imageIndex = options.findIndex( o => o.value === input.value )
  return (
    <div
      className={
        'form-control w-full max-w-xs ' + rest.className ? rest.className : ''
      }
    >
      {label && (
        <label
          htmlFor={input.name}
          className={
            'label ' + (rest.labelClassName ? rest.labelClassName : '')
          }
        >
          <span className="label-text">{label}</span>
        </label>
      )}
      <div className='flex gap-2'>
        <img src={options[imageIndex].icon} className='w-12 h-12' />
        <select
            {...input}
            className={
            'select w-full flex-shrink ' +
            (meta?.error && meta?.touched ? ' input-error invalid-shadow' : '') +
            (rest.narrow ? ' px-1' : '')
            }
            disabled={rest.readOnly || rest.disabled}
        >
            {empty && (
            <option key={input.name + '0'} value="">
                {empty}
            </option>
            )}
            {options &&
            options.map((option) => (
                <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                selected={option.selected}
                data-img-src={option.icon}
                >
                {option.label}
                </option>
            ))}
        </select>
      </div>
      {!rest.noError && (
        <label className="label">
          <span className="label-text-alt invalid-feedback">
            {meta?.error && meta?.touched && meta?.error}
          </span>
        </label>
      )}
    </div>
  );
}

export default SelectWithIconInput;