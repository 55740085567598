import React from 'react';

export default function UserProfileImage({ data, picture, username, ...rest }) {
  return (
    <>
      <div
        className={
          'rounded-full bg-secondary-focus overflow-hidden flex items-center text-center mr-4 ' +
          (picture && picture.className ? picture.className : 'w-12 h-12')
        }
        onClick={() => {
          picture && picture.onClick && picture.onClick();
        }}
      >
        {data.profile_image ? (
          <img className=" h-full w-full" src={data.profile_image} alt="" />
        ) : (
          <span className="m-auto text-white text-2xl">{data.username}</span>
        )
        }
      </div>
      <div className={rest.textContainer ? rest.textContainer : ''}>
        <h5
          className={
            username && username.className
              ? username.className
              : 'h5 font-semibold mb-0 inline-block'
            }
        >
          {`${data.first_name} ${data.last_name}`}
        </h5>
        <h2 className='text-xs opacity-50'>{data.email}</h2>
      </div>
    </>
  );
}
