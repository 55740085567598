const types = {
  CLEAR_CURRENT: 'PERMISSIONS_CLEAR_CURRENT',
  GET_REQUEST: 'PERMISSIONS_GET_REQUEST',
  GET_SUCCESS: 'PERMISSIONS_GET_SUCCESS',
  GET_FAILURE: 'PERMISSIONS_GET_FAILURE',

  SAVE_REQUEST: 'PERMISSIONS_SAVER_REQUEST',
  SAVE_SUCCESS: 'PERMISSIONS_SAVE_SUCCESS',
  SAVE_FAILURE: 'PERMISSIONS_SAVE_FAILURE',

  GETALL_REQUEST: 'PERMISSIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'PERMISSIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'PERMISSIONS_GETALL_FAILURE',

  DELETE_REQUEST: 'PERMISSIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'PERMISSIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PERMISSIONS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'PERMISSIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PERMISSIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PERMISSIONS_UPDATE_FAILURE'    

};

export default types;