import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import { withTranslation } from "react-i18next";
import { capitalize, validateIsfilled } from '../../libraries/utils';
import arrowRight from '../../assets/icons/arrow-right.svg';
import Calendar from '../../assets/icons/outline/calendar.svg'
import settingsActions from '../../context/settings/actions';
import internalsActions from '../../context/internals/actions';
import discountsActions from '../../context/discounts/actions'
import paymentsActions from '../../context/payments/actions';
import transactionsActions from '../../context/transactions/actions';
import Button from "../../components/commons/Button";
import aniversario from '../../assets/img/cards/aniversario.png';
import bautismo from '../../assets/img/cards/bautismo.png';
import casamiento from '../../assets/img/cards/casamiento.png';
import comunion from '../../assets/img/cards/comunion.png';
import confirmacion from '../../assets/img/cards/confirmacion.png';
import cumpleaños from '../../assets/img/cards/cumpleaños.png';
import snValentin from '../../assets/img/cards/dia de.png';
import graduacion from '../../assets/img/cards/graduacion.png';
import mudanza from '../../assets/img/cards/mudanza.png';
import nacimiento from '../../assets/img/cards/nacimiento.png';
import fiesta from '../../assets/img/cards/fiesta.png';
import navidad from '../../assets/img/cards/navidad.png';
import reyes from '../../assets/img/cards/reyes.png';
import fathers from '../../assets/img/cards/padre.png';
import mothers from '../../assets/img/cards/madre.png';
import btc from '../../assets/img/cards/btc.png';
import qrCode from '../../assets/img/qr_code.png';
import { notify, ToastContainer } from "../../libraries/notifications";
import Footer from "../../components/layout/Footer";
import Loader from "../../components/commons/Loader";

import Swal from 'sweetalert2';
import { isBefore, isAfter, format, parseISO } from 'date-fns'
import { Form, Field } from 'react-final-form'

import pirate1 from '../../assets/img/events/pirate1.png';
import pirate2 from '../../assets/img/events/pirate2.png';
import TextInput from "../../components/forms/TextInput";

const images = Object.freeze({ pirate1, pirate2 });

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});


class GiftBTC extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      USDprice: 0,
      BTCprice: 0,
      total: 0,
      btc: 0,
      fee_USD: 0,
      cards: {
        christmas: navidad,
        threekings: reyes,
        party: fiesta,
        birthday: cumpleaños,
        wedding: casamiento,
        anniversary: aniversario,
        baptism: bautismo,
        confirmation: confirmacion,
        valentines: snValentin,
        graduation: graduacion,
        move: mudanza,
        birth: nacimiento,
        communion: comunion,
        fathers_day: fathers,
        mothers_day: mothers,
        bitcoin: btc,
      },
      address: 'bc1q5kdpff93y3e4z9u9awltzs5safsmn8p9vaffus',
      loading: true,
      discountCode: null,
      minutes: 0,
      seconds: 0,
      transactionHash: "",
      isVerifying: false,
    };
  }

  async componentDidMount() {
    if(!this.props.location?.state){
      history.push(config.ROUTES.HOME)
      return
    }
    this.setState({ loading: true })
    this.setState({...this.props.location.state, address: "bc1q5kdpff93y3e4z9u9awltzs5safsmn8p9vaffus" })
    this.getData();
    
    const countDownDate = new Date(new Date().getTime() + 15*60000);
    const interval = setInterval(() => {
      // console.log('interval')
      // Get today's date and time
      const now = new Date().getTime();
      // Find the distance between now and the count down date
      const distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      // days = Math.floor(distance / (1000 * 60 * 60 * 24));
      // hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.setState({ 
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      })

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(interval);
        history.push(config.ROUTES.HOME)
      }
    }, 1000);
  }

  getData = async () => {
    await this.getSettings();
    const fee_USD = this.state.settings.find(s => s.code === config.TYPES.SETTINGS.CODES.ARS_TO_USD).value
    await this.getBTC();
    await this.getUSD(fee_USD);
    this.fixValues();
  }

  getSettings = async () => {
    await this.props.onGetSettings({ code: [config.TYPES.SETTINGS.CODES.ARS_TO_USD, config.TYPES.SETTINGS.CODES.BTC_PRICE, config.TYPES.SETTINGS.CODES.BINANCE_WITHDRAW, config.TYPES.SETTINGS.CODES.BUYER_FEE] });
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
      return 0
    } else {
      this.setState({settings: settings.items.map(s => { return {code:s.code, value:Number(s.value) } })})
    }
  };

  async getBTC() {
    const BTC = await this.props.onCoin({ coin: 'btcusdt' });
    if (BTC.error) {
      notify(BTC.error.message);
      return
    }
    this.setState({BTCprice: BTC.price})
  }
  async getUSD(fee_USD) {
    const USD = await this.props.onCoin({ coin: 'usd' });
    if (USD.error) {
      notify(USD.error.message);
      return
    }
    this.setState({USDprice: USD.price + fee_USD, fee_USD}) // 2pts extra de comisión
  }

  fixValues() {
    const { state } = this.props.location;
    const { USDprice, BTCprice, total } = this.state;

    const btc = state.total;

    this.setState({ btc, loading: false });
  }

  onSubmit = async () => {
    this.setState({isVerifying: true})
    const { transactionHash, address, remitApellido, remitEmail, remitNombre, destApellido,
    destNombre, destEmail, event_type, mensaje, discount_amount, discountCode, date,
    total, selectedCurrency, monto } = this.state;
    const { state } = this.props.location;

    // Verificar que el hash no esté vacío antes de hacer la solicitud
    if (transactionHash.trim() === "") {
      console.log("Ingrese un hash válido");
      return;
    }
    const redirect_uri = `${config.FRONTEND_URL}${config.ROUTES.REDIRECT_REDEEM.replace(":hash", "")}`
    console.log("LOCATION",state,"STATE",this.state);
    const data = {
      name: remitNombre, surname: remitApellido, email: remitEmail, redirect_uri,
      destName: destNombre, destSurname: destApellido, destEmail,
      event_type, message: mensaje, discountCode, discount: discount_amount,
      scheduled_date: date, trxHash: transactionHash, total: Number(total), address, monto, selectedCurrency
    }
    await this.props.onPayment(data)
    const { error, item } = this.props.payment;
    if (error) {
      notify(error.message)
    } else {
      const routeState = {
        from: "gift",
        payment_id: item.transaction_id,
        total_BTC: item.total_BTC,
        price_BTC: item.price_BTC,
        total_USD: item.total_USD,
        fee: item.fee,
        total_ARS: item.total_ARS,
        sender: item.sender,
        recipient: item.recipient,
        date: item.date,
        event_type: item.event_type,
        message: item.message,
      }

      history.push({pathname: config.ROUTES.CONGRATULATIONS, state: routeState})
    }
    this.setState({ isVerifying: false })
    // await this.props.onPayment(data)
  }

  render() {
    const { payMethod, cards, address, total, totalBTC, btc, ars,
      usd, loading, BTCprice, fee_buyer, discount, discount_amount,
      date, event_type, destNombre, mensaje, remitEmail, destEmail,
      minutes, seconds, selectedCurrency, destApellido, remitApellido,
      monto, remitNombre
    } = this.state;

    const goBackData = {date,destApellido,destEmail,destNombre,event_type,mensaje,monto,payMethod,remitApellido,remitEmail,remitNombre,selectedCurrency}

    const required = value =>
      validateIsfilled(value) ? undefined : this.t('This field is required');
    
    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: 'bg-base-200' }}
      >
        <ToastContainer></ToastContainer>
        <section className="flex flex-row bg-base-200 justify-around gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
          <div className="card white md:p-5 gap-4 md:w-[500px] w-full">
            <h2 className="h2 text-primary my-4 mx-auto">{capitalize(this.t("pago"))}</h2>
            <div className="z-[0] relative flex flex-col justify-between items-center px-3">
              <img src={qrCode} className='w-full md:w-96 md:h-96 object-contain' alt='' />
              {/* <div className="absolute text-lg leading-6 bottom-6 text-center w-3/4 md:w-1/2">{capitalize(this.t(event_type))} de <br/> <b>{capitalize(destNombre)}</b></div> */}
            </div>
            <p className="font-bold text-center text-lg text-base-content">
              {/* <span className="text-2xl">"</span>{capitalize(mensaje)}<span className="text-2xl">"</span> */}
            </p>
            <div className="flex flex-col gap-4 items-center justify-center mt-5 border-t p-5 md:p-0 md:pt-5">
              {/* <img src={Calendar} className='w-5 h-5  flex justify-center items-center ml-3' /> */}
              <div className='w-full'>
                <p className='text-black font-bold text-lg text-center'>Envía</p>
              </div>
              <div className=" mx-10 rounded-xl bg-white text-center p-2 pb-0">
                <div className='bg-gray-200 flex w-4/5 gap-2 rounded-lg mx-auto shadow-sm py-3 items-center justify-center place-content-center'>     
                    <p className="text-xl font-bold text-base-content">{btc} Bitcoin</p>
                </div>
                <div>
                    <p className="text-lg mb-2">a la billetera Bitcoin</p>
                  <p className="text-gray-500">{address}</p>
                  <p className="text-gray-500">para completar tu regalo</p>
                  <p className="text-black text-lg">Tienes {minutes}:{seconds} minutos para hacer la transferencia</p>
                </div>
              </div>
              <Form
                onSubmit={this.onSubmit}
              >
                {({handleSubmit, form, submitting, pristine}) => (
                <form onSubmit={handleSubmit} className="bg-base-200 p-4 ">
                    <p className="text-black">Colocar Hash de Transacción</p>
                    <div className="flex flex-row w-full pt-4">
                      <Field 
                        name="code" 
                        component={TextInput}
                        validate={required}
                        className="w-full text-black rounded-md pr-4"
                        inputClassName={"bg-white"}
                        placeholder={this.t("Hash")}
                        onChange={(e) => this.setState({ transactionHash: e.target.value })}
                      />                        
                    </div>
                    <p className="text-center p-4 text-xs text-gray-500">Colocar Hash para poder corroborar la operación.</p>
                    <div className="w-full">
                      <Button
                        onClick={()=>history.push(config.ROUTES.HOME)}
                        className="btn-outline btn-secondary flex-1 w-1/3 mr-2"
                        title={this.t('Volver')}
                      />
                      <Button className="btn-outline btn-secondary flex-end p-4 ml-4 w-1/2"
                        type="submit" title={this.state.isVerifying 
                          ? <Loader containerClassName='py-0 px-4 mx-auto' spinnerClassName='h-6 w-6 ml-3' />
                          : "Confirmar"}
                      />
                    </div>
                </form>
                )}
              </Form> 
              <div className="flex w-full"></div>
            </div>
          </div>
        </section>
        <Footer className="hidden md:block" />
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payment: state.payments.current,
    settings: state.settings.list,
    discount: state.discounts.current,
    transactions: state.transactions.current,
    internals: state.internals
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPayment: (data) => dispatch(paymentsActions.saveBitcoin(data)),
    onCoin: (params) => dispatch(paymentsActions.getCoin(params)),
    onGetSettings: params => dispatch(settingsActions.getAll(params)),
    validateDiscount: (params) => dispatch(discountsActions.validate(params)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(GiftBTC));