import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import { withTranslation } from "react-i18next";
import {capitalize} from '../../libraries/utils';
import { notify } from "../../libraries/notifications";
import Button from "../../components/commons/Button";
import aniversario from '../../assets/img/cards/aniversario.png';
import bautismo from '../../assets/img/cards/bautismo.png';
import casamiento from '../../assets/img/cards/casamiento.png';
import comunion from '../../assets/img/cards/comunion.png';
import confirmacion from '../../assets/img/cards/confirmacion.png';
import cumpleaños from '../../assets/img/cards/cumpleaños.png';
import snValentin from '../../assets/img/cards/dia de.png';
import graduacion from '../../assets/img/cards/graduacion.png';
import mudanza from '../../assets/img/cards/mudanza.png';
import nacimiento from '../../assets/img/cards/nacimiento.png';
import fiesta from '../../assets/img/cards/fiesta.png';
import navidad from '../../assets/img/cards/navidad.png';
import reyes from '../../assets/img/cards/reyes.png';
import fathers from '../../assets/img/cards/padre.png';
import mothers from '../../assets/img/cards/madre.png';
import btc from '../../assets/img/cards/btc.png';

import Footer from "../../components/layout/Footer";


import giftListsActions from '../../context/giftlists/actions'

class ConfirmList extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      cards: {
        birthday: cumpleaños,
        wedding: casamiento,
        anniversary: aniversario,
        baptism: bautismo,
        confirmation: confirmacion,
        valentines: snValentin,
        graduation: graduacion,
        move: mudanza,
        birth: nacimiento,
        communion: comunion,
        threekings: reyes,
        party: fiesta,
        christmas: navidad,
        fathers_day: fathers,
        mothers_day: mothers,
        bitcoin: btc,
      }
    };
  }

  componentDidMount() {
    const { state } = this.props.location;
    if(!state?.event_type || !state?.title || !state?.details){
      history.push(config.ROUTES.CHOOSE_CARD)
    } else {
      this.setState({event_type: state.event_type, title: state.title, details: state.details})
    }
  }

  async submitList() {
    const { state } = this.props.location;
    const { user } = this.props.auth;



    const details = state.details.replaceAll("\n", " ")
    const data = {
      event_type: state.event_type,
      title: state.title,
      details,
      wallet: state.wallet,
      json_data: {
        owner: {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email
        }
      }
    }
    await this.props.onSave(data);

    // this.props.location.state.from = 'list';
    const list = {...this.props.list.item, from: 'list'}
    history.push({pathname: config.ROUTES.CONGRATULATIONS, state: list});
  }

  render() {
    const { event_type, title, details } = this.state;
    const { cards } = this.state;
    
    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: '' }}
      >
        <section className="flex flex-row justify-around items-start gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
          <div className="card white gap-4 p-5 md:w-[500px] w-full">
            <h2 className="h2 text-primary mb-4 mx-auto">{capitalize(this.t("confirmar"))}</h2>
            <div className="h-fit z-[0] relative p-5 pb-0 flex flex-col justify-between items-center">
              <img src={cards[event_type]} className='w-full md:w-80 md:h-80 object-contain' alt='' />
              <div className="bg-white text-center rounded-btn p-2 mt-2">
                <div className="text-base font-semibold">{capitalize(title)}</div>
                <p className="text-sm text-gray-400">{capitalize(details)}</p>
              </div>
            </div>
            <div className="text-lg px-10 mb-10 text-center">
              <p className="font-semibold">Billetera</p>
              <p>{this.props.location.state.wallet}</p>
            </div>
            <div className="flex gap-5">
              <Button
                onClick={()=>history.push({ pathname:config.ROUTES.LIST_NEW, state: {event_type, title, details} })}
                className="btn-outline btn-secondary"
                title={this.t('Volver')}
              />
              <Button
                onClick={this.submitList.bind(this)}
                className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white flex-1 text-center p-2"
                title={capitalize(this.t('siguiente'))}
              />
            </div>
          </div>
        </section>
        <Footer className="hidden md:block" />
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.users.auth,
    list: state.giftlists.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (params) => dispatch(giftListsActions.saveOrUpdate(params))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ConfirmList));