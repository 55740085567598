import types from './types';
import roleUsers from './services';

const clearCurrent = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_CURRENT });
  };
};

const getAll = (params) => {
  function request() {
    return { type: types.GETALL_REQUEST };
  }
  function success(payload) {
    return { type: types.GETALL_SUCCESS, payload };
  }
  function failure(error) {
    return { type: types.GETALL_FAILURE, error };
  }

  return async (dispatch, getState) => {
    console.log('ACTIONS::ROLE_USERS::getAll', params);
    dispatch(request());
    const response = await roleUsers.getAll(params, getState().users.auth.token);
    console.log(
      'ACTIONS::ROLE_USERS::getAll::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const get = (id) => {
  console.log('ACTIONS::ROLE_USERS::get', id);
  const request = (id) => {
    return { type: types.GET_REQUEST, id };
  };
  const success = (payload) => {
    return { type: types.GET_SUCCESS, payload };
  };
  const failure = (error) => {
    return { type: types.GET_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request(id));
    let response = await roleUsers.get(id, getState().users.auth.token);
    console.log(
      'ACTIONS::ROLE_USERS::get::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(response.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const saveOrUpdate = (roleUser, userID) => {
  function request(payload) {
    return { type: types.SAVE_REQUEST, payload };
  }
  function success(payload) {
    return { type: types.SAVE_SUCCESS, payload };
  }
  function failure(error) {
    return { type: types.SAVE_FAILURE, error };
  }

  return async (dispatch, getState) => {
    console.log('ACTIONS::ROLE_USERS::saveOrUpdate', roleUser, userID);
    dispatch(request(roleUser));

    let response;
    if (roleUser.id) {
      // editing a existing record
      response = await roleUsers.update(roleUser, getState().users.auth.token);
    } else {
      response = await roleUsers.save(roleUser, getState().users.auth.token, userID);
    }
    console.log(
      'ACTIONS::ROLE_USERS::saveOrUpdate::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(response.data.data));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const del = (roleID, userID) => {
  console.log('ACTIONS::ROLE_USERS::del', roleID, userID);
  function request(roleID) {
    return { type: types.DELETE_REQUEST, roleID };
  }
  function success(roleID) {
    return { type: types.DELETE_SUCCESS, roleID };
  }
  function failure(roleID, error) {
    return { type: types.DELETE_FAILURE, roleID, error };
  }

  return async (dispatch, getState) => {
    dispatch(request(roleID));
    let response = await roleUsers.del(roleID, getState().users.auth.token, userID);
    console.log(
      'ACTIONS::ROLE_USERS::del::RESPONSE FROM SERVICE',
      response
    );
    if (response.success) {
      dispatch(success(roleID));
    } else {
      dispatch(failure(response.error));
    }
  };
};

const actions = {
  clearCurrent,
  get,
  getAll,
  saveOrUpdate,
  del,
};

export default actions;