import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NoAccess from '../../pages/commons/NoAccess';
import config from '../../config/';
import { permissionsCheck } from '../../libraries/utils';
import AuthCheck from '../AuthCheck';

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route 
      {...rest}
      render = { props => (<><AuthCheck /><Component {...props} /></>)}
    />
  )
};
