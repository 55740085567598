import React from 'react';
import logo from '../../assets/img/logo-negative.png';

export default function Splash(props) {
  return (
    <main className="bg-primary flex items-center justify-center h-full w-full">
      <h1>
        <img src={logo} alt="" width="250px" />
      </h1>
    </main>
  );
}
