const types = {
  CLEAR_CURRENT: 'DISCOUNTS_CLEAR_CURRENT',
  GET_REQUEST: 'DISCOUNTS_GET_REQUEST',
  GET_SUCCESS: 'DISCOUNTS_GET_SUCCESS',
  GET_FAILURE: 'DISCOUNTS_GET_FAILURE',

  SAVE_REQUEST: 'DISCOUNTS_SAVER_REQUEST',
  SAVE_SUCCESS: 'DISCOUNTS_SAVE_SUCCESS',
  SAVE_FAILURE: 'DISCOUNTS_SAVE_FAILURE',

  GETALL_REQUEST: 'DISCOUNTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'DISCOUNTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'DISCOUNTS_GETALL_FAILURE',

  DELETE_REQUEST: 'DISCOUNTS_DELETE_REQUEST',
  DELETE_SUCCESS: 'DISCOUNTS_DELETE_SUCCESS',
  DELETE_FAILURE: 'DISCOUNTS_DELETE_FAILURE',    

  UPDATE_REQUEST: 'DISCOUNTS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DISCOUNTS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DISCOUNTS_UPDATE_FAILURE', 
  
  GET_REQUEST_CODE: 'DISCOUNTS_CODE_GET_REQUEST',
  GET_SUCCESS_CODE: 'DISCOUNTS_CODE_GET_SUCCESS',
  GET_FAILURE_CODE: 'DISCOUNTS_CODE_GET_FAILURE',

};

export default types