import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import { withTranslation } from "react-i18next";
import {capitalize} from '../../libraries/utils';
import arrowRight from '../../assets/icons/arrow-right.svg';
import Calendar from '../../assets/icons/outline/calendar.svg'
import settingsActions from '../../context/settings/actions';
import discountsActions from '../../context/discounts/actions'
import paymentsActions from '../../context/payments/actions';
import Button from "../../components/commons/Button";
import aniversario from '../../assets/img/cards/aniversario.png';
import bautismo from '../../assets/img/cards/bautismo.png';
import casamiento from '../../assets/img/cards/casamiento.png';
import comunion from '../../assets/img/cards/comunion.png';
import confirmacion from '../../assets/img/cards/confirmacion.png';
import cumpleaños from '../../assets/img/cards/cumpleaños.png';
import snValentin from '../../assets/img/cards/dia de.png';
import graduacion from '../../assets/img/cards/graduacion.png';
import mudanza from '../../assets/img/cards/mudanza.png';
import nacimiento from '../../assets/img/cards/nacimiento.png';
import fiesta from '../../assets/img/cards/fiesta.png';
import navidad from '../../assets/img/cards/navidad.png';
import reyes from '../../assets/img/cards/reyes.png';
import fathers from '../../assets/img/cards/padre.png';
import mothers from '../../assets/img/cards/madre.png';
import btc from '../../assets/img/cards/btc.png';
import { notify, ToastContainer } from "../../libraries/notifications";
import Footer from "../../components/layout/Footer";
import Loader from "../../components/commons/Loader";

import Swal from 'sweetalert2';
import { isBefore, isAfter, format, parseISO } from 'date-fns'
import { Form, Field } from 'react-final-form'

import pirate1 from '../../assets/img/events/pirate1.png';
import pirate2 from '../../assets/img/events/pirate2.png';
import TextInput from "../../components/forms/TextInput";

const images = Object.freeze({ pirate1, pirate2 });

const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mx-1',
    cancelButton: 'btn btn-outline btn-primary mx-1',
    title: 'swal2-title',
    htmlContainer: 'swal-text'
  },
  buttonsStyling: false,
  background: '#fff'
});


class GiftConfirm extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      USDprice: 0,
      BTCprice: 0,
      ars: 0,
      usd: 0,
      btc: 0,
      btcars: 0,
      btcArsBTC: 0,
      btcArsFinal: 0,
      cards: {
        christmas: navidad,
        threekings: reyes,
        party: fiesta,
        birthday: cumpleaños,
        wedding: casamiento,
        anniversary: aniversario,
        baptism: bautismo,
        confirmation: confirmacion,
        valentines: snValentin,
        graduation: graduacion,
        move: mudanza,
        birth: nacimiento,
        communion: comunion,
        fathers_day: fathers,
        mothers_day: mothers,
        bitcoin: btc,
      },
      address: '',
      loading: true,
      fee_USD: 0, // 2
      fee_BTC: 0, //0.005
      fee_withdraw: 0, //0.00004
      buyer_fee: 0, // 7usd
      discountCode: null,
    };
  }

  async componentDidMount() {
    if(!this.props.location?.state){
      history.push(config.ROUTES.HOME)
      return
    }
    this.setState({ loading: true })
    this.setState({...this.props.location.state})
    console.log("STATE=========================",this.props.location.state)

    this.getData()
  }

  getData = async () => {
    await this.getSettings();
    const fee_USD = this.state.settings.find(s => s.code === config.TYPES.SETTINGS.CODES.ARS_TO_USD).value
    const fee_BTC = this.state.settings.find(s => s.code === config.TYPES.SETTINGS.CODES.BTC_PRICE).value
    const fee_withdraw = this.state.settings.find(s => s.code === config.TYPES.SETTINGS.CODES.BINANCE_WITHDRAW).value
    const fee_buyer = this.state.settings.find(s => s.code === config.TYPES.SETTINGS.CODES.BUYER_FEE).value
    await this.getBTC();
    await this.getUSD(fee_USD);
    this.fixValues(fee_BTC,fee_withdraw,fee_buyer);
  }

  getSettings = async () => {
    await this.props.onGetSettings({ code: [config.TYPES.SETTINGS.CODES.ARS_TO_USD, config.TYPES.SETTINGS.CODES.BTC_PRICE, config.TYPES.SETTINGS.CODES.BINANCE_WITHDRAW, config.TYPES.SETTINGS.CODES.BUYER_FEE] });
    const { settings } = this.props;
    if (settings.error) {
      notify(this.t(settings.error.message));
      return 0
    } else {
      this.setState({settings: settings.items.map(s => { return {code:s.code, value:Number(s.value) } })})

    }
  };

  async getBTC() {
    const BTC = await this.props.onCoin({ coin: 'btcusdt' });
    if (BTC.error) {
      notify(BTC.error.message);
      return
    }
    this.setState({BTCprice: BTC.price})
  }
  async getUSD(fee_USD) {
    const USD = await this.props.onCoin({ coin: 'usd' });
    if (USD.error) {
      notify(USD.error.message);
      return
    }
    this.setState({USDprice: USD.price + fee_USD, fee_USD}) // 2pts extra de comisión
  }

  fixValues(fee_BTC,fee_withdraw,fee_buyer) {
    const { state } = this.props.location;
    const { USDprice, BTCprice } = this.state;
    //ARS TO BTC
    let ars, btc, usd, total, totalBTC;
    state.monto = Number(state.monto);
    if (state.selectedCurrency === 'ARS') {
      ars = state.monto;
      total = ((state.monto) + (Math.round((fee_buyer * USDprice) / 10) * 10)).toFixed(2); // with fee
      usd = (state.monto / USDprice); // without fee
      btc = ((usd / BTCprice) + ((usd / BTCprice) * fee_BTC) + fee_withdraw ).toFixed(8); // with fee
    }
    if (state.selectedCurrency === 'USD') {
      ars = state.monto * USDprice;
      total = ((state.monto * USDprice) + (Math.round((fee_buyer * USDprice) / 10) * 10)).toFixed(2); // with fee
      usd = state.monto; // without fee
      btc = ((usd / BTCprice) + ((usd / BTCprice) * fee_BTC) + fee_withdraw ).toFixed(8); // with fee
    }
    if (state.selectedCurrency === 'BTC') {
      // btc = (state.monto + (state.monto * fee_BTC) + fee_withdraw).toFixed(8); // with fee
      btc = (state.monto).toFixed(8)
      usd = state.monto * BTCprice; // without fee
      ars = usd * USDprice;
      total = (ars + (Math.round((fee_buyer * USDprice) / 10) * 10)).toFixed(2);
    }
    if (state.payMethod === 'BTC') {
      totalBTC = (Number(btc) + (fee_buyer / BTCprice)).toFixed(8)
    }
    this.setState({ ars, btc, usd, total, totalBTC, fee_BTC, fee_withdraw, fee_buyer, loading: false });
  }

  confirm = async () =>  {
    this.setState({ loading: true })
    const { total, address, payMethod } = this.state;

    let init_date = new Date(2023, 1, 15, 19)
    let end_date = new Date(2023, 1, 16, 0) 
    let today = new Date()
    
    this.state.from = 'gift';    
    const message = this.state.mensaje.replaceAll("\n", " ")
    const data = {
      name: this.state.remitNombre,
      surname: this.state.remitApellido,
      email: this.state.remitEmail,
      total: parseInt(total),
      destEmail: this.state.destEmail,
      destName: this.state.destNombre,
      destSurname: this.state.destApellido,
      event_type: this.state.event_type,
      wallet: this.state.wallet ? this.state.wallet : null,
      message,
      redirect_uri: config.FRONTEND_URL + config.ROUTES.VERIFY.replace(":hash", ""),
      discount: this.state.discount ? this.state.discount_amount : null,
      discount_code: this.state.discount ? this.state.discountCode : null,
      scheduled_date: this.state.date,
      currency: this.state.selectedCurrency || null,
      usdAmount: this.state.usd,
      btcAmount: this.state.btc
    }
    console.log("data", data, "STATE", this.state)
    if (payMethod === 'BTC') {
      console.log(address)
      return
    }
    //PARA REVISAR QUE MANDA DESCOMENTAR EL RETURN
    // return
    await this.props.onPayment(data)
    this.setState({ loading: false })
    if (this.props.payment.error) {
      notify(this.t(this.props.payment.error.message))
    }
    if (this.props.payment.item) {
      this.setState({mpURL: this.props.payment.item})
    }
  }

  onSubmit = async values => {
    const {code} = values;
    if (code === '') {
      notify("Ingrese un código")
      return
    }
    if (values.code && !this.state.discountCode) {
      this.setState({ discountCode: values.code });
    }
    await this.props.validateDiscount({code})
    const {discount} = this.props;

    if (discount.error) {
      notify(this.t('Cupón no válido'));
      return
    } 
    if(discount.item.percentage === 1)  {
      const {fee_buyer, total, USDprice} = this.state
      const discount_amount = ((discount.item.value/100) * fee_buyer)
      const fee_buyer_w_discount = (fee_buyer-discount_amount)
      const total_w_discount = (total - (discount_amount * USDprice)) // THE TOTAL
      this.setState({total: total_w_discount, fee_buyer: fee_buyer_w_discount, discount_amount})
    } else {
      const {fee_buyer, total, USDprice} = this.state
      const discount_amount = discount.item.value
      const fee_buyer_w_discount = (fee_buyer - discount_amount )
      const total_w_discount = (total - (fee_buyer_w_discount * USDprice)) // THE TOTAL 
      this.setState({total: total_w_discount, fee_buyer: fee_buyer_w_discount, discount_amount})
    }
    this.setState({discount: discount.item})
  }

  handleQuitar = () => {
    this.setState({discountCode: null, discount: undefined})
    this.getData()
  }


  onOpenMPURL = () => {
    window.open(this.state.mpURL);
    history.push({pathname: config.ROUTES.HOME})
  }

  render() {
    const { payMethod, cards, address, total, totalBTC, btc, ars,
      usd, loading, BTCprice, fee_buyer, discount, discount_amount,
      date, event_type, destNombre, mensaje, remitEmail, destEmail,
      USDprice
    } = this.state;
    
    const { selectedCurrency } = this.props.location.state;
    const validateAddress = async (value) => {
      const characters = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
      if (value) {
        if(characters.test(value)) return undefined
      }
      return "Billetera inválida"
    }
    
    return (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: 'bg-base-200' }}
      >
        <ToastContainer></ToastContainer>
        <section className="flex flex-row bg-base-200 justify-around gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
          <div className="card white md:p-5 gap-4 md:w-[500px] w-full">
            <h2 className="h2 text-primary my-4 mx-auto">{capitalize(this.t("confirmar"))}</h2>
            <div className="z-[0] relative flex flex-col justify-between items-center px-3">
              <img src={cards[event_type]} className='w-full md:w-96 md:h-96 object-contain' alt='' />
              <div className="absolute text-lg leading-6 bottom-6 text-center w-3/4 md:w-1/2">{capitalize(this.t(event_type))} de <br/> <b>{capitalize(destNombre)}</b></div>
            </div>
            <p className="font-bold text-center text-lg text-base-content">
              <span className="text-2xl">"</span>{capitalize(mensaje)}<span className="text-2xl">"</span>
            </p>
            <div className="flex flex-col gap-4 items-center justify-center mt-5 border-t p-5 md:p-0 md:pt-5">
              <div className='bg-gray-200 flex w-3/5 gap-2 rounded-lg shadow-sm py-3 items-center justify-center place-content-center'>
                <img src={Calendar} className='w-5 h-5  flex justify-center items-center ml-3' />
                <div className='w-full'>
                  <p className='text-black text-xs '>Fecha de envío de regalo: 
                    <span className="font-bold mx-auto text-center"> {date ? format(date, 'dd/MM/yyyy') : 'hoy'}
                    </span></p>
                </div>
              </div>
              <div className="flex">
                <div className="text-start">
                  <p className="text-gray-400 text-sm">Remitente</p>
                  <p className=" text-xs">{remitEmail}</p>
                </div>
                <img src={arrowRight} alt='' className="w-6 m-2"/>
                <div className="text-start">
                  <p className="text-gray-400 text-sm">Destinatario</p>
                  <p className=" text-xs">{destEmail}</p>
                </div>
              </div>
              <div className=" mx-10 rounded-xl bg-white text-center p-2 pb-0">
                {BTCprice !== '' &&
                  <p className="text-xl font-bold text-base-content">{btc} Bitcoin</p>                
                }     
                <div>
                  {selectedCurrency !== 'BTC' &&
                    <p className="text-lg mb-2">Regalas 
                      {selectedCurrency === 'ARS' && (` ${ars} ` + selectedCurrency)} 
                      {selectedCurrency === 'USD' && (` ${usd} ` + selectedCurrency)}
                    </p>
                  }
                  <p className="text-xs text-gray-500">+{fee_buyer} USD costo del servicio</p>
                  {discount && <p className="text-xs text-gray-500">Descuento obtenido: {discount_amount.toFixed(3)} USD</p>}
                  {payMethod === 'MP' && <p className="text-xs text-gray-500">Pesos a pagar: {total} ARS</p>}
                  {payMethod === 'BTC' && total && USDprice && BTCprice && (
                    <>
                     <p>Total a pagar: {(parseInt(total/ USDprice) / BTCprice).toFixed(8)}</p>
                    </>  
                  )}
                </div>
              </div>
              <Form 
                initialValues={{code: this.state.discountCode || ''}}
                onSubmit={this.onSubmit}
              >
                {({handleSubmit, form, submitting, pristine}) => (
                <form onSubmit={handleSubmit} className="bg-base-200 p-4 ">
                    <p className="text-black">¿Tienes un cupón de descuento?</p>
                    <div className="flex flex-row w-full pt-4">
                      <Field 
                        name="code" 
                        component={TextInput} 
                        className="w-full text-black rounded-md pr-4"
                        inputClassName={"bg-white"}
                        placeholder={this.t("Código cupón")}
                        readOnly={this.state.discount}
                      />
                      {discount === undefined 
                        ?
                        <Button className='bg-black text-white rounded-btn border-opacity-50'
                          onClick={handleSubmit} title={loading ? 
                          <Loader containerClassName='py-0 px-4 mx-auto' spinnerClassName='h-6 w-6 ml-3' /> : 
                            capitalize(this.t('Agregar'))
                          }
                        />
                       :
                        <Button  className='bg-black text-white rounded-btn border-opacity-50'
                          onClick={this.handleQuitar} title={loading ? 
                            <Loader containerClassName='py-0 px-4 mx-auto' spinnerClassName='h-6 w-6 ml-3' /> : 
                              capitalize(this.t('Quitar'))
                          }
                        />
                      }
                    </div>
                    <p className="text-center p-4 text-xs text-gray-500">Los montos expresados son estimativos. El cierre de la operación varía por la volatilidad.</p>
                </form>
                )}
              </Form>  
              <p className="text-center p-4 text-xs text-gray-500">*Por favor comprobar los datos antes de confirmar.</p>
              {!this.state.mpURL && <div className="flex gap-5 w-full">
                <Button
                  onClick={()=>history.push({pathname: config.ROUTES.GIFT, state: {...this.props.location.state}})}
                  className="btn-outline btn-secondary"
                  title={this.t('Volver')}
                />
                <Button disabled={BTCprice === 0 || loading}
                  onClick={payMethod === 'MP'
                    ? () => this.confirm()
                    : () => history.push({ pathname: config.ROUTES.GIFT_BTC, state: {  ...this.state, total: (parseInt(total/ USDprice) / BTCprice).toFixed(8) } })
                  }
                  className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white flex-1 text-center p-2"
                  title={loading ? 
                    <Loader containerClassName='py-0 px-4 mx-auto' spinnerClassName='h-6 w-6 ml-3' /> : 
                    capitalize(this.t('Confirmar'))
                  }
                />
              </div>}
              {this.state.mpURL && <div className="flex gap-5 w-full">
                <Button onClick={this.onOpenMPURL}
                  className="bg-secondary border-2 border-white border-opacity-50 rounded-btn text-white flex-1 text-center p-2"
                  title={loading ? 
                    <Loader containerClassName='py-0 px-4 mx-auto' spinnerClassName='h-6 w-6 ml-3' /> : 
                    capitalize(this.t('Ir a mercadopago'))
                  }
                />
              </div>}
            </div>
            </div>
        </section>
        <Footer className="hidden md:block" />
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    payment: state.payments.current,
    settings: state.settings.list,
    discount: state.discounts.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPayment: (params) => dispatch(paymentsActions.saveOrUpdate(params)),
    onCoin: (params) => dispatch(paymentsActions.getCoin(params)),
    onGetSettings: params => dispatch(settingsActions.getAll(params)),
    validateDiscount: (params) => dispatch(discountsActions.validate(params)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(GiftConfirm));