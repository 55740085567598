import React, { Component } from "react";
import { connect } from "react-redux";
import LayoutWithSidebar from "../../components/layout/LayoutWithSidebar";
import { history } from "../../routes";
import config from "../../config";
import { withTranslation } from "react-i18next";
import ButtonIcon from "../../components/commons/ButtonIcon";
import iconOcasion from '../../assets/icons/custom/app-mobile/gift.png';
import Footer from "../../components/layout/Footer";
import Loader from "../../components/commons/Loader";

import giftlistsActions from '../../context/giftlists/actions';
import txActions from '../../context/transactions/actions';
import paymentsActions from '../../context/payments/actions';
import { notify, ToastContainer } from "../../libraries/notifications";
import Button from "../../components/commons/Button";
import { Form, Field } from "react-final-form";
import TextInput from "../../components/forms/TextInput";
import GiftEdit from "../../components/forms/GiftEdit";
import { isObject, validateIsfilled } from "../../libraries/utils";
import { capitalize } from "../../libraries/utils";
import Swal from "sweetalert2";
import facebook from "../../assets/icons/outline/Facebook.svg"
import twitter from "../../assets/icons/outline/Twitter.svg"

import aniversario from '../../assets/img/cards/aniversario.png';
import bautismo from '../../assets/img/cards/bautismo.png';
import casamiento from '../../assets/img/cards/casamiento.png';
import comunion from '../../assets/img/cards/comunion.png';
import confirmacion from '../../assets/img/cards/confirmacion.png';
import cumpleaños from '../../assets/img/cards/cumpleaños.png';
import snValentin from '../../assets/img/cards/dia de.png';
import graduacion from '../../assets/img/cards/graduacion.png';
import mudanza from '../../assets/img/cards/mudanza.png';
import nacimiento from '../../assets/img/cards/nacimiento.png';
import fiesta from '../../assets/img/cards/fiesta.png';
import navidad from '../../assets/img/cards/navidad.png';
import reyes from '../../assets/img/cards/reyes.png';
import fathers from '../../assets/img/cards/padre.png';
import mothers from '../../assets/img/cards/madre.png';
import btc from '../../assets/img/cards/btc.png';
import CancelGift from "./CancelGift";
import { format, parseISO } from "date-fns";

class Details extends Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      copied: false,
      from: '',
      id: '',
      item: null,
      claimed: false,
      BTCprice: 0,
      edit: false,
      del: false,
      redeemable: true,
      cards: {
        christmas: navidad,
        threekings: reyes,
        party: fiesta,
        birthday: cumpleaños,
        wedding: casamiento,
        anniversary: aniversario,
        baptism: bautismo,
        confirmation: confirmacion,
        valentines: snValentin,
        graduation: graduacion,
        move: mudanza,
        birth: nacimiento,
        communion: comunion,
        fathers_day: fathers,
        mothers_day: mothers,
        video: false,
        bitcoin: btc
      },
    };
  }
  async componentDidMount() {
    await this.getBTC();
    const { user } = this.props.auth;
    await this.getData();
    const { item } = this.state;
    let denied = true;
    if (user.id === item.source) { denied = false; };
    if (user.id === item.target) { denied = false; };
    if (user.id === item.json_data.owner?.id) { denied = false; };
    if (denied) {
      this.setState({ item: null, from: '' });
      history.push(config.ROUTES.USER.MY_GIFTS)
      notify(this.t('Acceso denegado'));
      setTimeout(()=>history.push(config.ROUTES.USER.INFO), 5000)
    }
  }

  async getBTC() {
    const BTC = await this.props.onCoin({ coin: 'btcusdt' });
    console.log("REPONSE",BTC)
    if (BTC.error) {
      notify(BTC.error.message);
      return
    }
    this.setState({BTCprice: BTC.price})
  }

  copyToClipboard(from) {
    if (from === 'list') {
      navigator.clipboard.writeText(config.FRONTEND_URL + config.ROUTES.LIST.replace(':id', this.state.id)).then(() => {
        /*`/list/${this.state.id}`*/
        this.setState({copied: true});
        setTimeout(()=> {this.setState({copied: false})}, 5000)
      });
    }
    if (from === 'gift') {
      navigator.clipboard.writeText(config.FRONTEND_URL + config.ROUTES.REDIRECT_REDEEM.replace(':hash', this.state.item.json_data.hash)).then(() => {
        /*`/redirect_redeem/${this.state.item.json_data.hash}`*/
        this.setState({copied: true});
        setTimeout(()=> {this.setState({copied: false})}, 5000)
      });
    }

    
  }

  
  async getData() {
    const { pathname } = this.props.location;
    const params = this.props.match.params;
    if (pathname.includes('gifts')) {
      const id = params.id;
      await this.setState({ id});
      await this.props.getTx(this.state.id);
      if (this.props.tx.error) {
        notify(this.props.tx.error.message)
        return
      }
      this.setState({ from: 'gift', item: this.props.tx.item})
      //use id to get the transaction data (/transactions)
    }
    if (pathname.includes('lists')) {
      const id = params.id;
      await this.setState({ id });
      await this.props.getList(this.state.id)
      if (this.props.list.error) {
        notify(this.props.list.error.message)
        return
      }

      const today = new Date();
      if (this.props.list.item.date) {
        const programed_date = new Date(this.props.list.item.date);
        if (today < programed_date) {
          this.setState({ redeemable: false })
        } else {
          this.setState({ redeemable: true })
        }
      }
      this.setState({ from: 'list', item: this.props.list.item })
      //use id to get the list data (/giftlists)
    }
  }

  async onSubmit(values) {
    const data = {
      id: this.state.item.json_data.hash,
      address: values.address,
    }

    await this.props.claim(data);
    let { claimed } = this.props;
    if (claimed.error) {
      notify(claimed.error.message);
      return
    }
    const json = JSON.parse(claimed.item.db_transaction.json_data);
    claimed.item.db_transaction.json_data = json;
    if (claimed.item.db_transaction.status === 'redeemed') {
      this.setState({ item: claimed.item.db_transaction })
    } else {
      notify('Algo salió mal, prueba mas tarde o ponte en contacto con el soporte.')
    }

  }

  toggleVideo = () => {
    this.setState(prev=>{
      return { video: !prev.video }
    })
  }

  toggleEdit = () => {
    this.setState(prev=>{
      return { edit: !prev.edit }
    })
  }

  toggleDel = ( reload = false) => {
    console.log(this.state.item)
    this.setState(prev=>{
      return { del: !prev.del }
    })
    if (reload) {
      this.getData()
    }
  }

  render() {
    const { item, from, id, copied, BTCprice, cards, video, edit, del, redeemable } = this.state;
    const { user } = this.props.auth;
    // if (item && !isObject(item.json_data)) {
    //   const json = JSON.parse(item.json_data);
    //   if (json) {
    //     item.json_data = json;
    //   }  
    // }
    
    const required = (value) =>
      validateIsfilled(value) ? undefined : this.t('This field is required');

    const validateAddress = async (value) => {

      const characters = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}$/;
      if (value) {
        if(characters.test(value)) {
          return undefined
        }
      }
      return "Billetera inválida"
    }
      

    let initialValues = {};
    if (item?.json_data.wallet) {
      initialValues = { address: item.json_data.wallet}
    }
    const now = new Date();
const birthdate = new Date('03/10/2001');
const age = (now - birthdate)/1000/60/60/24/365;

/* 
      Links
      Facebook:
      https://www.facebook.com/sharer.php?u=[post-url]
      Twitter:
      https://twitter.com/share?url=[post-url]&text=[post-title]&via=[via]&hashtags=[hashtags]
      Whatsapp:
      https://wa.me/?text=[post-title] [post-url]
    */
     //---------REDES SOCIALES---------
      // let postUrl = encodeURI('https://demo.regalalebitcoins.com/');
      // let postTitle = encodeURI('Quiero compartir con ustedes el regalo que realice: ')
  
      // let facebookLink = ('href',`https://www.facebook.com/sharer.php?u=${postUrl}`)
      // let twLink = ('href',`https://twitter.com/share?url=${postUrl}&text=${postTitle}&via=[via]&hashtags=[hashtags]`)

    return  (
      <LayoutWithSidebar
        header={{logo: true}}
        main={{ className: 'text-content-400' }}
        container={{ className: 'flex-grow md:flex-1' }}
      >
        <ToastContainer />
        <div onClick={this.toggleVideo} className={`absolute top-0 left-0 ${video ? "" : "hidden"} h-full w-full bg-[#00000088] z-50 grid place-content-center`}>
          {video &&
            <iframe className='w-[90vw] h-[45vw]'
              src="https://www.youtube.com/embed/2hFxvv07n_Q" title="YouTube video player" 
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
            </iframe>
          }
        </div>
        <section className="flex flex-row justify-around items-start gap-5 md:py-20 md:mt-10 min-h-full md:min-h-[calc(100%-325px)] container mx-auto">
        <div className="card white md:w-[500px] w-full h-full md:h-full">
          <div className={"h-fit flex items-center justify-center " + (item && item.status === 'canceled' ? 'bg-error opacity-40' : '')}>
            <ButtonIcon
              buttonClassName="btn-link absolute left-1 top-1 z-5"
              className="h-8 w-8 text-secondary"
              onClick={()=>{if (from === 'gift') {history.push(config.ROUTES.USER.MY_GIFTS)}; if (from === 'list') {history.push(config.ROUTES.USER.MY_LISTS)}}}          
              icon="arrow_left"
            />
            {/* <div className="relative">
              <img src={iconOcasion} width="100px" />
            </div> */}
            {item && from === 'gift' &&
              <div className={"flex-col items-center pt-8 "}>
                <p className="text-center mb-5 text-xl">{capitalize(item.source === user.id ? this.t('regalaste a') : this.t('te regaló'))} <b className="text-secondary">{capitalize(item?.json_data.recipient.first_name)}</b></p>
                <div className="z-[0] relative flex flex-col justify-between items-center">
                  <img src={cards[item.json_data.event_type]} className='w-48 object-contain' alt='' />
                  <div className="absolute leading-4 text-base bottom-3 text-center w-3/4">{capitalize(this.t(item.json_data.event_type))} de <br/> <b>{capitalize(item?.json_data.recipient.first_name)}</b></div>
                </div>
                <p className="font-bold text-center text-lg text-base-content my-2">
                  <span className="text-2xl">"</span>
                    {(item.status === 'canceled')
                      ? 'Regalo cancelado'
                      : capitalize(item.json_data.message)
                    }
                  <span className="text-2xl">"</span>
                </p>
              </div>
            }
            {item && from === 'list' &&
              <div className="flex-col items-center pt-8">
                <p className="text-center mb-5 text-xl">Tu lista de <b className="text-secondary">{this.t(capitalize(item.event_type))}</b></p>
                <div className="z-[0] relative flex flex-col justify-between items-center">
                  <img src={cards[item.event_type]} className='w-48 object-contain' alt='' />
                  <div className="absolute leading-4 text-base bottom-3 text-center w-1/2">{capitalize(this.t(item.title))}</div>
                </div>
                <p className="font-bold text-center text-lg text-base-content my-2 px-4">
                  <span className="text-2xl">"</span>{capitalize(item.details)}<span className="text-2xl">"</span>
                </p>
              </div>
            }
          </div>
          <div className="h-full flex flex-col">
            {from === '' && <Loader />}
            {item && from === 'gift' && item.json_data.source === user.id &&
              <>
                <div className="h-full bg-base-300 pt-6 flex flex-col justify-evenly items-center">
                  <div className="text-center text-2xl card white w-3/4 p-3 mx-auto">
                      <p className="text-3xl">{item.total} BTC</p>
                      <div className="">
                        <p>${(item.total * parseInt(BTCprice)).toFixed(2)} USD</p>
                      </div>
                  </div>
                  <p className="text-white my-3">Destinatario: {item.json_data.recipient.email}</p>
                  <div className="px-16 py-2 text-white flex justify-between border-y border-white">
                    <p className="pr-1">{this.t('Fecha')}:</p>
                    <p>{item.date.slice(0,10)}</p>
                  </div>
                  <div className="flex flex-col items-center justify-center mb-4">
                    {item.status !== 'redeemed' && item.status !== 'canceled' &&
                      <>
                        <Button onClick={this.copyToClipboard.bind(this, 'gift')} className='btn-link' title={this.t(capitalize(copied ? this.t('copied') : this.t('copiar link de reclamo')))} />
                        <p className="text-white text-xs">Este es el link con el que el agasajado podrá reclamar su regalo.</p>                        
                          <p className="mt-2 mb-1 text-white">Fecha: {format(parseISO(item.date),'dd/MM/yyyy')}</p>
                          <div className="flex w-full gap-4 mt-2">
                            <Button
                              onClick={this.toggleDel}
                              className='btn btn-error flex-1'
                              title={capitalize(this.t('cancelar'))}
                            />
                            {item.status === 'scheduled' &&
                              <Button
                                onClick={this.toggleEdit}
                                className='btn btn-primary flex-1'
                                title={capitalize(this.t('editar'))}
                              />  
                            }
                             
                          </div>                        
                      </>
                    }
                    {item.status === 'scheduled' && <p className="text-white text-xl mt-2">Regalo programado</p>}
                    {item.status === 'redeemed' && <p className="text-white text-xl mt-2">Regalo reclamado</p>}
                    {item.status === 'canceled' && <p className="text-white text-xl mt-2">Regalo cancelado</p>}
                  </div>
                    {item.status === 'redeemed' && 
                    <>
                    {/* --------REDES SOCIALES----------- */}
                      {/* <div className="my-2">
                        <p className="mb-2 text-lg text-white mt-2">Compartí en tus redes Favoritas</p>
                      </div>
                      <div className="flex flex-column-2 my-2">
                        <a className="mr-7 text-white" href={facebookLink}>Facebook</a>
                        <a className="ml-7 text-white" href={twLink}>Twitter</a>
                      </div> */}
                    </>
                    }
                </div>
              </>
              }
              {item && from === 'gift' && item.json_data.target === user.id && item.json_data.source !== user.id &&
                <>
                  <div className="h-full bg-base-300 pt-6 flex flex-col justify-evenly items-center">
                    <div className="mb-6 text-center text-2xl card white w-3/4 p-3 mx-auto">
                        <p className="text-3xl">Recibiste {item.total} BTC</p>
                        <p>${(item.total * parseInt(BTCprice)).toFixed(2)} USD</p>
                      
                    </div>
                    <p className="text-white my-3">Remitente: {item.json_data.sender.email}</p>
                    <div className="px-16 py-2 text-white flex justify-between border-y border-white">
                      <p className="mr-1">{this.t('Fecha')}:</p>
                      <p>{item.date.slice(0,10)}</p>
                    </div>
                    <div className="p-8 pt-0 flex justify-start">
                    {item?.status === 'approved' && redeemable &&
                      <Form
                        initialValues={initialValues}
                        onSubmit={this.onSubmit.bind(this)}
                      >
                          {({ handleSubmit, form, submitting, pristine, values, invalid }) => {
                            return (
                              <form onSubmit={handleSubmit} className='w-full'>
                                <p className="text-sm text-white text-center mt-4 mb-1 px-2">Crea tu wallet y recibí los BTC que te regalaron!</p>
                                <div onClick={this.toggleVideo}
                                  className='text-white text-xs p-2 mx-3 mb-3 rounded-lg grid place-content-center bg-gradient-to-r from-primary to-primary-focus hover:from-primary-focus hover:to-primary cursor-pointer'
                                >
                                  {/* <Icon className="h-8 w-8" name="video_camera" /> */}
                                  Ver video
                                </div>
                                <Field component={TextInput} name='address' textClassName='text-white' label='Address de BTC' inputClassName="w-full bg-[#F5F5F5]"
                                  validate={validateAddress}
                                />
                                <p className="text-xs mb-2 text-white">Las transacciones se realizarán al finalizar el día, esto nos permite bajar el costo de los fee y que recibas más bitcoins.</p>
                                <p className="text-xs mb-2 text-white">El address ingresado aquí no puede ser modificado una vez confirmado. Poner un address incorrecto hará que los BTC se envíen a otra persona.</p>
                                <Button
                                  type='submit' className='btn-secondary'
                                  disabled={submitting || invalid}
                                  title={ submitting ? 
                                    <Loader containerClassName='py-0 px-4 mx-auto' spinnerClassName='h-6 w-6 ml-3' /> :
                                    this.t('claim gift')
                                  }
                                />
                              </form>  
                            )
                          }}
                      </Form>    
                    }
                    {item?.status === 'redeemed' &&
                      <p className="text-white text-2xl w-full text-center mt-4">Regalo reclamado</p>
                    }
                    </div>
                  {item.status === 'redeemed' && 
                    <>
                    {/* ---------REDES SOCIALES------------- */}
                      {/* <div className="my-2">
                        <p className="mb-2 text-lg text-white mt-2">Compartí en tus redes favoritas</p>
                      </div>
                      <div className="flex flex-column-2 my-2">
                        <a className="mr-7 text-white " href={facebookLink}><img className="bg-blue-500" src={facebook}></img></a>
                        <a className="ml-7 text-white" href={twLink}><img className="bg-blue-500 w-full" src={twitter}></img></a>
                      </div> */}
                    </>
                    }
                  </div>
                </>
              }
              {from === 'list' &&
                <>
                  <div className=" bg-base-300 pt-6 flex-1">
                    <div className="mb-6 text-center text-2xl card white w-3/4 p-3 mx-auto">
                      <p className="text-2xl p-3">Compartila con tus amigos</p>
                      <div className="">
                        <p className="leading-5">{`${config.FRONTEND_URL}/list/${id}`}</p>
                        <Button
                          onClick={this.copyToClipboard.bind(this, 'list')}
                          className='btn-link'
                          title={this.t(capitalize(copied ? this.t('copied') : this.t('copy to clipboard')))}
                        />
                      </div>
                    </div>
                  </div>
                </>
              }
          </div>
        </div>
        {item && edit && <GiftEdit item={item} edit={edit} toggleEdit={this.toggleEdit} /> }
        {item && del && <CancelGift item={item} del={del} toggleDel={this.toggleDel}/> }
        </section>
        <Footer className="hidden md:block" />
      </LayoutWithSidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.users.current,
    auth: state.users.auth,
    tx: state.transactions.current,
    list: state.giftlists.current,
    claimed: state.payments.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTx: (id)=> dispatch(txActions.get(id)),
    getList: (id)=> dispatch(giftlistsActions.get(id)),
    claim: (id)=> dispatch(paymentsActions.claim(id)),
    onCoin: (params) => dispatch(paymentsActions.getCoin(params)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Details));